<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Invoice </h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Invoice Details</li>
</ul>


<!-- <round-progress
    [current]="current"
    [max]="max"
    [color]="'#45ccce'"
    [background]="'#eaeaea'"
    [radius]="125"
    [stroke]="20"
    [semicircle]="true"
    [rounded]="true"
    [clockwise]="false"
    [responsive]="false"
    [duration]="800"
    [animation]="'easeInOutQuart'"
    [animationDelay]="0"
    (onRender)="doSomethingWithCurrentValue($event)"></round-progress> -->


<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4><span class="red-clr mrg-right">Unpaid</span>Select to pay Unpaid Amount : &nbsp; ${{totl_amt}} -> &nbsp;
<button class="btn btn-warning" (click)="invoicepaymentlink()"> Pay Now</button>
</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#invoiceCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="invoiceCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped">
<thead>
<tr>

<th >

<!-- <th class="text-center pt-3"> -->
<!-- <div class="custom-checkbox custom-checkbox-table custom-control"> -->

<!-- <input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all"> -->
<!-- <label for="checkbox-all" class="custom-control-label">&nbsp;</label> -->

<!-- <input class="" type="checkbox"   (change)="checkCheckBoxvalue($event)"> -->
<input type="checkbox" name="checkbox" [(ngModel)]="isCheckedall" (click)="checkCheckBoxvalue()" >

<!-- </div> -->
</th>


<th>S.no</th>
<th>Invoice No</th>
<th>Biller</th>
<th>Date</th>
<th>Description</th>
<th>Total</th>
<th>Generated By</th>
<th>Inv Type(Aging)</th>
<th>Curr Det</th>
<th>Action</th>


</tr>
</thead>


<tbody>
<!-- <tr >
<td colspan="10">
<h6 class="red-clr">Unpaid</h6>
</td>
</tr> -->


<tr class="red-grad bordergap"   *ngFor="let u of unpaidinvoicedata;let i=index">
<!-- <div  *ngIf="u.cus_invoice_no != ''"> -->
<td class="text-center pt-2">
   <input class="" type="checkbox"  [(ngModel)]="u.add" name="add" (click)="multislt(u)">

<!-- <div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div> -->
</td>



<td>{{offset_count+i+1}}</td>
<td>{{u.cus_invoice_no}}</td>
<td><button class="btn" [style.background]="u.colorCodes" style="line-height: 12px;">{{u.billerCode}}</button></td>
<td>{{u.date}}</td>
<td>{{u.description}}</td>
<td>{{u.total}}</td>
<td>{{u.generatedby}}</td>
<td>{{u.invoice_type}}</td>
<td><b>{{u.currency_name}} {{u.conversionRate}}</b></td>
<td class="action-btn-group">
<a href="{{u.invoice_attach}}" target="_blank" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="{{u.paymentBillLink}}" target="_blank" data-toggle="tooltip" title="Payment" class="btn green-bg-clr">
<i class="fas fa-dollar-sign"></i>
</a>
<div *ngFor="let l of u.license_attach;let g=index;">
<a *ngIf="u.license_attach.length != 0" href="{{l}}" target="_blank"  data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</div>
</td>
<!-- </div> -->
</tr>
 




</tbody>
 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">Great no due invoices ..</td>
               </tr>
</table>
</div>


<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData.info == 'show'">
<!-- -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? unpaidinvoices({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="unpaidinvoices({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? unpaidinvoices({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="unpaidinvoices({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? unpaidinvoices({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>





</div>
</div>
</div>
</div>
</div>
</div>












<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<!-- <h4>Select to pay Unpaid Amount : &nbsp; $ 0 -> &nbsp;
<button class="btn btn-warning"> Pay Now</button>
</h4> -->


<h4><span class="gr-clr mrg-right">Paid</span></h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#invoiceCollapses">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="invoiceCollapses">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped">
<thead>
<tr>


<!-- <th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th> -->

<th>S.no</th>
<th>Invoice No</th>
<th>Biller</th>
<th>Date</th>
<th>Description</th>
<th>Total</th>
<th>Generated By</th>
<th>Inv Type(Aging)</th>
<th>Curr Det</th>
<th>Action</th>
</tr>
</thead>


<tbody>
<!-- <tr >
<td colspan="10">
<h6 class="gr-clr">Paid</h6>
</td>
</tr>
 -->

 <tr class="green-grad bordergap"   *ngFor="let u of paidinvoicedata;let c=index">
<!-- <td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td> -->
<td>{{offset_count2+c+1}}</td>
<td>{{u.cus_invoice_no}}</td>
<td><button class="btn" [style.background]="u.colorCodes" style="line-height: 12px;" >{{u.billerCode}}</button></td>
<td>{{u.date}}</td>
<td>{{u.description}}</td>
<td>{{u.total}}</td>
<td>{{u.generatedby}}</td>
<td>{{u.invoice_type}}</td>
<td><b>{{u.currency_name}} {{u.conversionRate}}</b></td>
<td class="action-btn-group">
<a href="{{u.invoice_attach}}" target="_blank" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="{{u.paymentBillLink}}" target="_blank" data-toggle="tooltip" title="Payment" class="btn green-bg-clr">
<i class="fas fa-dollar-sign"></i>
</a>
<div *ngFor="let l of u.license_attach;let g=index;">
<a  *ngIf="u.license_attach.length != 0" href="{{l}}" target="_blank"  data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</div>
</td>
</tr> 
 




</tbody>
 <tr *ngIf="recordNotFound2 == true">
                 <td colspan="12">No Record Found</td>
               </tr>
</table>
</div>


<div class="card-footer text-right" *ngIf="recordNotFound2 == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData2.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? paidinvoices({'search_text':'','offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="paidinvoices({'search_text':'','offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? paidinvoices({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="paidinvoices({'search_text':'','offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? paidinvoices({'search_text':'','offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>





</div>
</div>
</div>
</div>
</div>
</div>








<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<!-- <h4>Select to pay Unpaid Amount : &nbsp; $ 0 -> &nbsp;
<button class="btn btn-warning"> Pay Now</button>
</h4> -->


<h4><span class="red-clr  mrg-right">Proforma Invoice</span></h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#invoiceCollapses2">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="invoiceCollapses2">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped">
<thead>
<tr>


<!-- <th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th> -->

<th>S.no</th>
<th>Invoice No</th>
<th>Biller</th>
<th>Date</th>
<th>Description</th>
<th>Total</th>
<th>Generated By</th>
<th>Inv Type(Aging)</th>
<th>Curr Det</th>
<th>Action</th>
</tr>
</thead>


<tbody>
<!-- <tr >
<td colspan="10">
<h6 class="gr-clr">Paid</h6>
</td>
</tr>
 -->

 <tr class="red-grad bordergap"   *ngFor="let u of perfomainvoicedata;let c=index">
<!-- <td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td> -->
<td>{{offset_count3+c+1}}</td>
<td>{{u.invoice_no}}</td>
<td><button class="btn" [style.background]="u.colorCodes" style="line-height: 12px;" >{{u.billerCode}}</button></td>
<td>{{u.date}}</td>
<td>{{u.description}}</td>
<td>{{u.total}}</td>
<td>{{u.generatedby}}</td>
<td>{{u.invoice_type}}</td>
<td><b>{{u.currency_name}} {{u.conversionRate}}</b></td>
<td class="action-btn-group">
<a href="{{u.invoice_attach}}" target="_blank" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="{{u.paymentBillLink}}" target="_blank" data-toggle="tooltip" title="Payment" class="btn green-bg-clr">
<i class="fas fa-dollar-sign"></i>
</a>
<a href="{{u.license_attach[0]}}" target="_blank"  data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</td>
</tr> 
 




</tbody>
 <tr *ngIf="recordNotFound3 == true">
                 <td colspan="12">No Record Found</td>
               </tr>
</table>
</div>


<div class="card-footer text-right" *ngIf="recordNotFound3 == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData3.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData3.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.backtab=='show' ? perfomainvoices({'search_text':'','offset':paginationData3.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData3.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="perfomainvoices({'search_text':'','offset':paginationData3.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData3.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? perfomainvoices({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData3.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="perfomainvoices({'search_text':'','offset':paginationData3.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData3.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData3.nexttab =='show' ? perfomainvoices({'search_text':'','offset':paginationData3.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>





</div>
</div>
</div>
</div>
</div>
</div>












</section>