import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare var require: any
const SecureStorage = require('secure-web-storage');
const SECRET_KEY = 'secret_key';
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

public secureStorage = new SecureStorage(localStorage, {
// Encrypt the localstorage data
encrypt:function encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {
      console.log(e);
    }
  }
// function encrypt(data) {
//     data = CryptoJS.AES.encrypt(data, SECRET_KEY);
//     data = data.toString();
//     return data;
// }
,
// Decrypt the encrypted data
decrypt: function decryptData(data) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    } 
}
// function decrypt(data) {
//     data = CryptoJS.AES.decrypt(data, SECRET_KEY);
//     data = data.toString(CryptoJS.enc.Utf8);
//     return data;
// }

});


}