<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Licence Renewal Remainder</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Licence Renewal Remainder Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Licence Renewal Remainder Detailss</h4>

<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped"  >
  <!-- -->
  <!-- id="dataTable" -->







<thead>
<tr>
<!-- <th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th> -->
<th>S.no</th>
<th>Date</th>
<th>Biller</th>
<th>Company Name</th>
<th>End User Name</th>
<th>Licence</th>
<th>Expiry Date</th>
</tr>
</thead>
<tbody>
<tr *ngFor ="let l of renewallist;let i = index;">
<!-- <td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td> -->
<td>{{offset_count+i+1}}</td>
<td>{{l.date}}</td>
<td>
<div class="badge badge-success badge-shadow">&nbsp;{{l.biller_name}}&nbsp;</div>
</td>

<td>{{l.company_name}}</td>
<td>{{l.enduser}}</td>
<td>{{l.license}}</td>
<td>{{l.expiry_dt}}</td>

<!-- <td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;{{l.Generatedby}}&nbsp;</div></td> -->
<!-- <td class="action-btn-group">
<a href="{{l.Attachment}}" target="_blank" class="btn btn-primary"><i class="far fa-file-pdf"></i></a>

</td> -->
</tr>






</tbody>

 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>



</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
<!--  -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? renewals({'search_text':agent_search.value,'offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="renewals({'search_text':agent_search.value,'offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? renewals({'search_text':agent_search.value,'offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="renewals({'search_text':agent_search.value,'offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? renewals({'search_text':agent_search.value,'offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>




</div>
</div>
</div>
</div>
</div>
</div>
</section>