<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Deal Registration</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Deal Registration Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Deal Registration</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">

    <div class="form-group row mb-4">
                  <div class="col-sm-12 col-md-7">
                  <button type="submit"   data-toggle="modal" data-target=".dealRegisterModal" style="margin-right: 10px !important" class="btn btn-primary" ><i class="fas fa-plus-circle"></i>Add Deal Registeration</button>
                  </div>
               </div>
<div class="table-responsive">
<table class="table table-striped" >
  <!-- id="tableExport" -->
<thead>
<tr>
<th>S.no</th>
<th>Entry Date</th>
<th>Partner name</th>
<th>Partner Email</th>
<th>Phone Number</th>
<th>Product</th>
<th>Deal Size</th>
<th>End user name</th>
<th>End user Email</th>
<th>End user phone number</th>
<th>Expected deal closer</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>

<tr  *ngFor ="let l of dealregistration;let i = index;">
<td>{{offset_count+i+1}}</td>
<td>{{l.Entrydate}}</td>
<td>{{l.Partnername}}</td>
<td>{{l.Partneremail}}</td>
<td>{{l.Phonenumber}}</td>
<td>{{l.Product}}</td>
<td>{{l.Dealsize}}</td>
<td>{{l.Endusername}}</td>
<td>{{l.Enduseremail}}</td>
<td>{{l.Enduserno}}</td>
<td>{{l.Dealcloser}}</td>
<!-- <td class="action-btn-group">
<a href="javascript:;" data-toggle="tooltip" title="View" class="btn btn-warning">
<i class="fas fa-eye"></i> View Details
</a>
</td> -->
</tr>



<!-- <tr>
<td>2</td>
<td>01-10-2020</td>
<td>Lorenz</td>
<td>lorenz@gmail.com</td>
<td>+65 8950 4510</td>
<td>3CX Cloud</td>
<td>1</td>
<td>Twoaplus</td>
<td>twoaplus@gmail.com</td>
<td>++0 8950 1425</td>
<td>09-11-2021</td>
<td class="action-btn-group">
<a href="javascript:;" data-toggle="tooltip" title="View" class="btn btn-warning">
<i class="fas fa-eye"></i> View Details
</a>
</td>
</tr> -->




</tbody>

<tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>


</table>
</div>


<div class="card-footer text-right" *ngIf="recordNotFound == false">
  
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? deal_register_list({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="deal_register_list({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? deal_register_list({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="deal_register_list({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? deal_register_list({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>






</div>
</div>
</div>
</div>
</div>
</div>
</section>