import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';
declare var iziToast:any;
import Swal from 'sweetalert2'
import * as Feather from 'feather-icons';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import * as moment from 'moment';
@Component({
  selector: 'app-callhistorypbx',
  templateUrl: './callhistorypbx.component.html',
  styleUrls: ['./callhistorypbx.component.css']
})
export class CallhistorypbxComponent implements OnInit {

typeData:any={};
reseller_customer:any=[]
montharray:any=[];
getdata: FormGroup;
resellershow = false;
value;
filter  = 1;
bill_code_740; 
bill_code_750; 
bill_code_750_8;
bill_code_dcsg; 
bill_code_host;
bill_code_jp;
bill_code_kl;
bill_code_name;

public defaultSelect = "default";
selectedresellername:any=[];

recordNotFound = false;
 pageLimit = 100;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 calllist:any=[];
 filtercalllist:any=[];
calldatashow = false;
datamonth:any=[];
totaldatas:any=[];

display_names:any=[];
grouparray:any=[];
customers_id;
datalist:any;
username_display;

client_id_array:any=[];
perm:any;
 constructor(private router:Router,private serverService: ServerService,private http:HttpClient) { }

  ngOnInit(): void {
if(this.serverService.callhistorypbx == "0"){
  
 this.router.navigate(['/dashobard']);
}



  	this.callhistorydatas("")
       this.customers_id=atob(localStorage.getItem('en'))
       console.log(this.customers_id)
this.getdata = new FormGroup({
 
      'resellcustomer' : new FormControl(null,Validators.required),

      

     });
this.typeData.types=""
this.typeData.start_month=""
this.typeData.start_time=""
this.typeData.to_time=""
this.typeData.filterid =""
  }

ngAfterViewInit() {
    Feather.replace();
  }
goextension(){
   this.router.navigate(['/extensions']);

}

changeSelection(){

}
callhistorydatas(val){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="call_history";
datas.customer_id = customer_id;
datas.customer_bill_code_hd_id = val;




 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="callhistory";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.reseller_customer =response.reseller_billcode;
this.username_display=response.customer_details.cms_username_dispaly;
     this.bill_code_740 = response.bill_code_740;
     this.bill_code_750 = response.bill_code_750;
     this.bill_code_750_8 = response.bill_code_750_8;
     this.bill_code_dcsg = response.bill_code_dcsg;
     this.bill_code_host = response.bill_code_host;
     this.bill_code_jp = response.bill_code_jp;
     this.bill_code_kl = response.bill_code_kl;
     this.bill_code_name = response.bill_code_name;
     this.montharray =response.month;
     this.client_id_array = response.extension_data;
     this.datalist = response;
if(response.host_server != null){
this.typeData.host = response.host_server[0].name

}
     // if(this.bill_code_740 != ''){

     // }else if(this.bill_code_750 != '' &&  this.bill_code_750_8 != ''){

     // }else if(this.bill_code_kl != ''){

     // }
// var mm = response.month; 

// let arr = [];  
// Object.keys(mm).map(function(key){  
//     arr.push({[key]:mm[key]})  
//     return arr;  
// });  
// this.montharray = arr
console.log(this.montharray)
// console.log('Object=',mm)  
// console.log('Array=',arr) 


if(val == ""){
    if(this.reseller_customer.length > 1){
      this.resellershow = true;

    }
}
          }
        else{
this.reseller_customer =[];
 
        }

      
      }, 
      (error)=>{
          console.log(error);
      });

}


updateid(){
  this.resellershow = false;
  console.log(this.getdata.value.resellcustomer)
  this.selectedresellername = this.getdata.value.resellcustomer.split(","); 
  console.log(this.selectedresellername)
  this.callhistorydatas(this.selectedresellername[0])
}


validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }


finishFunction(){

   if(this.getdata.valid){
     this.updateid()
   

     
   }
   else {

        this.validateAllFields(this.getdata); 
        }
  
}


callhistorylist(data){

if(this.typeData.types == ""){
   iziToast.error({
          message: "Please Select filter by",
          position: 'topRight'
      });
return false;
}
else{

if(this.typeData.types == "dates"){

if(this.typeData.start_time == "" || this.typeData.to_time == "" ){
iziToast.error({
          message: "Please Select date ",
          position: 'topRight'
      });
return false;
}

}else if(this.typeData.types == "months"){


if(this.typeData.start_month == ""){
iziToast.error({
          message: "Please Select month ",
          position: 'topRight'
      });
return false;
}
}


}


 Swal.fire('Please wait')
Swal.showLoading()

console.log(this.typeData)
this.datamonth = this.typeData.start_month.split(",")
console.log(this.datamonth)
 var list_data= this.listDataInfo(data);

console.log(this.typeData.start_month)




  let datas:any =new Object();
 let datasend:any = new Object();
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
if(this.datalist.host_server != null && this.datalist.host_server.length >= 2){
if(this.typeData.host == "Host-A"){

datas.bill_code_740 = this.bill_code_740;
datas.bill_code_host = "1";

}else if(this.typeData.host == "Host-B"){

datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_host = "2";

}else if(this.typeData.host == "Host-C"){
this.bill_code_750.concat(this.bill_code_dcsg,this.bill_code_jp);

datas.bill_code_750 = this.bill_code_750;

datas.bill_code_750_8 = this.bill_code_750_8;

datas.bill_code_host = "3";
}
// else if(this.typeData.host == "Host-D"){

// datas.bill_code_dcsg = this.bill_code_dcsg;

// }
// else if(this.typeData.host == "Host-JP"){

// datas.bill_code_jp = this.bill_code_jp;

// }
else{
datas.bill_code_dcsg = this.bill_code_dcsg;
datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_740 = this.bill_code_740;
datas.bill_code_750 = this.bill_code_750;
datas.bill_code_750_8 = this.bill_code_750_8;
datas.bill_code_jp = this.bill_code_jp;
datas.bill_code_host = this.bill_code_host;
}

}
else{
datas.bill_code_dcsg = this.bill_code_dcsg;
datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_740 = this.bill_code_740;
datas.bill_code_750 = this.bill_code_750;
datas.bill_code_750_8 = this.bill_code_750_8;
datas.bill_code_jp = this.bill_code_jp;
datas.bill_code_host = this.bill_code_host;
}



   console.log(senddata)
datas.action="call_history_data";
datas.customer_id = customer_id;

// datas.bill_code_host = customer_id;



if(this.typeData.types == "dates"){
var filters  = 1;
var from_dt = moment(this.typeData.start_time).format('DD-MM-YYYY');
var to_dt = moment(this.typeData.to_time).format('DD-MM-YYYY');

}
else{

var filters  = 2;
   var senddata = this.datamonth[0]+"-"+this.datamonth[1]
datas.filter_by_month = senddata;

}


datas.from_dt = from_dt;
datas.to_dt = to_dt;
datas.export_type = '';



datas.filter = filters;


datas.bill_code_name = this.bill_code_name;
datas.from_display_name_id = this.grouparray;
if(this.grouparray.length != 0)
{
  datas.filter_by_client ="2"
}
 datas.limit = list_data.limit;

datas.offset = list_data.offset;
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="callhistory";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)

        if(response && response.status=="true"){
           Swal.close();
           console.log(this.calllist)
     this.calllist =response.call_history_data;
     this.filtercalllist = response.call_history_data;

     this.totaldatas = response.total_details
     this.display_names = response.display_name;
     console.log(this.display_names)
    // if(this.calllist.length > 1){
      this.calldatashow = true;

if(this.display_names.length != 0){
if(this.grouparray.length != 0){
for (var i = 0; i < this.display_names.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.grouparray.length; j++) {
   if(this.display_names[i].from_display_name ==  this.grouparray[j]){
     ismatch = true;
     this.display_names[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.display_names[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.display_names.length; i++) {
     this.display_names[i].add = false  
  }
}


}






    }
        else{
           Swal.close();
          this.calldatashow = false;

           iziToast.success({
          message: "No Call History Available",
          position: 'bottomLeft'
      });
// this.calllist =[];
 
        }
   this.offset_count = list_data.offset;
    this.paginationData = this.serverService.pagination({'offset':response.offset, 'total':response.list_cnt[0], 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.calllist.length == 0 ? true : false;
      
      }, 
      (error)=>{
          console.log(error);
      });

}



listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.callhistorylist({'search_text':searchValue})
}



callhistoryexport(val){

 Swal.fire('Please wait')
Swal.showLoading()
let datas:any =new Object();
 let datasend:any = new Object();
console.log(this.typeData)
this.datamonth = this.typeData.start_month.split(",")
console.log(this.datamonth)

console.log(this.typeData.start_month)
if(this.typeData.types == "dates"){
 var filter  = 1;
var from_dts = moment(this.typeData.start_time).format('DD-MM-YYYY');
var to_dts = moment(this.typeData.to_time).format('DD-MM-YYYY');
}
else{
 filter  = 2;
 var senddata = this.datamonth[0]+"-"+this.datamonth[1]
datas.filter_by_month = senddata;
}

  
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
   
   console.log(senddata)
datas.action="call_history_data";
datas.customer_id = customer_id;

// datas.bill_code_host = customer_id;
datas.filter = filter;


datas.from_dt = from_dts;
datas.to_dt = to_dts;
datas.export_type = val;

if(this.datalist.host_server.length >= 2 && this.datalist.host_server != null){
if(this.typeData.host == "Host-A"){

datas.bill_code_740 = this.bill_code_740;
datas.bill_code_host = "1";

}else if(this.typeData.host == "Host-B"){

datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_host = "2";

}else if(this.typeData.host == "Host-C"){
this.bill_code_750.concat(this.bill_code_dcsg,this.bill_code_jp);

datas.bill_code_750 = this.bill_code_750;

datas.bill_code_750_8 = this.bill_code_750_8;

datas.bill_code_host = "3";
}
// else if(this.typeData.host == "Host-D"){

// datas.bill_code_dcsg = this.bill_code_dcsg;

// }
// else if(this.typeData.host == "Host-JP"){

// datas.bill_code_jp = this.bill_code_jp;

// }
else{
datas.bill_code_dcsg = this.bill_code_dcsg;
datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_740 = this.bill_code_740;
datas.bill_code_750 = this.bill_code_750;
datas.bill_code_750_8 = this.bill_code_750_8;
datas.bill_code_jp = this.bill_code_jp;
datas.bill_code_host = this.bill_code_host;
}

}
else{
datas.bill_code_dcsg = this.bill_code_dcsg;
datas.bill_code_kl = this.bill_code_kl;
datas.bill_code_740 = this.bill_code_740;
datas.bill_code_750 = this.bill_code_750;
datas.bill_code_750_8 = this.bill_code_750_8;
datas.bill_code_jp = this.bill_code_jp;
datas.bill_code_host = this.bill_code_host;
}


datas.from_display_name_id = this.grouparray;

datas.bill_code_name = this.bill_code_name;
if(this.grouparray.length != 0)
{
  datas.filter_by_client ="2"
}
       
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="callhistory";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
 Swal.close();
        if(response.status=="true"){
  this.goToLink(response.file_name,val)

    }
        else{

 
        }

      
      }, 
      (error)=>{
          console.log(error);
      });

}

goToLink(url: string,type){
if(type == 'excel'){
    window.open(url);
      // this.http.get(url);

}else{
    window.open(url, "_blank");

}
}

multislt(val)
{
  // this.uncheck = 0;
  console.log(val)

 var adding = false
if(val.add == false){

if(this.grouparray.length != 0){

 for (var i = 0; i < this.grouparray.length; i++) {


if(val.from_display_name !== this.grouparray[i].from_display_name){

 adding  = true
}

}
  if(adding){
      this.grouparray.push(val)


  }
}
else{
  this.grouparray.push(val)

}

}
else{

   for (var j = 0; j < this.grouparray.length; j++) {

if(val.from_display_name == this.grouparray[j].from_display_name){

    this.grouparray.splice(this.grouparray.indexOf(this.grouparray[j]), 1 );

}
}
 

}
 
 console.log(this.grouparray)

}

filterbyclentid(val){
  // const strs = ['valval', 'bal', 'gal', 'dalval'];
//   for (var i =0; this.filtercalllist.length; i++) {
   
// this.calllist = this.filtercalllist[i].client_id.filter(s => s.includes('419'));

//   }
if(val != ''){

  this.calllist = this.filtercalllist.filter(
          book => book.client_id === val);
}else{
 this.calllist = this.filtercalllist;
}
console.log(this.calllist)

}


}
