<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Lead</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Lead Details</li>
</ul>


<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Lead Details</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped" >
	<!-- id="tableExport" -->
<thead>
<tr>
<th>S.no</th>
<th>ENQ NO</th>
<th>Assign Date</th>
<th>Contact Info</th>
<th>Email</th>
<th>Phone Number</th>
<th>Company</th>
<th>Product Name</th>
<th>Country</th>
<th>Qty</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>
<tr *ngFor="let l of leads;let i = index;">
<td>{{offset_count+i+1}}</td>
<td>{{l.Enqno}}</td>
<td>{{l.Assigndate}}</td>
<td>{{l.Contactinfo}}</td>
<td>{{l.Email}}</td>
<td>{{l.Phonenumber}}</td>
<td>{{l.Company}}</td>
<td>{{l.Productname}}</td>
<td>{{l.Country}}</td>
<td>{{l.Quantity}}</td>
<!-- <td class="action-btn-group">
<a href="javascript:;" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="javascript:;" data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</td> -->
</tr>
 

<!-- <tr>
<td>2</td>
<td>196</td>
<td>01-10-20</td>
<td>+60 1320 1501</td>
<td>twoaplusceo@cal4care.com</td>
<td>+65 9869 8020</td>
<td>Twoaplus</td>
<td>3CX Cloud</td>
<td>Singapore</td>
<td>1</td>
<td class="action-btn-group">
<a href="javascript:;" data-toggle="tooltip" title="Invoice" class="btn btn-danger">
<i class="far fa-file-pdf"></i></a>
<a href="javascript:;" data-toggle="tooltip" title="License" class="btn btn-warning">
<i class="fas fa-key"></i>
</a>
</td>
</tr> -->
</tbody>

<tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>


</table>
</div>



<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData.info == 'show'">
<!-- -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? leadslist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="leadslist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? leadslist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="leadslist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? leadslist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>









</div>
</div>
</div>
</div>
</div>
</div>



</section>