import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ServerService } from '../services/server.service';

import { LocalService } from '../serviceslocal/local.service';
import { Router } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { interval, Subscription } from 'rxjs';
declare var iziToast:any;
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
 loginForm:FormGroup;
 otpform:FormGroup;
 forgotform:FormGroup;
 datavalue;
 sendotps = false;
 forgots = false;
 expired= false;
qrcode;
 title = 'angular-qr-code-tutorial';
subscription: Subscription;
  public qrdata: string;
  public level;
  public width: number;
  datas:any;
  getdatas:any;
  count:number= 1;
   websocket:any;
   typess;
    tab: number = 1;
     userperms: any = [];
     pn:any=[];
  constructor(private router:Router,private serverService: ServerService,private localService: LocalService) { 

this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4007"); 
var s = this;
  this.websocket.onopen = function(event) { 
   console.log('socket connected');
 

 }
 this.websocket.onmessage = function(event) {
   s.getdatas = JSON.parse(event.data)
    
s.logins()
}

this.websocket.onerror = function(event){
   console.log('error');
   console.log(event.message);
}





  }


 //  sends(){
 //      var socket_message='[{"userId":"fgftr","unique_id_en":"responsedata"}]';
 // this.websocket.send(socket_message);
 //  }
  ngOnInit(): void {
localStorage.clear();

 localStorage.removeItem("pn");
 	this.loginForm = new FormGroup({
    'username' : new FormControl(null,Validators.required),
    'password' : new FormControl(null,Validators.required),
    // 'company_name' : new FormControl(null,Validators.required)           
      });

    this.otpform = new FormGroup({
    'auth_code' : new FormControl(null,Validators.required),           
      });



 this.forgotform = new FormGroup({
    'forgotemail' : new FormControl(null,Validators.required),           
      });

   //      this.loginForm.setValue({
   //    'username' : this.username,
   //      'password' :'' ,
   //      'company_name' :'' 


   // })
// this.datavalue = this.serverService.encryptData("jai")
// console.log(this.datavalue)

 // this.localService.setJsonValue('user', "uyjy");
 // console.log(this.localService.getJsonValue('user'))





this.subscribes('')

  }

openForm2(){
  
}



ngAfterViewInit() {
    Feather.replace();
  }

// ngOnDestroy() {


//   
// }
unsubscribe(){
this.subscription.unsubscribe();
}
subscribes(val){
  this.expired=false;
  if(val != '')
    this.count = 1;
 this.qrcodes()
const source = interval(30000);
this.subscription = source.subscribe(val => 
  this.qrcodes()
  );
}

qrcodes(){
  this.count=++this.count;
   console.log(this.count)  
   if(this.count<5){
      this.level = "M"
 this.width=256;
 this.datas =uuidv4();
 this.typess =btoa('cms')
 console.log(this.typess)
 const data = [{
    'type': this.typess,
    'address': this.datas,
  }]


  this.qrdata = JSON.stringify(data);
  console.log(this.qrdata)
   }
   else{
     this.expired = true;
     this.unsubscribe()
   }
  
}

backToLogin(){
this.forgots = false;
this.sendotps = false;
}
validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }


userLogin(){
   if(this.loginForm.valid){
     
     this.userLogins()
   }
   else {
            this.validateAllFields(this.loginForm); 
        }
  
}

otpsubmit(){
   if(this.otpform.valid){
     
     this.otpsubmits()
   }
   else {
            this.validateAllFields(this.otpform); 
        }
  
}

otpsubmits(){
  
   let Otpform:any =  new Object();
let api_submitotpdata:any = new Object();

    Otpform.action="otp_validation_for_app";
     Otpform.uname =this.loginForm.value.username;
      Otpform.pwd =this.loginForm.value.password;
      Otpform.otp_from ="otp1";
      Otpform.auth_from ="undefined";
      Otpform.auth_code =this.otpform.value.auth_code;

   api_submitotpdata.operation="curlData";
   api_submitotpdata.moduleType="login";
   api_submitotpdata.api_type="web";
   api_submitotpdata.element_data=Otpform;                     
                this.serverService.sendServer(api_submitotpdata).subscribe((response:any) => {
                    
        console.log(response)
        if(response.result.data.result_state == "success"){
        iziToast.success({
          message: "OTP Validated Sucessfully",
          position: 'topRight'
      });
          this.websocket.close();
localStorage.setItem('at', response.result.data.auth_token);
localStorage.setItem('cn', response.result.data.result_data.customer_details.customerName);
localStorage.setItem('en',response.result.data.customer_id_encode);
localStorage.setItem('blg','1');

localStorage.setItem('enable','1'); 


this.userperms = response.result.data.result_data.customer_details.cus_permission.split(',');
 // localStorage.getItem('userperms').split(',');
 
    console.log(this.userperms)
    for (var i = 0; i < this.userperms.length; i++) {

this.pn.push(btoa(this.userperms[i]))
      // if (this.userperms[i] == '101')
      //   this.invoice = 1;
      // if (this.userperms[i] == '102')
      //   this.credit = 1;
      // if (this.userperms[i] == '103')
      //   this.license = 1;
      // if (this.userperms[i] == '118')
      //   this.cxbuy = 1;
    }


localStorage.setItem('pn',this.pn); 


this.serverService.cn = response.result.data.result_data.customer_details.customerName;
this.serverService.ci = response.result.data.result_data.customer_details.customerId;
console.log(this.serverService.ci)
 this.loginForm.reset();
  this.otpform.reset();
   this.router.navigate(['/dashobard']);
        // if(this.page == 'register'){
        //   this.sendotps = false;
        // }  
        // else{
        // this.userLogin()

        // }
        } 
       else{
         iziToast.error({
          message: "Please Enter the Valid OTP",
          position: 'topRight'
      });
}

    }, 
    (error)=>{
        console.log(error);
    });

}
  userLoginswith(){

        let loginForm:any = new Object();
        let api_req:any = new Object();
          loginForm.auth_from= "undefined";
          loginForm.uname= this.loginForm.value.username;
          loginForm.pwd= this.loginForm.value.password;
          loginForm.otp_from= "otp1";
          loginForm.action="login_validation";
          api_req.operation="curlData";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=loginForm;
         

                this.serverService.sendServer(api_req).subscribe((response:any) => {

                    if(response.status == "true" ){

                      // if(response.result.data.status == "1"){
// 
  iziToast.success({
          message: "OTP Send  Successfully",
          position: 'topRight'
      });
this.sendotps = true;
  // localStorage.setItem('userId',''); 
    
  //   localStorage.setItem('type', response.result.data.type);
  //   localStorage.setItem('superadmin','');
  //   localStorage.setItem('supersuperadmin', response.result.data.user_id);

// }

     
     

        // if(localStorage.getItem('type') == '0'){
        // this.router.navigate(['/dashboard']);


       //    }
       // else{
       //    this.sendotps = true;
       //    // this.user_id =  response.result.data.user_id;
       //    // this.sendotp()
       //  }


        }
       else{

       iziToast.error({
          message: "Invalid Login credentials",
          position: 'topRight'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    

}



  userLogins(){

        let loginForm:any = new Object();
        let api_req:any = new Object();
          loginForm.uname= this.loginForm.value.username;
          loginForm.pwd= this.loginForm.value.password;
          loginForm.action="login_without_otp";
          api_req.operation="curlData";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=loginForm;
        this.serverService.sendServer(api_req).subscribe((response:any) => {

// console.log(response)
 if(response.result.data.result_state == "success"){
          iziToast.success({
          message: "Logged In Sucessfully",
          position: 'topRight'
      });
  this.websocket.close();
localStorage.setItem('at', response.result.data.auth_token);
localStorage.setItem('cn', response.result.data.result_data.customer_details.customerName);
localStorage.setItem('en',response.result.data.customer_id_encode);
localStorage.setItem('blg','1');

localStorage.setItem('enable','1'); 

this.userperms = response.result.data.result_data.customer_details.cus_permission.split(',');
 // localStorage.getItem('userperms').split(',');
 
    console.log(this.userperms)
    for (var i = 0; i < this.userperms.length; i++) {

this.pn.push(btoa(this.userperms[i]))
      // if (this.userperms[i] == '101')
      //   this.invoice = 1;
      // if (this.userperms[i] == '102')
      //   this.credit = 1;
      // if (this.userperms[i] == '103')
      //   this.license = 1;
      // if (this.userperms[i] == '118')
      //   this.cxbuy = 1;
    }


localStorage.setItem('pn',this.pn); 


this.serverService.cn = response.result.data.result_data.customer_details.customerName;
this.serverService.ci = response.result.data.result_data.customer_details.customerId;
console.log(this.serverService.ci)
 this.loginForm.reset();
  this.otpform.reset();
   this.router.navigate(['/dashobard']);


        }
       else{

       iziToast.error({
          message: "Invalid Login credentials",
          position: 'topRight'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    

}






logins(){

if(this.datas == this.getdatas[0].address){

  let loginForm:any = new Object();
        let api_req:any = new Object();
          loginForm.action="customer_data";
          loginForm.customer_id=this.getdatas[0].userId;
          api_req.operation="curlData";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=loginForm;
        this.serverService.sendServer(api_req).subscribe((response:any) => {

console.log(response)
 if(response.status == "true"){
          iziToast.success({
          message: "Logged In Sucessfully",
          position: 'topRight'
      });
localStorage.setItem('at', response.auth_token);
localStorage.setItem('cn', response.customer_data.customerName);
localStorage.setItem('en',response.customer_id_encode);
localStorage.setItem('pn',response.customer_id_encode);

localStorage.setItem('enable','1'); 

  

this.serverService.cn = response.customer_data.customerName;
this.serverService.ci = response.customer_data.customer_id;
console.log(this.serverService.ci)
 this.loginForm.reset();
  this.otpform.reset();
   this.router.navigate(['/dashobard']);


        }
       else{

       iziToast.error({
          message: "Invalid Scan",
          position: 'topRight'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
}
else
{
  console.log("false exists")
}
      
}

  forgotsubmit(){

console.log(this.forgotform.value.forgotemail)
if(this.forgotform.value.forgotemail == null || this.forgotform.value.forgotemail == ""){
    iziToast.error({
          message: "Enter your Email",
          position: 'topRight'
      });
    return false

}

        let loginForm:any = new Object();
        let api_req:any = new Object();
          loginForm.uname= this.forgotform.value.forgotemail;
          loginForm.action="forgot_password";
          api_req.operation="curlData";
          api_req.moduleType="login";
          api_req.api_type="web";
          api_req.element_data=loginForm;
         

                this.serverService.sendServer(api_req).subscribe((response:any) => {

                    if(response.status == "true" ){

  iziToast.success({
          message: "Email Sent Successfully",
          position: 'topRight'
      });

this.backToLogin()

        }
       else{

       iziToast.error({
          message: "Please Try Again Later",
          position: 'topRight'
      });
                        }

                    }, 
                    (error)=>{
                        console.log(error);
                    });
    
}


forgotpass(){
this.forgots = true;
}








}

