<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">GCC Firewall Allow</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">GCC Firewall Allow</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>GCC Firewall Allow</h4>

<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">

 <div class="form-group row mb-4">
              <div class="col-sm-12 col-md-7">
              <button type="submit" style="margin-right: 10px !important" class="btn btn-primary" (click)="addip()"><i class="fas fa-plus-circle"></i>Add IP to Allowed Group GCC</button>
              </div>
           </div>

<div class="table-responsive">
<table class="table table-striped"  >



 <!--  <thead>
                <tr>
                  <td colspan="20" class="search-text-tbl">
                    <div class="input-group"><input type="text" class="form-control" placeholder="Search" (input)="getsearch($event.target.value)"  (keyup.enter)="gcclist({'search_text':agent_search.value})" #agent_search>
                      <div class="input-group-append">
                        <button class="btn btn-primary" (click)="gcclist({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                      </div>
                    </div>
                  </td>
                </tr>
              </thead>  
 -->




<thead>
<tr>

<th>S.no</th>
<th>Master IP</th>
<th>Allowed IP</th>
<th>Type</th>
<th>Action</th>

</tr>
</thead>
<tbody>
<tr *ngFor ="let l of gccdata;let i = index;">

<td>{{offset_count+i+1}}</td>
<td>{{l.master_ip}}</td>


<td>{{l.allowed_ip}}</td>
<td>
<div class="badge badge-success badge-shadow">&nbsp;{{l.type}}&nbsp;</div>
</td>
<td>
  <div *ngIf='l.auto_renew == "1"'>
  <img src="{{l.image}}"     alt="image"  width="50">&nbsp;
</div>
  <div *ngIf='l.auto_renew == "0"'>
  <img src="{{l.image}}"     alt="image"  width="70">&nbsp;
</div>
</td>



</tr>






</tbody>

 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>



</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
<!--  -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? gcclist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="gcclist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? gcclist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="gcclist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? gcclist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>





</div>
</div>
</div>
</div>
</div>
</div>
</section>


<div class="modal fade createticketModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
aria-hidden="true"  id="ipmodal">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="myLargeModalLabel">Add IP Address to Allowed Group</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

    

      <form class="deal-register-form" [formGroup]="ipinformation">

        <div class="form-group">
          <label for="partner-name">Customer Name</label>
            <input type="text" class="form-control" id="partner-name" formControlName="customername" readonly>
              <div class="error-message message-small" *ngIf="!ipinformation.get('customername').valid && ipinformation.get('customername').touched">
                     Please enter Customer Name
          </div>
      </div>

  <div class="form-group">
          <label for="partner-name">IPv4 IP Address</label>
            <input type="text" class="form-control" id="partner-name" formControlName="ipaddress" >
              <div class="error-message message-small" *ngIf="!ipinformation.get('ipaddress').valid && ipinformation.get('ipaddress').touched">
                     Please enter IP Address
          </div>
      </div>






      
         
        




        
        <div class="btn-groups aligncenter">
          <a  class="btn btn-success mr-3" (click)="finishFunction()">Submit</a>
          <a  class="btn btn-info" data-dismiss="modal">Cancel</a>
        </div>
      </form>
    </div>
  </div>
</div>
</div> 