import { Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-reseller-price-list',
  templateUrl: './reseller-price-list.component.html',
  styleUrls: ['./reseller-price-list.component.css']
})
export class ResellerPriceListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

ngAfterViewInit() {
    Feather.replace();
  }



}
