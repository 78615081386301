<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Your Cart</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/dashobard"><i data-feather="headphones"></i></a>
</li>
<li class="breadcrumb-item active">Add to Cart</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Your Cart</h4>

<div class="card-header-action" style="display: -webkit-box;">
<p *ngIf="dataslist.Customer_3cx_data.Resellerid">3CX Reseller Id :{{dataslist.Customer_3cx_data.Resellerid}}
</p>
<a  style="margin-left: 30px;" data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>

</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body" >
<div class="table-responsive">
<table class="table table-striped" >
	<!-- id="tableExport" -->
<thead>
<tr>
	<th style="text-align: center;">Action</th>
<!-- <th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th> -->
<th>Code</th>
<th>Description</th>
<th>Price({{dataslist.Customer_data.currency_name}})</th>
<th>Qty</th>
<th>Discount({{dataslist.Customer_data.currency_name}})</th>
<th>Amount ({{dataslist.Customer_data.currency_name}})</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>
<!-- <tr>
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>Code 1</td>
<td>16 SC PRO  Concurent Perpetual</td>
<td>680</td>
<td>1</td>
<td>60</td>
<td><b>620</b></td>
<td class="action-btn-group">
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> -->
<!-- <div  class="borderbox"> -->
<tr *ngFor="let l of cartdata;let i = index;" class="borderbox">
<!-- <td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td> -->
<td class="action-btn-group " style="text-align: center;">
<a href="javascript:;" class="btn btn-danger" (click)="removecart(l)"><i class="fas fa-trash-alt"></i></a>
</td> 
<td style="text-align: center;">{{l.edition_key}}</td>
<td>{{l.product_desc}}</td>
<td style="text-align: right;">{{l.actual_price}}</td>
<td style="text-align: center;">{{l.quantity}}</td>
<td style="text-align: center;">{{l.discount}}({{l.customer_discount}}%)</td>
<td style="text-align: right;"><b>{{l.sub_total}}</b></td>



<!-- <td class="action-btn-group">
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td> -->
</tr>
<!-- </div> -->
<tr>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td style="border: none !important;"></td>
<td style="border: none !important;"> </td>
<td style="text-align: center;">Net Total</td>
<td style="text-align: right;">
	<b *ngIf="cartdata.length != 0">{{total_price}}</b>
	<b *ngIf="cartdata.length == 0">0.00</b>
</td>
</tr>


<tr>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"> </td>
<td style="text-align: center;">Tax Value<span *ngIf='total_tax != "0.00"'>({{tax_percent}}%)</span></td>
<td style="text-align: right;">
<b *ngIf="cartdata.length != 0">{{total_tax}}</b>
	<b *ngIf="cartdata.length == 0">0.00</b>
</td>
</tr>


<tr>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td  style="border: none !important;"></td>
<td style="text-align: center;">Grand total</td>
<td style="text-align: right;">
<b *ngIf="cartdata.length != 0">{{grand_total}}</b>
	<b *ngIf="cartdata.length == 0">0.00</b>
</td>
</tr>

</tbody>
</table>
</div>

<div class="submit-btn-group text-center mb-3 mt-4">
<a routerLink="/buy3cx" class="btn btn-info">Add another Product</a>
<a  (click)="proceedtobuy()" class="btn btn-success" >Proceed to Checkout</a>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</section>