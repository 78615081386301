import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-newextension',
  templateUrl: './newextension.component.html',
  styleUrls: ['./newextension.component.css']
})
export class NewextensionComponent implements OnInit {
exten_id:any;
page ="add";
extnsiondata:any={};
 getdata: FormGroup;

  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) {
  this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.page=this.router.getCurrentNavigation().extras.state.page;

     this.exten_id=this.router.getCurrentNavigation().extras.state.exten_id; 


if(this.page == "edit"){
     this.editextension()

}
   
      }
    });


  this.extensiondropdown()
   }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
ngAfterViewInit() {
    Feather.replace();
  }

  ngOnInit(): void {

  		  this.getdata = new FormGroup({
      'extensionselect' : new FormControl(null,Validators.required),
      'ex_name' : new FormControl(null,Validators.required)
     
           
     });


  }

editextension(){

 
let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="edit_extension";
datas.customer_id = customer_id;
datas.extn_name_id = this.exten_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){

     this.extnsiondata = response
this.getdata.patchValue({
	extensionselect:'',
	ex_name:this.extnsiondata.Extension_name,
	

});
     
   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });


}




update(){

if(this.getdata.valid){
this.updatedata()
}
else{
   this.validateAllFields(this.getdata); 
}

}


updatedata(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="update_extension_info";
datas.customer_id_hd = customer_id;
datas.extn_name_id = this.extnsiondata.Extension_name_id;
datas.extn_no = this.getdata.value.extensionselect;
datas.extn_name = this.getdata.value.ex_name;
    

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
  console.log(datasend)
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
   iziToast.success({
          message: "Extension Updated Sucessfully",
          position: 'topRight'
      });
   
          }else{
            iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}





addextension(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="add_extension";
datas.customer_id = customer_id;


datas.extn_no = this.getdata.value.extensionselect;
datas.extn_name =  this.getdata.value.ex_name;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
      iziToast.success({
          message: "Extension Added Sucessfully",
          position: 'topRight'
      });
 this.router.navigate(['/extensions']);

     
   
          }else{
             iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


extensiondropdown(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="extension_dropdown";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
      this.extnsiondata = response;
   
          }else{
        
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



}
