import { Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-shoppingcartdetails',
  templateUrl: './shoppingcartdetails.component.html',
  styleUrls: ['./shoppingcartdetails.component.css']
})
export class ShoppingcartdetailsComponent implements OnInit {
cartdata:any=[];
datalist:any={};
paymentarray:any=[];
selectpayment;
transactioncharge="0.00";
total_amount;
show = false;
couponcode:any;
discount_price;
discount_percent="0";
couponcodemessage="";

invoice_id;
credit_data;
item_number = 0;
taxpercent;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
  	this.viewcart()
       this.taxpercent=localStorage.getItem('xtt');

// $('input[name="business"]').val("Good Fish");
// console.log(document.getElementById('payPaylPayment').elements['business'].value)
// console.log($("payPaylPayment").serializeArray())
  }
ngAfterViewInit() {
    Feather.replace();
  }


getvalue(){
 for (var i = 0; i < this.paymentarray.length; i++) {

   this.paymentarray[i].show = true;
 }
 // var elmId = $("#payment_type_paypal_checkout").val();
 console.log(this.selectpayment)
}
viewcart(){
    Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="viewcartlist";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
            Swal.close();

    this.cartdata = response.options;
    this.item_number = this.cartdata.length;
    this.datalist = response;

this.total_amount = this.datalist.grand_total_value
this.discount_price =this.datalist.total_discount_value
    for (var i = 0; i < this.datalist.payment_gateway.length; i++) {


this.paymentarray.push({
  name:"",
  showvalue:this.datalist.payment_gateway[i]
}
  )

    }

 for (var i = 0; i < this.paymentarray.length; i++) {

   this.paymentarray[i].show = true;
      if(this.paymentarray[i].showvalue == "paypal_credit_debit"){
this.paymentarray[i].name="Credit or Debit Card (PayPal)" 
this.paymentarray[i].value="payment_type_paypal_checkout" 

      }else if(this.paymentarray[i].showvalue == "ocbc_sg_payment"){
this.paymentarray[i].name="Credit Card / Debit Card" 
this.paymentarray[i].value="payment_type_ocbc_sg_payment" 

      }else if(this.paymentarray[i].showvalue == "stripe_pay"){
 this.paymentarray[i].name="Stripe" 
this.paymentarray[i].value="payment_type_stripe_pay" 
      }else if(this.paymentarray[i].showvalue == "payoffline"){
 this.paymentarray[i].name="Pay Offline" 
this.paymentarray[i].value="payment_type_offline" 
      }
      else if(this.paymentarray[i].showvalue == "ocbc_my_payment"){
this.paymentarray[i].name="Credit Card / Debit Card" 
this.paymentarray[i].value="payment_type_ocbc_my_payment" 

      }




}
// this.selectpayment = this.paymentarray[0].value
    console.log(this.paymentarray)
      //   iziToast.success({
      //     message: "Product removed from your cart",
      //     position: 'topRight'
      // });
          }
        else{
          this.datalist ={};
  // iziToast.error({
  //         message: "Please try again later",
  //         position: 'topRight'
  //     });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });

}

transactioncharges(val){
  
this.selectpayment = val;
console.log(this.selectpayment)

for (var i = 0; i < this.paymentarray.length; i++) {
  if(this.paymentarray[i].value == val){
  this.paymentarray[i].show = true;

  }
  else{
  this.paymentarray[i].show = false;

  }
}


  let datas:any =new Object();
 let datasend:any = new Object();
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
   
datas.action="transaction_charges";
datas.customer_id = customer_id;
datas.currency_name = this.datalist.Customer_data.currency_name;
datas.selected_rdo =val;
datas.total_amount = this.datalist.grand_total_value;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="payment";
  datasend.api_type="web";
  datasend.element_data = datas;
  Swal.fire('Please wait')
Swal.showLoading()
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close();
          this.transactioncharge =response.tansaction_charges;
          this.total_amount = response.total_amount;
          if(this.couponcode != "" && this.couponcode != undefined){
            this.couponcode_verify()
          }
      //   iziToast.success({
      //     message: "Product removed from your cart",
      //     position: 'topRight'
      // });
          }
        else{
          Swal.close();
  // iziToast.error({
  //         message: "Please try again later",
  //         position: 'topRight'
  //     });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });
}




couponcode_verify(){


  let datas:any =new Object();
 let datasend:any = new Object();
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
   
datas.action="coupon_code_verify";
datas.customer_id = customer_id;
if(this.couponcode == '' || this.couponcode == undefined ){
datas.coupon_code = '';
}else{
  datas.coupon_code = this.couponcode;
}


datas.net_total = this.total_amount;
datas.tax_value = this.datalist.total_tax_value;
datas.transaction_charges =this.transactioncharge;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="payment";
  datasend.api_type="web";
  datasend.element_data = datas;
  Swal.fire('Please wait')
Swal.showLoading()
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close();
          // this.transactioncharge =response.tansaction_charges;
          this.total_amount = response.grand_total;
          this.discount_price =response.discount_price;
          this.discount_percent = response.discount_percentage;
          this.couponcodemessage ="Copon applied successfully.";
      //   iziToast.success({
      //     message: "Product removed from your cart",
      //     position: 'topRight'
      // });
          }
        else{
          this.couponcodemessage=""
          Swal.close();
  iziToast.error({
          message: "Invalid Coupon",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });
}









check(){

}



submitpaymentangular(){
  
  if(this.selectpayment == '' || this.selectpayment == undefined){
      iziToast.error({
          message: "Please Select any payment",
          position: 'topRight'
      });
      return false;
  }
 //  console.log($('[name=billing_id]').val())
 
 // console.log($('[name=credit_data]').val())
 //  console.log($('[name=action]').val())
 // console.log($('[name=payment_order]').val())
 // console.log($('[name=payment_from]').val())

 // console.log($('[name=currency_code]').val())
 
 // console.log($('[name=order_id]').val())
 //  console.log($('[name=credit_data]').val())
 // console.log($('[name=transaction_data]').val())
 // console.log($('[name=payment_from]').val())

// $('#stripePay_frm').submit();
// $('#payPaylPayment').submit();
     // $('#ocbc_sg').submit();

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="cms_invoice_generate";
datas.customer_id = customer_id;
datas.customer_discount =this.datalist.Customer_data.reseller_dis_per;
datas.select_pay =this.selectpayment;
datas.coupon_code = this.couponcode;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
  Swal.fire('Please wait')
Swal.showLoading()
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response && response.status=="true"){
          // Swal.close();
     // 
this.invoice_id = response.invoice_id;
this.credit_data = response.credit_data;


 setTimeout (() => {
   

 Swal.close();
if (this.selectpayment == 'payment_type_offline') {

                                $('#licenseKeyGenerate').submit();
                            }

                  
                   else if (this.selectpayment  == 'payment_type_stripe_pay') {

                                $('#stripePay_frm').submit();
                            }
                                    
                             else if (this.selectpayment  == 'payment_type_ocbc_sg_payment') {

                                $('#ocbc_sg').submit();

                            }

          else if (this.selectpayment  == 'payment_type_ocbc_my_payment') {

                                $('#ocbc_my').submit();

                            }
                    

                
                             else if (this.selectpayment  == 'payment_type_paypal_checkout') {


                                $('#payPaylPayment').submit();
                            }

                           

               
      }, 2000);


          }
        else{
          Swal.close();
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });
}












submitpayment(){
// $.ajax({
//                     type: 'POST',
//                     url: 'includes/modules/getdata/getFormTemplate/buyReseller.php',
//                     data: {
//                         action: 'place_order_angular',
//                         customer_id: $log_customerId,
//                         select_pay: select_pay,
//                         coupon_code: coupon_code,
//                         coupon_status: coupon_status

//                     },
//                     success: function (data) {
//                       console.log(data);
                
              
//                         result_data = data.split('^^^^');

//                         if (result_data[0] == '111') {

//                             $('.invoice_id').val(result_data[1]);
//                                     $('.credit_data').val(result_data[2]);

//                             if (select_pay == 'payment_type_offline') {

//                                 $('#licenseKeyGenerate').submit();
//                             }

//                             else if (select_pay == 'payment_type_generate_invoice') {

//                                 $('#generate_invoice').submit();
//                             }
                                    
//                              else if (select_pay == 'payment_type_2checkout') {

//                                 $('#2CheckOut').submit();
//                             }
                  
//                    else if (select_pay == 'payment_type_stripe_pay') {

//                                 $('#stripePay_frm').submit();
//                             }
                                    
//                              else if (select_pay == 'payment_type_ocbc_sg_payment') {

//                                 $('#ocbc_sg').submit();

//                             }


//                             else if (select_pay == 'payment_type_ocbc_my_payment') {

//                                 $('#ocbc_my').submit();

//                             }


//                    else if (select_pay == 'payment_type_multi_safe_pay') {

//                                 $('#multiSafePay').submit();

//                             }
//                             else {

//                                 $('#payPaylPayment').submit();
//                             }

                           

//                         } else {

//                             alert('Sorry Some error occur. Please contact admin.'+ result_data[1]);
//                            // $('#payment_submit').removeAttr('disabled');
//                             location.reload();

//                         }

//                     }
//                 });


}

formss(){

}

}


