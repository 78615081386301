import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule }  from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angular2-qrcode';
import { CookieService } from 'ngx-cookie-service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterHelpdeskComponent } from './footer-helpdesk/footer-helpdesk.component';
import { DealRegistrationComponent } from './deal-registration/deal-registration.component';
import { ReferralComponent } from './referral/referral.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { PrepaidNoteComponent } from './prepaid-note/prepaid-note.component';
import { LicenseComponent } from './license/license.component';
import { LeadsComponent } from './leads/leads.component';
import { ResellerPriceListComponent } from './reseller-price-list/reseller-price-list.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { Buy3cxComponent } from './buy3cx/buy3cx.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';
import { SupportTicketDashboardComponent } from './support-ticket-dashboard/support-ticket-dashboard.component';
import { DealRegisterFormComponent } from './deal-register-form/deal-register-form.component';
import { SupportTicketThreadComponent } from './support-ticket-thread/support-ticket-thread.component';
import { LoginComponent } from './login/login.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CmsUserComponent } from './cms-user/cms-user.component';
import { CmsUsernewComponent } from './cms-usernew/cms-usernew.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { NewextensionComponent } from './newextension/newextension.component';
import { CallhistorypbxComponent } from './callhistorypbx/callhistorypbx.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import * as GoQrcode from "go-qrcode";
import { DragDropDirective } from './drag-drop.directive';
import { ReadmoreComponent } from './readmore/readmore.component';
import {RoundProgressModule} from 'angular-svg-round-progressbar';
import { ShoppingcartdetailsComponent } from './shoppingcartdetails/shoppingcartdetails.component';
import { RenewalComponent } from './renewal/renewal.component';
import { GccComponent } from './gcc/gcc.component';
import { CallhistoryvspbxComponent } from './callhistoryvspbx/callhistoryvspbx.component';
import { NotificationComponent } from './notification/notification.component';
import { CostcenterComponent } from './costcenter/costcenter.component';
import { CostcentereditComponent } from './costcenteredit/costcenteredit.component';
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    DashboardComponent,
    FooterHelpdeskComponent,
    DealRegistrationComponent,
    ReferralComponent,
    InvoiceComponent,
    ProfileDetailsComponent,
    CreditNoteComponent,
    PrepaidNoteComponent,
    LicenseComponent,
    LeadsComponent,
    ResellerPriceListComponent,
    CallHistoryComponent,
    Buy3cxComponent,
    SupportTicketComponent,
    SupportTicketDashboardComponent,
    DealRegisterFormComponent,
    SupportTicketThreadComponent,
    LoginComponent,
    ForgotPwdComponent,
    ShoppingCartComponent,
    CmsUserComponent,
    CmsUsernewComponent,
    ExtensionsComponent,
    NewextensionComponent,
    CallhistorypbxComponent,
    DragDropDirective,
    ReadmoreComponent,
    ShoppingcartdetailsComponent,
    RenewalComponent,
    GccComponent,
    CallhistoryvspbxComponent,
    NotificationComponent,
    CostcenterComponent,
    CostcentereditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
     ReactiveFormsModule,
     HttpClientModule,
    FormsModule,
    QRCodeModule,
    EditorModule,
    RoundProgressModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
