<section class="flc content-panelc grey-bgc">

<!-- <ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Invoice </h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Invoice Details</li>
</ul> -->


	<div class="containerc card">
	<div class="row blog-boxc card-body">

	<div class="col-md-12 col-12">
	<div class="section-titlec text-centerc ">
	<div class="aos-item aos-init aos-animate" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
	<div class="aos-item__inner">
	<!-- <span class="badgec badgec-primary">Blog</span> -->
	<h2>{{detail.service_name}}</h2>
	<span class="section-title-linec"></span>
	</div>
	</div>
	</div>
	</div>


	<div class="col-md-12 col-12">
	<div class="author-detailsc">
	<img src="{{detail.profile_image}}"> {{detail.firstName}}<br> 
	<p>Posted on : <strong>{{detail.created_dt}}</strong>, Last modified on : <strong>{{detail.updated_dt}}</strong></p>
	</div>
	<div class="content-detailsc">


	<figure style="margin-right: 20px;    float: left;    overflow: hidden;    width: 160px;    height: 107px;    border: 1px solid #000;">
	<img id="myImg" style="max-width: 160px;    overflow: hidden;  margin-bottom: 15px;  max-height: 105px;    width: 100%;    height: 100%;    transform: scaleX(1);    transition: all 1s;    object-fit: contain;    float: left;" src="{{detail.service_image_name_str}}"><br>
	</figure>

<!-- <div class="blog-short-description mb-2 descrip">
<p>{{l.service_description}}</p>
</div> -->

<div [innerHtml]="detail.service_description">
</div>


	</div>
	</div>
	<!-- <div class="col-md-3 col-12">

	<div class="share-btn">
	<ul>
	<li><a class="btn btn-default" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.call4tel.com%2Fblog-inner-page%2F%3F52%2FCall4tel+opens+a+new+office+at+Tampa%2C+Florida.+Shipping+Straight+from+US.&amp;src=sdkpreparse"><i class="fa fa-facebook" aria-hidden="true"></i> Share</a></li>	
	
	<li><a class="btn btn-blue"  data-size="large" data-text="custom share text" data-url="https://dev.twitter.com/web/tweet-button" data-hashtags="example,demo" data-via="twitterdev" data-related="twitterapi,twitter"><i class="fa fa-twitter" aria-hidden="true"></i> Tweet</a></li>
	</ul>
	</div>

	<section class="side-bar">
						<div class="sidebar-online">
						<h5>3CX NX 32</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/81807082020163141.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/38/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>3CX NX 96</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/209072020180342.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/47/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>NX32-SBC</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/49522012020084707.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/44/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>3CX DUO SBC</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/11912082020175433.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/48/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>HA 3CX</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/79812082020184804.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/49/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>Dual 3CX failover Bracket</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/89614082020100216.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/50/">Read More</a></p>
						</div>
						</div>
						</section><section class="side-bar">
						<div class="sidebar-online">
						<h5>PSU</h5>
						<div class="sidebar-body">
						<div class="thumnail-img">
						<img src="https://dev.cal4care.com/erp/images/online_products/3922012020082252.png">
						</div>
						<p class="blog-read-more"><a href="https://www.call4tel.com/product-details/41/">Read More</a></p>
						</div>
						</div>
						</section></div> -->


					</div>
				</div></section>