import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var $:any;
import Swal from 'sweetalert2'
declare var iziToast:any;
@Component({
  selector: 'app-gcc',
  templateUrl: './gcc.component.html',
  styleUrls: ['./gcc.component.css']
})
export class GccComponent implements OnInit {
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

  gccdata:any=[];
  ipinformation: FormGroup;
  cust_data:any;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {

    if(this.serverService.gccfirewall == 0){
  
 this.router.navigate(['/dashobard']);
}
  		this.gcclist({})
      this.countryflag()
       this.ipinformation = new FormGroup({
      'customername' : new FormControl(null,
    Validators.required),
      'ipaddress' : new FormControl(null,Validators.required)
     });
  }
ngAfterViewInit() {
    Feather.replace();
  }


gcclist(data){
    var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="ip_list";
datas.customer_id = customer_id;
datas.limit = list_data.limit;
datas.offset = list_data.offset;
datas.search_text = list_data.search_text;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="gcc";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.gccdata = response.gcclist
   for (var i = 0; i < this.gccdata.length; i++) {
     if(this.gccdata[i].auto_renew == "1"){
        this.gccdata[i].image = "assets/img/gcc/auto.svg"
     }else{
      if(this.gccdata[i].days == 0)
          this.gccdata[i].image = "assets/img/gcc/day7.png" 
      if(this.gccdata[i].days == 1)
          this.gccdata[i].image = "assets/img/gcc/day6.png" 
      if(this.gccdata[i].days == 2)
          this.gccdata[i].image = "assets/img/gcc/day5.png" 
      if(this.gccdata[i].days == 3)
          this.gccdata[i].image = "assets/img/gcc/day4.png" 
      if(this.gccdata[i].days == 4)
          this.gccdata[i].image = "assets/img/gcc/day3.png"
      if(this.gccdata[i].days == 5)
          this.gccdata[i].image = "assets/img/gcc/day2.png"  
      if(this.gccdata[i].days == 6)
          this.gccdata[i].image = "assets/img/gcc/day1.png"     
     }
   }
          }
        else{
this.gccdata =[];
 
        }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.gccdata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.gcclist({'search_text':searchValue})
}


finishFunction(){
   if(this.ipinformation.valid){
   
     this.submitip()
   }
   else {

        this.validateAllFields(this.ipinformation); 
        }
}



validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }



addip(){
   $('#ipmodal').modal('show');

}

submitip(){
  Swal.fire('Please wait')
Swal.showLoading()
  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
data.action="add_ip_list";
data.global_customer_id=customer_id;
data.global_customer_name=this.cust_data.customer_details.customerName;
data.ip_data=this.ipinformation.value.ipaddress;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="gcc";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
Swal.close()
  iziToast.success({
          message: "Ip Added Sucessfully",
          position: 'topRight'
      });

          }
        else{
Swal.close()

  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}


countryflag(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="contact_flag";
data.customer_id=customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 this.cust_data = response;
 console.log(this.cust_data.customer_details.customerName)
   this.ipinformation.patchValue({

  customername: this.cust_data.customer_details.customerName


});
   this.gcclist({})
          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}



}
