import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent implements OnInit {
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 creditnotedata:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

ngAfterViewInit() {
    Feather.replace();
  }

  ngOnInit(): void {
if(this.serverService.creditnote == 0){
  
 this.router.navigate(['/dashobard']);
}
  	   this.creditnote({})
  }

creditnote(data){
    var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
invoicedata.action="credit_note";
// invoicedata.action="referral";


invoicedata.customer_id = customer_id;
invoicedata.limit = list_data.limit;
invoicedata.offset = list_data.offset;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="login";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.creditnotedata = response.Credit_note
     // this.creditnotedata = response.Referral
     
     
   
          }
        else{

   this.creditnotedata=[];
        }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.creditnotedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.creditnote({'search_text':searchValue})
}


}
