<section class="section">
   <ul class="breadcrumb breadcrumb-style ">
      <li class="breadcrumb-item">
         <h4 class="page-title m-b-0">{{datalist.ticket.subject}}</h4>
         <!-- <h4 class="page-title m-b-0" *ngIf="datalist == ''"></h4> -->

      </li>
      <li class="breadcrumb-item">
         <a routerLink="/DashboardComponent">
         <i data-feather="home"></i></a>
      </li>
      <li class="breadcrumb-item active">Support Ticket</li>
   </ul>
   <div class="section-body">
      <div class="row">
         <div class="col-12">
            <div class="card card-pink">
               <div class="card-header">
                  <h4>Ticket #{{datalist.ticket.ticket_number}}</h4>
               </div>
               <div class="support-ticket-panel">
                  <div class="card-body">
                     <div class="row">
                        <div class="col-md-6">
                           <table class="table table-bordered support-ticket-header-details table-striped">
                              <thead>
                                 <tr>
                                    <th width="160" class="ping-bg-clr">Status</th>
                                    <td>
                                       <a *ngIf="datalist.ticket.ticket_status == 'Open'" data-toggle="tooltip" title="" class="btn btn-danger btn-radius btn-icon icon-left btn-sm" data-original-title="Oen Ticket"><i class="fas fa-ticket-alt"></i> Open
                                       </a>
                                       <a *ngIf="datalist.ticket.ticket_status != 'Open'"  data-toggle="tooltip" title="" class="btn btn-success btn-radius btn-icon icon-left btn-sm" data-original-title="Oen Ticket"><i class="fas fa-ticket-alt"></i> Closed
                                       </a>
                                    </td>
                                 </tr>
                                  <tr>
                                    <th width="160" class="ping-bg-clr">Priority</th>
                                    <td class="red-clr">{{datalist.ticket.priority}}</td>
                                 </tr> 
                                 <tr>
                                    <th width="160" class="ping-bg-clr">Department</th>
                                    <td>
                                       <span class="blue-clr">{{datalist.ticket.department}}</span>
                                    </td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="ping-bg-clr">Create Date</th>
                                    <td>
                                       {{datalist.ticket.create_timestamp}}
                                    </td>
                                 </tr>
                              </thead>
                           </table>
                        </div>
                        <div class="col-md-6">
                           <table class="table table-bordered support-ticket-header-details table-striped">
                              <thead>
                                 <tr>
                                    <th width="160" class="ping-bg-clr">User</th>
                                    <td>
                                       <a href="javascript:;">
                                       <i class="fas fa-users"></i>
                                       {{datalist.ticket.user_name}}</a>
                                    </td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="ping-bg-clr">Email</th>
                                    <td>{{datalist.ticket.user_email.address}}</td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="ping-bg-clr">Source</th>
                                    <td>{{datalist.ticket.source}}</td>
                                 </tr>
                               <!--   <tr>
                                    <th width="160" class="ping-bg-clr">Customer</th>
                                    <td>
                                       <a href="javascript:;">Acer Computer (Singapore) Pte Ltd - Contract</a>
                                    </td>
                                 </tr> -->
                              </thead>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-12">
            <div class="card card-success">
               <div class="support-ticket-panel">
                  <div class="card-body">
                     <div class="row">
                        <div class="col-md-6">
                           <table class="table table-bordered support-ticket-header-details table-striped">
                              <thead>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">Assigned To</th>
                                    <td *ngIf="datalist.ticket.assigned_to.length != 0">{{datalist.ticket.assigned_to[0].name}}</td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">SLA Plan</th>
                                    <td>Default SLA</td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">Due Date</th>
                                    <td>
                                       <span class="blue-clr">{{datalist.ticket.due_timestamp}}</span>
                                    </td>
                                 </tr>
                              </thead>
                           </table>
                        </div>
                        <div class="col-md-6">
                           <table class="table table-bordered support-ticket-header-details table-striped">
                              <thead>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">Help Topic</th>
                                    <td>
                                       <a href="http://helpdesk.guru/kb/" target="_blank">Help Desk <i class="fas fa-external-link-alt"></i></a>
                                    </td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">Last Message</th>
                                    <td>{{datalist.ticket.last_message_timestamp}}</td>
                                 </tr>
                                 <tr>
                                    <th width="160" class="light-green-bg-clr">Last Response:</th>
                                    <td>{{datalist.ticket.last_response_timestamp}}</td>
                                 </tr>
                              </thead>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!------------------------------
         Ticket Thread
         -------------------------------->
         <div *ngFor="let l of datalist.ticket.thread_entries;let c= index;">
      <div class="row" *ngIf='l.model.type == "M" && !l.show'>
         <div class="col-sm-11 col-12" >
            <div class="card card-primary" >
               <div class="card-header">
                  <h4>{{l.model.poster}} <small>posted {{l.model.created}}</small></h4>
                  <div class="card-header-action">
                     <a data-collapse="#ticketThread-2" class="btn btn-icon btn-success" >
                     <i class="fas fa-minus"></i></a>
                  </div>
               </div>
               <div class="collapse support-ticket-panel show" id="ticketThread-2">
                  <div class="card-body" [innerHtml]="l.model.body">
                  
                  </div>
               </div>
 
<a  *ngIf="c == '4'  && conversation_show" class="btn btn-warning btn-icon  icon-left aligniconthread" (click)="showmore('0')">
<i class="fas fa-arrow-up"></i>
<!-- <i class="fas fa-arrow-down"></i> -->
{{datalist.ticket.thread_entries.length - 5}}+ Conversation
</a>
<a  *ngIf="c == '4'  && !conversation_show" class="btn btn-warning btn-icon  icon-left aligniconthread" (click)="showmore('1')">
<!-- <i class="fas fa-arrow-up"></i> -->
<i class="fas fa-arrow-down"></i>
{{datalist.ticket.thread_entries.length - 5}}+ Conversation
</a>

            </div>
         </div>
         <div class="col-sm-1 col-12">
            <div class="ticket-profile-img" [innerHtml]="datalist.user_avatar">
               <!-- <img src="{{datalist.user_avatar}}"  data-toggle="tooltip" title="Michelle Chan"> -->
            </div>
         </div>
      </div>
      <!------------------------------
         Ticket Thread End
         -------------------------------->
      <!------------------------------
         Ticket Thread
         -------------------------------->
      <div class="row" *ngIf='l.model.type == "R" && !l.show'>
         <div class="col-sm-1 col-12">
            <div class="ticket-profile-img text-right"  [innerHtml]="datalist.staff_avatar">
               <!-- <img src="{{datalist.staff_avatar}}" data-toggle="tooltip" title="JN"> -->
            </div>
         </div>
         <div class="col-sm-11 col-12">
            <div class="card card-warning">
               <div class="card-header">
                  <h4>{{l.model.poster}} <small>posted {{l.model.created}}</small></h4>
                  <div class="card-header-action">
                     <a data-collapse="#ticketThread-1" class="btn btn-icon btn-success" >
                     <i class="fas fa-minus"></i></a>
                  </div>
               </div>
               <div class="collapse support-ticket-panel show" id="ticketThread-1">
                  <div class="card-body"  [innerHtml]="l.model.body">

                  </div>
               </div>

<a  *ngIf="c == '4' && conversation_show" class="btn btn-warning btn-icon  icon-left aligniconthread" (click)="showmore('0')">
<i class="fas fa-arrow-up"></i>

 {{datalist.ticket.thread_entries.length - 5}}+ Conversation
</a>

<a  *ngIf="c == '4' && !conversation_show" class="btn btn-warning btn-icon  icon-left aligniconthread" (click)="showmore('1')">

<i class="fas fa-arrow-down"></i>
 {{datalist.ticket.thread_entries.length - 5}}+ Conversation
</a>


            </div>
         </div>
      </div>



   </div>
      <!------------------------------
         Ticket Thread End
         -------------------------------->
      <!------------------------------
         Ticket  Reply
         -------------------------------->
      <div class="row" *ngIf='datalist.ticket.ticket_status != "Closed"'>
         <div class="col-sm-12 col-12">
            <div class="card card-purple">
               <div class="card-header">
                  <h4>Post a Reply</h4>
               </div>
               <div class="collapse support-ticket-panel show">
                  <div class="card-body p-0">
                     <!-- <form class="composeForm"> -->
                        <!-- <textarea name="ck-editor"> -->
                        <!-- </textarea> -->
 <div class="form-group">
<kendo-editor [value]="value" [(ngModel)]="replaymessage" style="height: 250px;margin: 10px 10px;"></kendo-editor> 

</div>


       <div class="form-group">
             <!-- <label >File Upload </label>  -->
   <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFiless($event)">
  <input hidden type="file" #fileInput (change)="uploadFile($event)">
</div>
<div  class="files-list" *ngFor="let file of files;let i= index">
    <p> {{ file }} </p>
    <button class="delete-file" (click)="deleteAttachment(i)">
      <a  data-toggle="tooltip" title="View" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>
    </button>
 </div>
</div>







                     <!-- </form> -->
                     <div class="m-l-25 m-b-20">
                        <button type="button" (click)="replyOldticket()" class="btn btn-success btn-border-radius waves-effect"><i class="fas fa-paper-plane"></i> &nbsp;&nbsp; Post Reply</button> &nbsp;&nbsp;
                        <button routerLink="/support-ticket" type="button" class="btn btn-danger btn-border-radius waves-effect" >Discard</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!------------------------------
         Ticket  Reply End
         -------------------------------->
   </div>
</section>

