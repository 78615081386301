import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
declare var iziToast:any;
@Component({
  selector: 'app-costcenteredit',
  templateUrl: './costcenteredit.component.html',
  styleUrls: ['./costcenteredit.component.css']
})
export class CostcentereditComponent implements OnInit {
username:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.adddropdown()
  }

finishFunction(){

}





editcost_sender(senderid){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="edit_cost_sender";
datas.customer_id = customer_id;
datas.cost_sender_id = senderid;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
      //    iziToast.success({
      //     message: "Deleted Successfully",
      //     position: 'topRight'
      // });


          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}

adddropdown(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="add_dropdown";
datas.customer_id = customer_id;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          this.username = response.options;
      //    iziToast.success({
      //     message: "Deleted Successfully",
      //     position: 'topRight'
      // });


          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}

add_cost_sender(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="add_cost_sender";
datas.customer_id = customer_id;
datas.mapping_name = customer_id;
datas.from_display_name = customer_id;
// datas.customer_id = customer_id;

// "customer_id": "Mjg2NQ==",
//         "mapping_name":"test",
//         "from_display_name":"0002139738908,00070642"

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
      //    iziToast.success({
      //     message: "Deleted Successfully",
      //     position: 'topRight'
      // });


          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}


updatecost_sender(senderid){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="update_cost_sender";
datas.customer_id = customer_id;
datas.mapping_name = customer_id;
datas.from_display_name = customer_id;
datas.cost_sender_id = senderid;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="costsender";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
      //    iziToast.success({
      //     message: "Deleted Successfully",
      //     position: 'topRight'
      // });


          }
        else{
    // iziToast.error({
    //       message: "Please try again later",
    //       position: 'topRight'
    //   });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}


}
