import { Component, OnInit } from '@angular/core';
declare var $:any;
declare var doCall: any;
declare var transferCall: any;
declare var init_page: any;
declare var getinStatusV2: any;
declare var iziToast:any;
declare var outgoingCallEnd:any;
declare var makecallTransfer:any;
declare var makecallTransfer2:any;
declare var doCall3:any;
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-footer-helpdesk',
  templateUrl: './footer-helpdesk.component.html',
  styleUrls: ['./footer-helpdesk.component.css']
})

export class FooterHelpdeskComponent implements OnInit {
closes= 1;
sip_login;
  constructor() { }

  ngOnInit(): void {

// var sipLogin = '000';
// var sipauthenticationName = '3YAEcao70l';
// var sippassword = 'htfsfXhi8n';
// var sipOptions = {
// 	login: sipLogin,
// 	authenticationName: sipauthenticationName,
// 	password: sippassword,
// 	domain: 'cal4care.3cx.sg',
// 	outboundProxy: 'cal4care.3cx.sg',
// 	port: '5060'
// };

	$('#sip_urld').val("cal4care.3cx.sg");
	$('#server').val("sip:cal4care.3cx.sg:5060");
	$('#username').val("sip:000@cal4care.3cx.sg");
	$('#authuser').val("3YAEcao70l");
	$('#password').val("htfsfXhi8n");
	$('#displayname').val('devomni Channel');
	setTimeout( () => { init_page("sip:000@cal4care.3cx.sg","3YAEcao70l","htfsfXhi8n","sip:cal4care.3cx.sg:5060","5060");  }, 3000 );
  }


closefunctions(){
	if(this.closes == 1){
	$('.footer-help-desk').css('display','block');
	this.closes = 0;
	}else{
	$('.footer-help-desk').css('display','none');
	this.closes = 1;
	}
}

ngAfterViewInit() {
    Feather.replace();
  }

webMakeCall(number_key){
  // alert(number_key); return false;
  $('#make_call_number').val(number_key);       
  doCall3('',number_key);
}


outgoingCallEnd() {
    outgoingCallEnd();
    $("#makecallHanupBtn").click();
    $('#makeCallForwordNumber').val('');
}





}
