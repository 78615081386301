<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Support Ticket</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Support Ticket List</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Support Ticket</h4>
<div class="card-header-action">
<a data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
	<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse support-ticket-panel show" id="tableCollapse">
<div class="card-body">
	 <div class="form-group row mb-4">
                  <div class="col-sm-12 col-md-7">
                  	<!-- data-toggle="modal"  data-target=".createticketModal"  -->
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-primary" (click)="createticket()"><i class="fas fa-plus-circle"></i> Create Tickets</button>

<!-- <a routerLink="/deal-registration" data-toggle="modal" data-target=".dealRegisterModal" class="btn btn-info btn-icon icon-left">
<i class="fas fa-handshake"></i> Deal Registration
</a> -->




                  </div>
               </div>
	<div class="table-responsive">
		<table class="table table-bordered support-ticket-table table-striped" id="">
			<thead>
				<tr>
					<th>Ticket Number</th>
					<th>Status</th>
					<th>Date</th>
					<th>Subject</th>
					<!-- <th>From</th> -->
					<th>Department</th>
					<th>Action</th>
				</tr>
			</thead>
			<tbody>
				<tr class="new-ticket" *ngFor="let l of opentickets;let i = index">
					<td><a (click)="gotck(l.ticketnumber)"><b>#{{l.ticketnumber}}</b></a></td>
					<td>
						<a  data-toggle="tooltip" title="Open Ticket" class="btn btn-danger btn-radius btn-icon icon-left btn-sm">
							<i class="fas fa-ticket-alt"></i> {{l.status}}
						</a>
					</td>
					<td>{{l.dates}}</td>
					<td>{{l.subject}}</td>
					<!-- <td>{{l.subject}}</td> -->
					<td>{{l.dept}}</td>
					<td>
						<a (click)="gotck(l.ticketnumber)" data-toggle="tooltip" title="View" class="btn btn-danger btn-sm">
							<i class="fas fa-eye"></i> View Ticket
						</a>
					</td>
				</tr>




				<!-- <tr class="unread-ticket">
					<td><a routerLink="/support-ticket-thread"><b>#150486</b></a></td>
					<td>
						<a routerLink="/support-ticket-thread" class="btn btn-info btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Unread Message">
							<i class="fas fa-envelope"></i> Unread
						</a>
					</td>
					<td>20/08/2020 1:27 pm</td>
					<td>Re: RE: CRM-3CX Integration Issue</td>
					<td>Chris Johnson</td>
					<td>Support SG</td>
					<td>
						<a routerLink="/support-ticket-thread" data-toggle="tooltip" title="View" class="btn btn-info btn-sm">
							<i class="fas fa-eye"></i> View Ticket
						</a>
					</td>
				</tr>
 -->
			<!-- 	<tr class="new-ticket">
					<td><a routerLink="/support-ticket-thread"><b>#150486</b></a></td>
					<td>
						<a routerLink="/support-ticket-thread" class="btn btn-danger btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Open Ticket">
							<i class="fas fa-ticket-alt"></i> Open
						</a>
					</td>
					<td>20/08/2020 1:27 pm</td>
					<td>Re: RE: CRM-3CX Integration Issue</td>
					<td>Chris Johnson</td>
					<td>Support SG</td>
					<td>
						<a routerLink="/support-ticket-thread" data-toggle="tooltip" title="View" class="btn btn-danger btn-sm">
							<i class="fas fa-eye"></i> View Ticket
						</a>
					</td>
				</tr> -->

				<!-- <tr class="unread-ticket">
					<td><a routerLink="/support-ticket-thread"><b>#150486</b></a></td>
					<td>
						<a routerLink="/support-ticket-thread" class="btn btn-info btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Unread Message">
							<i class="fas fa-envelope"></i> Unread
						</a>
					</td>
					<td>20/08/2020 1:27 pm</td>
					<td>Re: RE: CRM-3CX Integration Issue</td>
					<td>Chris Johnson</td>
					<td>Support SG</td>
					<td>
						<a routerLink="/support-ticket-thread" data-toggle="tooltip" title="View" class="btn btn-info btn-sm">
							<i class="fas fa-eye"></i> View Ticket
						</a>
					</td>
				</tr> -->
			<!-- 	<tr class="unread-ticket">
					<td><a routerLink="/support-ticket-thread"><b>#150486</b></a></td>
					<td>
						<a routerLink="/support-ticket-thread" class="btn btn-info btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Unread Message">
							<i class="fas fa-envelope"></i> Unread
						</a>
					</td>
					<td>20/08/2020 1:27 pm</td>
					<td>Re: RE: CRM-3CX Integration Issue</td>
					<td>Chris Johnson</td>
					<td>Support SG</td>
					<td>
						<a routerLink="/support-ticket-thread" data-toggle="tooltip" title="View" class="btn btn-info btn-sm">
							<i class="fas fa-eye"></i> View Ticket
						</a>
					</td>
				</tr> -->
			</tbody>

 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>

		</table>
	</div>


<div class="card-footer text-right" *ngIf="recordNotFound == false">
	<!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData.info == 'show'">
<!-- -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? open_ticket({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="open_ticket({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? open_ticket({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="open_ticket({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? open_ticket({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>









</div>
</div>
</div>
</div>
</div>
<!----------------------
Closed Tickets
----------------------->
<div class="row">
<div class="col-12">
<div class="card card-success">
<div class="card-header">
<h4>Closed Tickets</h4>
<div class="card-header-action">
<a data-collapse="#supportTicketCollapse" class="btn btn-icon btn-success" >
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse support-ticket-panel show" id="supportTicketCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="success-tbl table table-bordered support-ticket-table table-striped" id="">
<thead>
<tr>
	<th>Ticket Number</th>
	<th>Status</th>
	<th>Agent</th>
	<th>Date</th>
	<th>Subject</th>
	<!-- <th>From</th> -->
	<th>Department</th>
	<th>Action</th>
</tr>
</thead>
<tbody>
<tr  *ngFor="let c of closetickets;let i = index">
	<td><a ><b>#{{c.ticketnumber}}</b></a></td>
	<td>
		<a  class="btn btn-success btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Closed Ticket" >
			<i class="fas fa-check-circle"></i>{{c.status}}
		</a>
	</td>
	<td><span class="agent-img" [innerHtml]="c.image"></span></td>
	<td>{{c.dates}}</td>
	<td>{{c.subject}}</td>
	<!-- <td>Warner</td> -->
	<td>{{c.dept}}</td>
	<td>
		<a  data-toggle="tooltip" title="View"  (click)="gotck(c.ticketnumber)" class="btn btn-success btn-sm">
			<i class="fas fa-eye"></i> View Ticket
		</a>
	</td>
</tr>





<!-- <tr>
	<td><a href="#"><b>#150486</b></a></td>
	<td>
		<a href="#" class="btn btn-success btn-radius btn-icon icon-left btn-sm" data-toggle="tooltip" title="Closed Ticket">
			<i class="fas fa-check-circle"></i> Closed
		</a>
	</td>
	<td><span class="agent-img"><img src="assets/img/custom-images/user-img.jpeg"></span></td>
	<td>20/08/2020 1:27 pm</td>
	<td>Re: RE: CRM-3CX Integration Issue</td>
	<td>Warner</td>
	<td>Support SG</td>
	<td>
		<a href="javascript:;" data-toggle="tooltip" title="View" class="btn btn-success btn-sm">
			<i class="fas fa-eye"></i> View Ticket
		</a>
	</td>
</tr> -->
</tbody>

<tr *ngIf="recordNotFound2 == true">
                 <td colspan="12">No Record Found</td>
               </tr>


</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound2 == false">
  <!-- *ngIf="recordNotFound == false" -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData2.info == 'show'">
<!-- *ngIf="paginationData.info == 'show'" -->
                  <li class="page-item">
                    <a [class]="paginationData2.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.backtab=='show' ? closedticket({'search_text':'','offset':paginationData2.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData2.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="closedticket({'search_text':'','offset':paginationData2.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData2.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? closedticket({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData2.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="closedticket({'search_text':'','offset':paginationData2.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData2.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData2.nexttab =='show' ? closedticket({'search_text':'','offset':paginationData2.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>







</div>
</div>
</div>
</div>
</div>

<!------------------------------
Support Ticket End
------------------------>

</div>
</section>











<!-- Create Ticket Modal -->
<div class="modal fade createticketModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
aria-hidden="true"  id="addNewPopup">
<div class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="myLargeModalLabel">Create Tickets</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

		

			<form class="deal-register-form" [formGroup]="createinformation">

				<div class="form-group">
					<label for="partner-name">From</label>
						<input type="text" class="form-control" id="partner-name" formControlName="fromticket" readonly>
						  <div class="error-message message-small" *ngIf="!createinformation.get('fromticket').valid && createinformation.get('fromticket').touched">
                     Please enter Name
					</div>
			</div>

	<div class="form-group">
					<label for="partner-name">Department</label>
						<input type="text" class="form-control" id="partner-name" formControlName="department" readonly>
						  <div class="error-message message-small" *ngIf="!createinformation.get('department').valid && createinformation.get('department').touched">
                     Please enter Department
					</div>
			</div>

<div class="form-group">
					<label for="partner-name">Subject</label>
						<input type="text" class="form-control" id="partner-name" formControlName="subject">
						  <div class="error-message message-small" *ngIf="!createinformation.get('subject').valid && createinformation.get('subject').touched">
                     Please enter Subject
					</div>
			</div>


	<div class="form-group">
					<label >Message</label>
						<!-- <input type="text" class="form-control" id="partner-name" formControlName="department" readonly> -->
                    <kendo-editor [value]="value"  formControlName="messages" style="height: 250px;"></kendo-editor> 

						 <!--  <div class="error-message message-small" *ngIf="!createinformation.get('department').valid && createinformation.get('department').touched">
                     Please enter Department
					</div> -->
			</div>



			
				 
				
				 <div class="form-group">
				 <label >File Upload </label>	
	<div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFiless($event)">
  <input hidden type="file" #fileInput (change)="uploadFile($event)">
</div>
<div  class="files-list" *ngFor="let file of files;let i= index">
    <p> {{ file }} </p>
    <button class="delete-file" (click)="deleteAttachment(i)">
      <a  data-toggle="tooltip" title="View" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>
    </button>
 </div>
</div>




				
				<div class="btn-groups aligncenter">
					<a  class="btn btn-success mr-3" (click)="finishFunction()">Submit</a>
					<a  class="btn btn-info" data-dismiss="modal">Cancel</a>
				</div>
			</form>
		</div>
	</div>
</div>
</div>
<!-- Create Ticket Modal End -->



