<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Referral</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Referral Details</li>
</ul>

<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Referral Details</h4>

<div class="card-header-action">
<div class="badge ping-bg-clr mr-4 white-clr badge-shadow">
Unpaid Amount : <b>{{unpaidamount}}</b> </div>
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#referralCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="referralCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped" >
	<!-- id="dataTable" -->
<thead>
<tr>
<!-- <th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th> -->

<th>S.No</th>
<th>Invoice No</th>
<th>Comm Date</th>
<th>Invoice Date</th>
<th>Customer Name</th>
<th>Referral Type</th>
<th>Invoice Amt</th>
<th>Currency</th>
<th>Referral Amount</th>
<th>Conv Amount</th>
<th>Customer Paid</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<!-- <tr>
<td class="text-center pt-2">
	<div class="custom-checkbox custom-control">
		<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
		id="checkbox-1">
		<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
	</div>
</td>
<td>INC0598-890058</td>
<td>29-08-2020</td>
<td>28-08-2020</td>
<td>Two A Plus Company Ltd</td>
<td>
	Web
</td>
<td>6000</td>
<td>
	<div class="badge badge-success badge-shadow">&nbsp;USD&nbsp;</div>
</td>
<td>5000</td>
<td>200</td>
<td>4500</td>
<td class="action-btn-group">
	<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
	<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr>

 <tr>
<td class="text-center pt-2">
	<div class="custom-checkbox custom-control">
		<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
		id="checkbox-1">
		<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
	</div>
</td>
<td>INV98205-890058</td>
<td>02-10-2020</td>
<td>01-10-2020</td>
<td>Thomship Management</td>
<td>
	Web
</td>
<td>5800</td>
<td>
	<div class="badge badge-warning badge-shadow">&nbsp;SGD&nbsp;</div>
</td>
<td>5000</td>
<td>200</td>
<td>4500</td>
<td class="action-btn-group">
	<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
	<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> 
<tr>
<td class="text-center pt-2">
	<div class="custom-checkbox custom-control">
		<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
		id="checkbox-1">
		<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
	</div>
</td>
<td>INC0598-890058</td>
<td>29-08-2020</td>
<td>28-08-2020</td>
<td>Two A Plus Company Ltd</td>
<td>
	Web
</td>
<td>6000</td>
<td>
	<div class="badge badge-success badge-shadow">&nbsp;USD&nbsp;</div>
</td>
<td>5000</td>
<td>200</td>
<td>4500</td>
<td class="action-btn-group">
	<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
	<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr>
<tr>
<td class="text-center pt-2">
	<div class="custom-checkbox custom-control">
		<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
		id="checkbox-1">
		<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
	</div>
</td>
<td>INC0598-890058</td>
<td>29-08-2020</td>
<td>28-08-2020</td>
<td>Two A Plus Company Ltd</td>
<td>
	Web
</td>
<td>6000</td>
<td>
	<div class="badge badge-success badge-shadow">&nbsp;USD&nbsp;</div>
</td>
<td>5000</td>
<td>200</td>
<td>4500</td>
<td class="action-btn-group">
	<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
	<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> -->
<tr  *ngFor ="let l of referrallistdata;let i = index;"  [ngClass]="(l.ReferalStatus =='notpaid')?'red-grad':'green-grad'" class="bordergap">
	 <!-- [class.selected]="i==selectedUser" -->
<!-- green-grad  -->

<!-- <td class="text-center pt-2">
	<div class="custom-checkbox custom-control">
		<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
		id="checkbox-1">
		<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
	</div>
</td>
 -->

<td>{{offset_count+i+1}}</td>

<td>{{l.Invoiceno}}</td>
<td>{{l.Commdate}}</td>
<td>{{l.Invoicedate}}</td>
<td>{{l.Customername}}</td>
<td>
	{{l.Referraltype}}
</td>
<td>{{l.Invoiceamt}}</td>
<td>
	<div class="badge badge-info badge-shadow">{{l.Currency}}</div>
</td>
<td>{{l.Refferalamount}}</td>
<td>{{l.Conversionamount}}</td>
<td [ngClass]="(l.InvStatus =='Paid' && l.ReferalStatus =='notpaid')?'bk-paid':''">{{l.InvStatus}}</td>
<td class="action-btn-group">
	<a *ngIf='l.Attachment != ""' href="{{l.Attachment}}" class="btn btn-danger" target="_blank"  data-toggle="tooltip"><i class="far fa-file-pdf"></i></a>


	<a  *ngIf='l.Remarksoption != "no"' class="btn btn-primary"><i class="far fa-comment"></i></a>
</td>
</tr>

<!-- <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>

 -->

</tbody>


<tr *ngIf="recordNotFound == true">
                       <td colspan="12">Data not found</td>
                     </tr>


</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">
	
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? referrallist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="referrallist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? referrallist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="referrallist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? referrallist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>



</div>
</div>
</div>
</div>
</div>
</div>
</section>