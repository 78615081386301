import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-support-ticket-dashboard',
  templateUrl: './support-ticket-dashboard.component.html',
  styleUrls: ['./support-ticket-dashboard.component.css']
})
export class SupportTicketDashboardComponent implements OnInit {
 files: any = [];
 createinformation: FormGroup;
 value;
 counts:any={};
 customerdetails:any={};
 idmessage;
 datadetails:any;
 filesend:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.countryflag()
  	 	this.ticket_count();
        this.createinformation = new FormGroup({
      'fromticket' : new FormControl(null,
    Validators.required),
      'department' : new FormControl(null,Validators.required),
      'subject' : new FormControl(null,Validators.required),
      'messages' : new FormControl(null)

     });


    
  }
ngAfterViewInit() {
    Feather.replace();
  }
ticket_count(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="ticket_count";
datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.counts = response.counts
   
          }
        else{
this.counts={}
 
        }

      }, 
      (error)=>{
          console.log(error);
      });

}


  
finishFunction(){
  // console.log(this.idmessage)
  // console.log($('#idmessage').val())
    // console.log(this.value)

    console.log(this.createinformation.value)
   if(this.createinformation.valid){
   
     this.createnewticket()
   }
   else {

        this.validateAllFields(this.createinformation); 
        }
}


// countryflag(){

//   let data:any =new Object();
//  let datasend:any = new Object();
//     let access_token: any=localStorage.getItem('at');
//     let customer_id: any=localStorage.getItem('en');
   
   
// data.action="contact_flag";
// data.customer_id=customer_id;

//  datasend.access_token=access_token;
//   datasend.operation="curlDatas";
//   datasend.moduleType="login";
//   datasend.api_type="web";
//   datasend.element_data = data;
//       this.serverService.sendServer(datasend).subscribe((response: any) => {
// console.log(response)
//         if(response.status=="true"){
//  this.customerdetails = response.customer_details;
//     this.createinformation.patchValue({

//   fromticket: this.customerdetails.customerName, 
//   department:"Support_SG"

// });
//           }
//         else{
 
//         }      
//       }, 
//       (error)=>{
//           console.log(error);
//       });

// }

countryflag(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="customer_data";
data.customer_id=customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 this.customerdetails = response.customer_data;
 this.datadetails= response;
   this.createinformation.patchValue({

  fromticket: this.customerdetails.customerName, 
  department:"Support_SG"

});
          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}

// createnewticket(){
//  Swal.fire('Please wait')
// Swal.showLoading()
//  let access_token: any=localStorage.getItem('at');
//     let customer_id: any=localStorage.getItem('en');
  
//       const forms = new FormData();

//       forms.append('name', this.customerdetails.customerName);
//       forms.append('email', this.customerdetails.email);
//       forms.append('dept_id', '5');
//       forms.append('ss',this.createinformation.value.subject);
//       forms.append('message',this.createinformation.value.messages);
//       forms.append('custs_id',  this.customerdetails.customerId);
//       forms.append('custs_code',  this.customerdetails.customerCode);
//       forms.append('attachment', this.files);
//       forms.append('action_info', 'add_ticket');
//       forms.append('action', 'create_ticket');
//       forms.append('operation', 'curlData');
//       forms.append('access_token', access_token);
//       forms.append('moduleType', 'login');
//       forms.append('api_type', 'web');

//       console.log(forms);
//       var self = this;
//       $.ajax({
//         url: "https://erp.cal4care.com/cms/api_cms/v1.0/index_new.php",
//         type: 'POST',
//         data: forms,
//         processData: false,  // tell jQuery not to process the data
//         contentType: false,
//         success: function (data) {
// console.log(data)
 
//           // self.apiservice.dismiss();
//           // Swal.fire({
//           //   title: 'Ticket' + ' ' + '#' + data,
//           //   text: 'Ticket has Successfully Created',
//           //   icon: 'success',
//           //   // showCancelButton: true,
//           //   confirmButtonText: 'OK',
//           //   // cancelButtonText: 'No'
//           // })
//         }
//       });

  

//  setTimeout (() => {
//    this.ticket_count();
//       Swal.close();
//   $('#addNewPopup').modal('hide');
//  iziToast.success({
//           message: "Ticket Created Sucessfully",
//           position: 'topRight'
//       });
    
//          console.log("Hello from setTimeout");
//       }, 3000);
//   }

createnewticket(){

 
 

 Swal.fire('Please wait')
Swal.showLoading()
 let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
  
      const forms = new FormData();

      forms.append('name', this.customerdetails.customerName);
      forms.append('email', this.datadetails.email_id);
      forms.append('dept_id', '5');
      forms.append('ss',this.createinformation.value.subject);
      forms.append('message', this.createinformation.value.messages.replace(/<(?:.|\n)*?>/gm, ' '));
      forms.append('custs_id',  this.datadetails.customer_id);
      forms.append('custs_code',  this.customerdetails.customerCode);
      forms.append('attachment',  this.file);
      forms.append('action_info', 'add_ticket');
      forms.append('action', 'create_ticket');
      forms.append('operation', 'curlData');
      forms.append('access_token', access_token);
      forms.append('moduleType', 'login');
      forms.append('api_type', 'web');

      console.log(forms);
      var self = this;
      $.ajax({
        url: "https://erp.cal4care.com/cms/api_cms/v1.0/index_new.php",
        type: 'POST',
        data: forms,
        processData: false,  // tell jQuery not to process the data
        contentType: false,
        success: function (data) {
console.log(data)

     
        }
      });



 setTimeout (() => {
   
    this.ticket_count();
  $('#addNewPopup').modal('hide');
 Swal.close();
 iziToast.success({
          message: "Ticket Created Sucessfully",
          position: 'topRight'
      });
         console.log("Hello from setTimeout");
      }, 3000);
     
  }

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }



  // uploadFile(event) {
  //   console.log(event)
  //   for (let index = 0; index < event.length; index++) {
  //     const element = event[index];
  //     this.files.push(element.name)
  //   }  
  // }


 localUrl;
 file: File;
  uploadFiless(event) {
     console.log(event[0])
       this.file = event[0];
 if(event[0] != undefined){
      this.filesend.push(event[0])
      
    }
     
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name);
    }  
console.log(this.filesend)

  }



  uploadFile(event: any) {
    this.file = event.target.files[0];

    console.log(this.file)
    if(this.file != undefined){

     this.filesend.push(this.file)
    }
    var events = event.target.files;
 for (let index = 0; index < events.length; index++) {
      const element = events[index];
      this.files.push(element.name)
    } 

console.log(this.filesend)

    }




createticket(){
   this.files= [];
 this.filesend=[];
 this.file =null;
  this.createinformation.patchValue({
  messages:"",
  subject:""
 
});
   $('#addNewPopup').modal('show');

}

}
