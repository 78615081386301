import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
declare var iziToast:any;
@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
access_token:any;
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 recordNotFound2 = false;
 pageLimit2 = 10;
 paginationData2:any ={"info":"hide"};
 offset_count2 = 0;

 recordNotFound3 = false;
 pageLimit3 = 10;
 paginationData3:any ={"info":"hide"};
 offset_count3 = 0;

perfomainvoicedata:any=[];
unpaidinvoicedata:any=[];
paidinvoicedata:any=[];

sendidsarray:any=[];
selectids:any=[];
sendid ='';

allcheck= false;
uncheck = 0;
deletearr:any=[];
totl_amt = 0;

isCheckedall = false;

  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {

    if(this.serverService.invoice == 0){
  
 this.router.navigate(['/dashobard']);
}
    this.unpaidinvoices({})
     this.paidinvoices({})
     this.perfomainvoices({})
     
    

  }
doSomethingWithCurrentValue(ev){

}

ngAfterViewInit() {
    Feather.replace();
  }
listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 


listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit2 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 


getsearchpaidinvoices(searchValue: string): void {  
  console.log(searchValue);
  this.paidinvoices({'search_text':searchValue})
}
getsearchunpaidinvoices(searchValue: string): void {  
  console.log(searchValue);
  this.unpaidinvoices({'search_text':searchValue})
}

paidinvoices(data){
    var list_data2= this.listDataInfo2(data);

console.log(this.serverService.ci)
  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
invoicedata.action="invoice_list";
invoicedata.customer_id = customer_id;
invoicedata.type = "paid";
invoicedata.limit = list_data2.limit;
invoicedata.offset = list_data2.offset;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="login";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     // this.locations = response.result.data
   this.paidinvoicedata = response.List

          }
        else{

    this.paidinvoicedata=[];
        }
   this.offset_count2 = list_data2.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit2 });
         console.log(this.paginationData2);
                this.recordNotFound2 = this.paidinvoicedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}






unpaidinvoices(data){
    var list_data= this.listDataInfo(data);

console.log(this.serverService.ci)
  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
invoicedata.action="invoice_list";
invoicedata.customer_id = customer_id;
invoicedata.type = "unpaid";
invoicedata.limit = list_data.limit;
invoicedata.offset = list_data.offset;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="login";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     // this.unpaidinvoicedata = 
     for (var i = 0; i < response.List.length; i++) {
       if(response.List[i].performa_invoice != "1")
        this.unpaidinvoicedata.push(response.List[i])
     }
     
// 
if(this.allcheck == true){

this.checkallbox()

}else{

if(this.uncheck == 1) {
this.uncheckallbox()
}else{

if(this.unpaidinvoicedata.length != 0){
if(this.deletearr.length != 0){
for (var i = 0; i < this.unpaidinvoicedata.length; i++) {
   var ismatch = false;
  for (var j = 0; j < this.deletearr.length; j++) {
   if(this.unpaidinvoicedata[i].billId ==  this.deletearr[j]){
     ismatch = true;
     this.unpaidinvoicedata[i].add = true;
     break;  

   }
 }
 if (!ismatch){
         this.unpaidinvoicedata[i].add = false//  checkbox status false
      
      } 

}
}
else{
  
  for (var i = 0; i < this.unpaidinvoicedata.length; i++) {
     this.unpaidinvoicedata[i].add = false  
  }
}


}

}
}








          }
        else{

     this.unpaidinvoicedata=[];
        }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.unpaidinvoicedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}

uncheckallbox(){
 for (var i = 0; i < this.unpaidinvoicedata.length; i++) { 
  this.unpaidinvoicedata[i].add = false;
   this.deletearr=[];
    // this.deletearr.push(this.studentslist[i].id)
 }
 console.log(this.deletearr)
 this.overduepayment()
}

checkCheckBoxvalue(){
  console.log(this.isCheckedall)
  if(this.isCheckedall == false){
this.checkallbox()
  }else{
this.uncheckallbox()
  }
}
checkallbox(){

  console.log('enter all check')
   // this.deletearr =[];
 

 console.log(this.deletearr)
  for (var i = 0; i < this.unpaidinvoicedata.length; i++) { 
 var ismatchs = false;
if(this.deletearr.length != 0){

  for (var j = 0; j < this.deletearr.length; j++) {
   if(this.unpaidinvoicedata[i].billId !==  this.deletearr[j]){
    
console.log('exists')
// this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );
ismatchs = true;
     this.unpaidinvoicedata[i].add = true;
    this.deletearr.push(this.unpaidinvoicedata[i].billId)

     break;  

   }
   // else{
     
   // }
 }
 if(!ismatchs){
// this.studentslist[i].add = false;
    // this.deletearr.push(this.studentslist[i].id)

      } 
}else{
  this.unpaidinvoicedata[i].add = true;
    this.deletearr.push(this.unpaidinvoicedata[i].billId)
}

}
 console.log(this.deletearr)

this.overduepayment()

}

overduepayment(){

  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   

  // this.selectids =[]

  this.sendidsarray =[];
  this.sendid ="";

  for (var i = 0; i < this.deletearr.length; i++) {
  this.sendidsarray.push(this.deletearr[i])
  }

    this.sendid=this.sendidsarray.join(',');
invoicedata.action="overdue_payment";
invoicedata.customer_id = customer_id;
invoicedata.select_billid = this.sendid;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="login";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
this.totl_amt = response.total_amount;
          }
        else{

this.totl_amt = 0;

        }

      
      }, 
      (error)=>{
          console.log(error);
      });

}

multislt(val)
{
  this.uncheck = 0;
  console.log(val)

 var adding = false
if(val.add == false){

if(this.deletearr.length != 0){

 for (var i = 0; i < this.deletearr.length; i++) {


if(val.billId !== this.deletearr[i]){

 adding  = true
}

}
  if(adding){
      this.deletearr.push(val.billId)


  }
}
else{
  this.deletearr.push(val.billId)

}

}
else{

   for (var j = 0; j < this.deletearr.length; j++) {

if(val.billId == this.deletearr[j]){

    this.deletearr.splice(this.deletearr.indexOf(this.deletearr[j]), 1 );

}
}
 

}
 
 console.log(this.deletearr)

 this.overduepayment()
}


goToLink(url: string){

    window.open(url, "_blank");


}



invoicepaymentlink(){

if(this.sendid == ''){
  iziToast.error({
          message: "Please select any unpaid invoice",
          position: 'topRight'
      });
  return false;
}
console.log(this.serverService.ci)
  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
invoicedata.action="invoice_payment_link";
invoicedata.customer_id = customer_id;
invoicedata.select_billid =this.sendid;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="payment";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          this.goToLink(response.payment_url)


          }
        else{

    

}

      
      }, 
      (error)=>{
          console.log(error);
      });

}



perfomainvoices(data){
    var list_data3= this.listDataInfo2(data);

console.log(this.serverService.ci)
  let invoicedata:any =new Object();
 let invoicedatasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
invoicedata.action="invoice_list";
invoicedata.customer_id = customer_id;
invoicedata.type = "pi";
invoicedata.limit = list_data3.limit;
invoicedata.offset = list_data3.offset;


 invoicedatasend.access_token=access_token;
  invoicedatasend.operation="curlDatas";
  invoicedatasend.moduleType="login";
  invoicedatasend.api_type="web";
  invoicedatasend.element_data = invoicedata;
      this.serverService.sendServer(invoicedatasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     // this.locations = response.result.data
   this.perfomainvoicedata = response.List

          }
        else{

    this.perfomainvoicedata=[];
        }
   this.offset_count3 = list_data3.offset;
        this.paginationData3 = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit3 });
         console.log(this.paginationData2);
                this.recordNotFound3 = this.perfomainvoicedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}





}
