import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
declare var iziToast:any;
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {
cartdata:any=[];
total_price;
total_tax;
grand_total;
tax_percent;
dataslist:any;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.getcart()
  }
ngAfterViewInit() {
    Feather.replace();
  }
removecart(data){
    // var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="remove_cart";
datas.customer_id = customer_id;
datas.select_chk = data.cart_details_id;



// invoicedata.limit = list_data.limit;
// invoicedata.offset = list_data.offset;
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
    
        iziToast.success({
          message: "Product removed from your cart",
          position: 'topRight'
      });
         this.getcart()
          }
        else{
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
      }

      
      }, 
      (error)=>{
          console.log(error);
      });

}

proceedtobuy(){
  if(this.cartdata.length != 0){
   this.router.navigate(['/shoppingcartdetails']);

  }else{
      iziToast.error({
          message: "Please add any product in your cart",
          position: 'topRight'
      });
  }
}



getcart(){
  Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="getcartlist";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.cartdata = response.options
this.total_price = response.total_price_all
this.total_tax = response.total_tax_value
this.grand_total = response.grand_total_value
this.dataslist = response;
this.tax_percent = response.tax_percent;
localStorage.setItem('xtt', response.tax_percent);


      Swal.close();
          }
        else{
this.cartdata =[];
this.total_tax  ="0.00" 
// this.dataslist ={};
    Swal.close();
        }
//    this.offset_count = list_data.offset;
//         this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
//          console.log(this.paginationData);
//                 this.recordNotFound = this.prepaidnotedata.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}








}
