
<!-- Deal Registration Modal -->
<div class="modal fade dealRegisterModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
aria-hidden="true" id="addNewPopups">
<div class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="myLargeModalLabel">Deal Registration</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<form class="deal-register-form" [formGroup]="information">
				<div class="form-group">
					<label for="partner-name">1. Partner name *</label>
					<!-- <div class="input-group"> -->
						<input type="text" class="form-control" id="partner-name" formControlName="partner_name">
						  <div class="error-message message-small" *ngIf="!information.get('partner_name').valid && information.get('partner_name').touched">
                     Please enter partner name
					</div>
				<!-- </div> -->
			</div>
				<div class="form-group">
					<label for="partner-email">2. Partner Email *</label>
					<!-- <div class="input-group"> -->
						<input type="text" class="form-control" id="partner-email" formControlName="partner_email">
  <div class="error-message message-small" *ngIf="!information.get('partner_name').valid && information.get('partner_name').touched">
                     Please enter partner email
					</div>


					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="phone-number">3. Phone Number *</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="phone-number" formControlName="phone_number">
						 <div class="error-message message-small" *ngIf="!information.get('phone_number').valid && information.get('phone_number').touched">
                     Please enter phone number
					</div>
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="Product">4. Product *</label>
					<!-- <div class="input-group"> -->
						<div class="pretty p-default mr-4">
							<div class="state p-primary">
								<input id="cloud" type="checkbox"  formControlName="prod_cloud1"  value="cloud"/>
								<label for="cloud" class="pl-1 mrg-right">Cloud</label>
								<input id="3cx" type="checkbox" formControlName="prod_cloud2"  value="3cx"/>
								<label for="3cx" class="pl-1 mrg-right">3CX</label>
								<input id="mr_voip" type="checkbox" formControlName="prod_cloud3"  value="mr_voip"/>
								<label for="mr_voip" class="pl-1 mrg-right">Mr. VoIP</label>
							<!-- 	 <div class="error-message message-small" *ngIf="!information.get('phone_number').valid && information.get('phone_number').touched">
                     Please select one product
					</div> -->
							</div>
						</div>
						<!-- <div class="pretty p-default mr-4">
							<div class="state p-primary">
								<input id="3cx" type="checkbox" formControlName="prod_cloud2"/>
								<label for="3cx" class="pl-1">3CX</label>
							</div>
						</div>
						<div class="pretty p-default mr-4">
							<div class="state p-primary">
								<input id="mr_voip" type="checkbox" formControlName="prod_cloud3"/>
								<label for="mr_voip" class="pl-1">Mr. VoIP</label>
							</div>
						</div> -->
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="deal-size">5.Deal Size (Ex: 8sc / 16sc, 500$, 2000$)Required to answer.Single line text. *</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="deal-size"  formControlName="deal_size">
							 <div class="error-message message-small" *ngIf="!information.get('deal_size').valid && information.get('deal_size').touched">
                    Please enter Deal Size
					</div>
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="end-user-name">6.End user Name *</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="end-user-name" formControlName="end_user_name">
							 <div class="error-message message-small" *ngIf="!information.get('end_user_name').valid && information.get('end_user_name').touched">
                   Please enter end user name
					</div>
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="end-user-email">7.End user Email</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="end-user-email" formControlName="end_user_email">
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="end-user-phone">8.End user Phone number</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="end-user-phone" formControlName="end_user_phone_no">
							 <div class="error-message message-small" *ngIf="!information.get('end_user_phone_no').valid && information.get('end_user_phone_no').touched">
                   Please enter end user phone number
					</div>
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="deal-closer">9.Expected deal closer?</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="deal-closer" formControlName="expected_deal_closer">
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="deal-discription">10.Describe about the deal</label>
					<!-- <div class="input-group"> -->
						<input type="text"  class="form-control" id="deal-discription" formControlName="about_deal">
					<!-- </div> -->
				</div>
				<div class="btn-groups">
					<a  class="btn btn-success mr-3" (click)="finishFunction()">Submit</a>
					<a  class="btn btn-info">Cancel</a>
				</div>
			</form>
		</div>
	</div>
</div>
</div>
<!-- Deal Registration Modal End -->