import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.css']
})
export class SupportTicketComponent implements OnInit {
recordNotFound = false;
 pageLimit = 5;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

recordNotFound2 = false;
 pageLimit2 = 10;
 paginationData2:any ={"info":"hide"};
 offset_count2 = 0;


 opentickets:any=[];
 closetickets:any=[];

 files: any = [];
 filesend:any=[];
 createinformation: FormGroup;
 value;
 customerdetails:any={};
 datadetails:any;

  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
    this.countryflag()
  	this.open_ticket({})
    this.closedticket({})
 this.createinformation = new FormGroup({
      'fromticket' : new FormControl(null,
    Validators.required),
      'department' : new FormControl(null,Validators.required),
      'subject' : new FormControl(null,Validators.required),
      'messages' : new FormControl(null)

     });

  	
  }
ngAfterViewInit() {
    Feather.replace();
  }
finishFunction(){
  // console.log(this.createinformation.value)
   if(this.createinformation.valid){
   
     this.createnewticket()
   }
   else {

        this.validateAllFields(this.createinformation); 
        }
}



validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }


 localUrl;
 file: File;
  uploadFiless(event) {
     console.log(event[0])
       this.file = event[0];
 if(event[0] != undefined){
      this.filesend.push(event[0])
      
    }
     
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name);
    }  
console.log(this.filesend)

  }



  uploadFile(event: any) {
    this.file = event.target.files[0];

    console.log(this.file)
    if(this.file != undefined){

     this.filesend.push(this.file)
    }
    var events = event.target.files;
 for (let index = 0; index < events.length; index++) {
      const element = events[index];
      this.files.push(element.name)
    } 

console.log(this.filesend)

    }



  deleteAttachment(index) {
    this.files.splice(index, 1)
  }


gotck(ticket){
var tck = btoa(ticket);
  localStorage.setItem('tck',tck); 
this.router.navigate(['support-ticket-thread']);

}



open_ticket(data){
   Swal.fire('Please wait')
Swal.showLoading()
    var list_data= this.listDataInfo(data);


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="open_ticket";
datas.customer_id = customer_id;
datas.limit = list_data.limit;
datas.offset = list_data.offset;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
 Swal.close();
        // if(response.status=="true"){
     this.opentickets = response.open
   
//           }
//         else{
// this.opentickets =[];
 
//         }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.opentickets.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.open_ticket({'search_text':searchValue})
}



closedticket(data){
    var list_data2= this.listDataInfo2(data);


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="closed_ticket";
datas.customer_id = customer_id;
datas.limit = list_data2.limit;
datas.offset = list_data2.offset;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        // if(response.status=="true"){
     this.closetickets = response.closed
   
          // }
//         else{
// this.closetickets =[];
 
//         }
   this.offset_count2 = list_data2.offset;
        this.paginationData2 = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit2 });
         console.log(this.paginationData);
                this.recordNotFound2 = this.closetickets.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo2(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit2 : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch2(searchValue: string): void {  
  console.log(searchValue);
  this.closedticket({'search_text':searchValue})
}



createticket(){
   this.files= [];
 this.filesend=[];
 this.file =null;
  this.createinformation.patchValue({
  messages:"",
  subject:""
 
});
   $('#addNewPopup').modal('show');

}


countryflag(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="customer_data";
data.customer_id=customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 this.customerdetails = response.customer_data;
 this.datadetails= response;
   this.createinformation.patchValue({

  fromticket: this.customerdetails.customerName, 
  department:"Support_SG"

});
          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}




createnewticket(){

  // consoless.log(atob(this.createinformation.value.messages))
 
 
 Swal.fire('Please wait')
Swal.showLoading()
 let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
  
      const forms = new FormData();

      forms.append('name', this.customerdetails.customerName);
      forms.append('email', this.datadetails.email_id);
      forms.append('dept_id', '5');
      forms.append('ss',this.createinformation.value.subject);
      forms.append('message', this.createinformation.value.messages.replace(/<(?:.|\n)*?>/gm, ' '));
      forms.append('custs_id',  this.datadetails.customer_id);
      forms.append('custs_code',  this.customerdetails.customerCode);
      forms.append('attachment', this.file);
      forms.append('action_info', 'add_ticket');
      forms.append('action', 'create_ticket');
      forms.append('operation', 'curlData');
      forms.append('access_token', access_token);
      forms.append('moduleType', 'login');
      forms.append('api_type', 'web');

      console.log(forms);
      var self = this;
      $.ajax({
        url: "https://erp.cal4care.com/cms/api_cms/v1.0/index_new.php",
        type: 'POST',
        data: forms,
        processData: false,  // tell jQuery not to process the data
        contentType: false,
        success: function (data) {
console.log(data)

     
        }
      });



 setTimeout (() => {
   
    this.open_ticket({})
    this.closedticket({}) 
  $('#addNewPopup').modal('hide');
 Swal.close();
 iziToast.success({
          message: "Ticket Created Sucessfully",
          position: 'topRight'
      });
         console.log("Hello from setTimeout");
      }, 3000);
     
  }

}


