import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
declare var $:any;
@Component({
  selector: 'app-cms-user',
  templateUrl: './cms-user.component.html',
  styleUrls: ['./cms-user.component.css']
})
export class CmsUserComponent implements OnInit {
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 users:any=[];
 public href: string = "";
 informationext: FormGroup;
 cms_user_id;
 customer_email;
 type;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {

if(this.serverService.users == 0){
  
 this.router.navigate(['/dashobard']);
}
  	this.userlist({})
      this.href = this.router.url;
        console.log(this.router.url);

 this.informationext = new FormGroup({
   
      'ext_no' : new FormControl(null),
      'ext_pwd' : new FormControl(null),
      'ext_username' : new FormControl(null)
      
     });



  }
ngAfterViewInit() {
    Feather.replace();
  }

  addnewuser(){
this.router.navigate(['cmsusernew'], { state: {page:"add"} });
    

  }




  edituser(userid){
this.router.navigate(['cmsusernew'], { state: {user_id:userid,page:"edit"} });

  }
userlist(data){
   Swal.fire('Loading')
Swal.showLoading()
    var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="cms_user";
datas.customer_id = customer_id;
// invoicedata.limit = list_data.limit;
// invoicedata.offset = list_data.offset;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
     this.users =response.Emaillist;
     if(response.Userlist != null){
   this.users = this.users.concat(response.Userlist);
       
     }
     // this.users.push(response.Userlist)
// for (var i = 0; i <  this.users.length; i++) {
// this.users[i].cus_array = this.users[i].cus_permission.split(',');

// }

// for (var i = 0; i <  this.users.length; i++) {
// for (var j = 0; j <  this.users[i].cus_permission.length; j++) {
//   if(this.users[i].cus_permission[j] == "Invoice" ){
// this.users[i].cus_permission[j].push(
// {
//  this.users[i].cus_permission[j].color:#fff,
//     // background-color: deepskyblue
// }
//   )
//   }
//   if(this.users[i].cus_array[j] == "Credit Note" ){
    
//   }

// }

// }

  console.log(this.users)


          }
        else{
          Swal.close()

this.users =[];
 
        }
   // this.offset_count = list_data.offset;
   //      this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
   //       console.log(this.paginationData);
   //              this.recordNotFound = this.users.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.userlist({'search_text':searchValue})
}


deleteaction(val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')
 if(val.Userid){
   s.cms_userdelete(val.Userid)
 }
else{
  s.customer_email_delete(val.Username)
}

        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}


// deleteaction(val){
//   if(val.Userid){
// this.cms_userdelete(val.Userid)

//   }
// else{
// this.customer_email_delete(val.Username)
// }
// }


cms_userdelete(userid){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="cms_userdelete";
datas.customer_id = customer_id;
datas.cms_user_id = userid;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
         iziToast.success({
          message: "Deleted Successfully",
          position: 'topRight'
      });

    this.userlist({})

          }
        else{
    iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}

resetpassword(val){
   if(val.Userid){
   this.cms_user_id = val.Userid;
   this.type = "cmsuser";
this.customerpwd_reset(val.Userid)

   }else{
  this.customer_email = val.Username;
   this.type = "customer";
this.customerpwd_reset(val.Username)
   }
}

customerpwd_reset(val){

console.log(val)
console.log(this.type)

  Swal.fire('Please Wait')
Swal.showLoading()

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
//    if(this.type == "cmsuser") {
// datas.action="cms_user_password_send_mail";
// datas.cms_user_id = this.cms_user_id;
    
//    }else{
datas.action="cms_user_password_send_mail";

if(this.type == "cmsuser"){

  datas.cms_user_id = this.cms_user_id ;
datas.table_type = "2" ;

}
else{

datas.customer_email = this.customer_email ;
datas.table_type = "1" ;

}



datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)

        if(response.status=="true"){

 Swal.close()
  
iziToast.success({
          message: "Reset password sent to your email Successfully",
          position: 'topRight'
      });

          }
        else{
          Swal.close()
 
  iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 
      }, 
      (error)=>{
          console.log(error);
      });

}

closemodals(){

  $('#addNewPopups').modal('hide');
}



updateext(val){
  console.log(val)
  if(val.Userid){
  this.get_ext_details(val.Userid,"cmsuser")

}else{
  this.get_ext_details(val.Username,"customer")

}
}


get_ext_details(val,type){
  console.log(val)
  console.log(type)

   Swal.fire('Loading')
Swal.showLoading()
if(type == "cmsuser"){
this.cms_user_id = val;
this.type = type;

}else{
  this.customer_email = val;
   this.type = type;

}


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');

   if(this.type == "cmsuser") {
 datas.cms_user_id = this.cms_user_id ;
datas.action="cms_user_ext_details";
}else{
 datas.action="customer_user_ext_details";
datas.customer_email = this.customer_email; 
}


datas.customer_id = customer_id;
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
  
 this.informationext.patchValue({
  'ext_no' :response.ext_no,
  'ext_pwd' :response.ext_pwd,
  'ext_username' : response.ext_username
});
   $('#addNewPopups').modal('show');

          }
        else{
          Swal.close()
iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 

 
        }
 
      }, 
      (error)=>{
          console.log(error);
      });

}

cms_user_ext_details_update(){
   Swal.fire('Please Wait')
Swal.showLoading()

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   if(this.type == "cmsuser") {
datas.action="cms_user_ext_details_update";
datas.cms_user_id = this.cms_user_id;
    
   }else{
datas.action="customer_user_ext_details_update";
datas.customer_email = this.customer_email ;
   }


datas.customer_id = customer_id;
datas.ext_no = this.informationext.value.ext_no;
datas.ext_username = this.informationext.value.ext_username;
datas.ext_pwd = this.informationext.value.ext_pwd;




 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
  
iziToast.success({
          message: "Extension Details Updated Successfully",
          position: 'topRight'
      });
this.closemodals()
          }
        else{
          Swal.close()
iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
this.closemodals()
 

 
        }
 
      }, 
      (error)=>{
          console.log(error);
      });

}

welcome_email(l){
   Swal.fire('Please Wait')
Swal.showLoading()

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   // if(this.type == "cmsuser") {
// datas.action="cms_user_ext_details_update";
// datas.cms_user_id = this.cms_user_id;
    
   // }else{
datas.action="get_cms_authendication_details";
datas.cms_user_id = l.Userid;
datas.template_id = "18";

   // }


datas.customer_id = customer_id;
// datas.ext_no = this.informationext.value.ext_no;
// datas.ext_username = this.informationext.value.ext_username;
// datas.ext_pwd = this.informationext.value.ext_pwd;




 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
//           Swal.close()
  
// iziToast.success({
//           message: "Extension Details Updated Successfully",
//           position: 'topRight'
//       });
// this.closemodals()
//           }
//         else{
//           Swal.close()
// iziToast.error({
//           message: "Please try again later",
//           position: 'topRight'
//       });
// this.closemodals()
 

 
        }
 
      }, 
      (error)=>{
          console.log(error);
      });

}

customer_email_delete(val){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="customer_master_email_delete";
datas.customer_id = customer_id;
datas.customer_email = val;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
         iziToast.success({
          message: "Deleted Successfully",
          position: 'topRight'
      });

    this.userlist({})

          }
        else{
    iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}






}
