<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">CMS User</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">CMS User Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>CMS User Details</h4>
<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
        <div class="form-group row mb-4">
                  <div class="col-sm-12 col-md-7">
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-primary" (click)="addnewuser()"><i class="fas fa-plus-circle"></i>Add New User</button>
                  </div>
               </div>






<div class="table-responsive">
<table class="table table-striped" >
  <!-- id="tableExport" -->
<thead>
<tr>
<th>Username</th>
<th>Phone No</th>
<th>Permissions</th>
<th>Action</th>
</tr>
</thead>
<tbody>


<tr  *ngFor="let l of users;let i = index;" style="border-bottom: 1px solid chartreuse;">
<td>{{l.Username}}</td>
<td>{{l.Phonenumber}}</td>
<td  ><span *ngFor="let c of l.cus_permission" style="margin-right: 5px !important">
  <div class="badge badge-default badge-shadow" style="margin-bottom: 7px;margin-top: 7px" [ngStyle]="{'color':c.color, 'background-color':c.backgroundcolor}">&nbsp;{{c.name}}&nbsp;</div>
</span></td>

<td class="action-btn-group">

<a  *ngIf="l.Userid" (click)="edituser(l.Userid)" data-toggle="tooltip" title="Edit" class="btn btn-primary">
<i class="far fa-edit"></i>
</a>

<a  *ngIf="i != 0" (click)="deleteaction(l)" data-toggle="tooltip" title="Delete" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>


<!-- <a (click)="updateext(l)" data-toggle="tooltip" title="Update Ext Details" class="btn btn-info">
<i class="fas fa-cog"></i>
</a> -->


<a (click)="resetpassword(l)" data-toggle="tooltip" title="Password Reset" class="btn btn-warning">
<i class="fab fa-gg"></i>
</a>

<a *ngIf="l.Userid" (click)="welcome_email(l)"  data-toggle="tooltip" title="Welcome Email" class="btn btn-default ping-bg-clr">
<i class="fas fa-envelope-open-text" style="color: #fff"></i>
</a>
<!-- <a  class="btn btn-default ping-bg-clr mr-3 alignwelcomebtn" >Welcome Email</a> -->

</td>
<!-- <td class="btn-groups">

  </td> -->
</tr>

<!-- <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>
<div class="card-footer text-right" *ngIf="recordNotFound == false">
	
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? licenselist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="licenselist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? licenselist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="licenselist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? licenselist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
 -->


</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>


<div class="modal fade dealRegisterModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
aria-hidden="true" id="addNewPopups">
<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="myLargeModalLabel">Ext Details Update</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="deal-register-form" [formGroup]="informationext">
        <div class="form-group">
          <label for="partner-name">Ext Number</label>
       
            <input type="text" class="form-control" id="partner-name" formControlName="ext_no">
             <!--  <div class="error-message message-small" *ngIf="!information.get('partner_name').valid && information.get('partner_name').touched">
                     Please enter partner name
          </div> -->
  
      </div>
        <div class="form-group">
          <label for="partner-email">Ext Username</label>
       
            <input type="text" class="form-control" id="partner-email" formControlName="ext_username">
<!--   <div class="error-message message-small" *ngIf="!information.get('partner_name').valid && information.get('partner_name').touched">
                     Please enter partner email
          </div> -->


  
        </div>
        <div class="form-group">
          <label for="phone-number">Ext Password</label>
       
            <input type="text"  class="form-control" id="phone-number" formControlName="ext_pwd">
           <!--   <div class="error-message message-small" *ngIf="!information.get('phone_number').valid && information.get('phone_number').touched">
                     Please enter phone number
          </div> -->
  
        </div>
 


     
        <div class="btn-groups">
          <a  class="btn btn-success mr-3" (click)="cms_user_ext_details_update()">Save</a>
          <a  class="btn btn-info" (click)="closemodals()">Cancel</a>
        </div>
      </form>
    </div>
  </div>
</div>
</div>