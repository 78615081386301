<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">My Profile</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">My Profile Update</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12 col-lg-12">
<div class="card">
<div class="card-header">
<h4>My Profile Update</h4>
</div>
<div class="card-body">

<form  [formGroup]="getdata">


<div class="row">
<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="company-name">Company Name *</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-users"></i>
	</div>
</div>
<input type="text" class="form-control" id="company-name"  formControlName="company_name" readonly>
</div>
</div>
</div>
<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="phone-number">Phone No *</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-phone"></i>
	</div>
</div>
<input type="text" class="form-control" id="phone-number" formControlName="phone_no">
</div>
</div>
</div>
<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="googleAuthenticator">Google authenticator *&nbsp;&nbsp; <button class="btn fr btn-sm btn-info">Generate Code</button></label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-lock"></i>
	</div>
</div>
<input type="text" id="googleAuthenticator" class="form-control pwstrength" data-indicator="googleAuthenticator" formControlName="google_Authenticator">
</div>
<div id="googleAuthenticator" class="googleAuthenticator">
<div class="bar"></div>
<div class="label"></div>
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="address-1">Address 1 *</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-building"></i>
	</div>
</div>
<input type="text" class="form-control" id="address-1" formControlName="address1">
<br> 
 <div class="error-message message-small" style="padding: 7px 2px !important" *ngIf="!getdata.get('address1').valid && getdata.get('address1').touched">
                     Please enter Address1
					</div>
</div>

</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="address-2">Address 2</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-building"></i>
	</div>
</div>
<input type="text" class="form-control" id="address-2" formControlName="address2">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="city">City</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-university"></i>
	</div>
</div>
<input type="text" class="form-control" id="city" formControlName="city">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="state">State</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-university"></i>
	</div>
</div>
<input type="text" class="form-control" id="state" formControlName="state">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="country">Country</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-globe"></i>
	</div>
</div>
<input type="text" class="form-control" id="country" formControlName="country">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="phone-2">Phone</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-phone"></i>
	</div>
</div>
<input type="text" class="form-control" id="phone-2" formControlName="phone_no2">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="mobile-phone">Mobile Phone</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-mobile-alt"></i>
	</div>
</div>
<input type="text" class="form-control" id="mobile-phone" formControlName="mobilePhone">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="fax">Fax</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-fax"></i>
	</div>
</div>
<input type="text" class="form-control" id="fax" formControlName="fax">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="contact-person">Contact Person</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-user"></i>
	</div>
</div>
<input type="text" class="form-control" id="contact-person" formControlName="contact_person">
</div>
</div>
</div>

<!-- <div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="bankAccountName">Bank Account Name</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-piggy-bank"></i>
	</div>
</div>
<input type="text" class="form-control" id="bankAccountName" formControlName="bank_account_name">
</div>
</div>
</div> -->

<!-- <div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="bankAccountNumber">Bank Account Number</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-money-check-alt"></i>
	</div>
</div>
<input type="text" class="form-control" id="bankAccountNumber" formControlName="bank_account_no">
</div>
</div>
</div> -->

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="resetPassoword">Reset Passoword</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-lock"></i>
	</div>
</div>
<input type="text" class="form-control" id="resetPassoword" formControlName="reset_password">
</div>
</div>
</div>

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="credit-limit">Credit Limit</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-money-bill-alt"></i>
	</div>
</div>
<input type="text" class="form-control" id="credit-limit" formControlName="credit_limit" readonly>
</div>
</div>
</div>

<!-- <div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="bill-code">Bill Code</label>
<div class="input-group">
<div class="input-group-prepend">
	<div class="input-group-text">
		<i class="fas fa-file-invoice-dollar"></i>
	</div>
</div>
<input type="text" class="form-control" id="bill-code" formControlName="bill_code">
</div>
</div>
</div>
 -->
<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<label for="resetPassoword">Customer Status</label>
<div class="badge badge-success">{{profile_details.customer_status}}</div>
</div>
</div>

<!-- <div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<div class="pretty p-switch p-fill">
<input type="checkbox" id="pbx-username" formControlName="cms_username_dispaly" />
<div class="state p-success">
	<label for="pbx-username"><b>Show PBX User Name</b></label>
</div>
</div>
</div>
</div> -->

<!-- <div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<div class="pretty p-switch p-fill">
<input type="checkbox" id="Show-vs-pbx"  formControlName="cms_vs_pbx_dispaly" />
<div class="state p-success">
	<label for="Show-vs-pbx"><b class="fl">Show VS PBX</b></label>
</div>
</div>
</div>
</div> -->

<div class="col-12 col-md-6 col-lg-4">
<div class="form-group">
<div class="pretty p-switch p-fill">
<div class="state p-success">
	<label for="Show-vs-pbx"><b>QR Code *</b></label>
</div>
</div>
<qr-code [value]="qrdata" [size]="width" [level]="level" style="display: flex;justify-content: center;"></qr-code>
<!-- <img src="https://dev.cal4care.com/erp/images/profile_image/5f858120c1c60.png"> -->
</div>
</div>



                 <div class="col-12 col-md-6 col-lg-4">
                    <div class="registerdata form-group">
                      <div class="form-line">
                        <label class="form-label">Upload Photo</label>
                        <div class="row">
                         <div class="col-md-12">
                           
<input required type="file" name="uploaded-img" id="uploaded-img" (change)="showPreviewImage($event)" style="padding-bottom: 10px !important;">


                         </div>
                          <div class="col-md-1">
                          </div>
                         <div class="col-md-10">
                        
 <img src="{{localUrl}}"  onerror="this.src='assets/img/placeholder-img.png'"  class="img-thumbnail imguploadthumb">

                          
                        </div>
                        <div class="col-md-1">
                          </div>
                      </div>
                    </div>
                  </div>
                </div> 













<div class="col-12 col-lg-12 mt-2 mb-3">
<div class="submit-btn-group fr">
<button class="btn btn-info">Cancel</button>
<button class="btn btn-warning" (click)="finishFunction()">Update Changes</button>
</div>
</div>

</div>

</form>

</div>
</div>

</div>
</div>
</div>


</section>