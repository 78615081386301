import { Component, OnInit } from '@angular/core';
import * as Feather from 'feather-icons';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
declare var iziToast:any;
import Swal from 'sweetalert2'
declare var $:any;
@Component({
  selector: 'app-support-ticket-thread',
  templateUrl: './support-ticket-thread.component.html',
  styleUrls: ['./support-ticket-thread.component.css']
})
export class SupportTicketThreadComponent implements OnInit {
datalist:any;
replaymessage:any;
files: any = [];
value;
conversation_show = false;
filesend:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
  	this.ticket_count()
  }
ngAfterViewInit() {
    Feather.replace();
  }

showmore(show){
console.log(show)
if(show == '0'){
   this.conversation_show = false;
for (var i = 0; i < this.datalist.ticket.thread_entries.length; i++) {
  if(i <=4){
    this.datalist.ticket.thread_entries[i].show = false;
  }else{
     this.datalist.ticket.thread_entries[i].show = true;
  }
}
}else{
  this.conversation_show = true;
  for (var i = 0; i < this.datalist.ticket.thread_entries.length; i++) {

    this.datalist.ticket.thread_entries[i].show = false;
  
  }
}

}



replyOldticket(){
  Swal.fire('Please wait')
Swal.showLoading()
       let access_token: any=localStorage.getItem('at');
       let customer_id: any=localStorage.getItem('en');
         const forms = new FormData();
         forms.append('name', this.datalist.ticket.user_name);
         forms.append('email', this.datalist.ticket.user_email.address);
         forms.append('ticket_number', this.datalist.ticket.ticket_number);
         forms.append('message', this.replaymessage.replace(/<(?:.|\n)*?>/gm, ' '));
         forms.append('attachment', this.file);
         forms.append('action_info', 'reply_ticket');
         forms.append('action', 'reply_ticket');
         forms.append('operation', 'ticket');
         forms.append('access_token', access_token);
         forms.append('moduleType', 'ticket');
         forms.append('api_type', 'web');
         console.log(forms);
         var self = this;
         $.ajax({
           url: "https://erp.cal4care.com/cms/api_cms/v1.0/index_new.php",
           type: 'POST',
           data: forms,
           processData: false,  // tell jQuery not to process the data
           contentType: false,
           success: function (data) {
          
           }
         });

 setTimeout (() => {
       Swal.close();
  iziToast.success({
                    message: "Ticket Replied Successfully",
                    position: 'topRight'
                });
  this.files= [];
 this.filesend=[];
 this.file =null;
 this.replaymessage="";
    this.ticket_count()
      }, 3000);

         // console.log(data)
            
  }

// ticketthreadtimeout(){
//   setTimeout (() => {
//        // Swal.close();
//   // iziToast.success({
//   //                   message: "Ticket Replied Successfully",
//   //                   position: 'topRight'
//   //               });
//       }, 3000);

// }



ticket_count(){
Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
    let tck_id: any=  atob(localStorage.getItem('tck'));

   
datas.action="getTicketInfo";
// datas.customer_id = customer_id;

datas.ticket_id = tck_id;

 datasend.access_token=access_token;
  datasend.operation="ticket";
  datasend.moduleType="ticket";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response && response.result.status == true){
          Swal.close();


if(response.result.data != null){
 this.datalist = response.result.data;

for (var i = 0; i < this.datalist.ticket.thread_entries.length; i++) {
  if(i <= 4){
    this.datalist.ticket.thread_entries[i].show = false;
  }else{
     this.datalist.ticket.thread_entries[i].show = true;
  }
}

console.log(this.datalist.ticket.thread_entries) 
}else{
  this.datalist ="";
}

     // this.counts = response.counts
       
          }
        else{
              Swal.close();
// this.counts={}
 
        }

      }, 
      (error)=>{
          console.log(error);
      });

}
 // filed: File;
 //  uploadFile(event:any) {
 //    this.filed = event.target.files[0];
 //    // console.log(event.target.files[0])
 //    for (let index = 0; index < event.length; index++) {
 //      const element = event[index];
 //      this.files.push(element.names)
 //    }  
 //  }
 localUrl;
 file: File;
  uploadFiless(event) {
     console.log(event[0])
       this.file = event[0];
 if(event[0] != undefined){
      this.filesend.push(event[0])
      
    }
     
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name);
    }  
console.log(this.filesend)

  }



  uploadFile(event: any) {
    this.file = event.target.files[0];

    console.log(this.file)
    if(this.file != undefined){

     this.filesend.push(this.file)
    }
    var events = event.target.files;
 for (let index = 0; index < events.length; index++) {
      const element = events[index];
      this.files.push(element.name)
    } 

console.log(this.filesend)

    }

 
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }



}
