<!-- Main Content -->
<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Dashboard</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Dashboard</li>
<!-- <h3 class="page-title m-b-0 alignphp"></h3> -->
<!-- <a href="https://erp.cal4care.com/cms/index.php" target="_blank" class="btn btn-default btn-icon ping-bg-clr icon-left alignphp">
<i class="fab fa-superpowers"></i>Switch Old CMS
</a> -->
</ul>
<!-- <ul>
	<li>
		
	</li>
	</ul> -->
<div class="section-body">
<div class="row no-gutters header-card mb-4">

	<!--Referral  -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" (click)="check()"  *ngIf="serverService.invoice == 1">
<div class="card clr-card yellow-bg-clr" >
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/referral-icon.svg" alt="Referral">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Referral</h6>
<h4 class="text-left"><span></span>
</h4>
</div>
</div>
</div>
</div>

<!-- Invoice -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf="serverService.invoice == 1 && dashcount.invoice_cnt != '0.00'">
<div class="card clr-card ping-bg-clr" routerLink="/invoice">
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/invoice-icon.svg" alt="Invoice">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Invoice</h6>
<h4 class="text-left" *ngIf="dashcount.invoice_cnt != '0.00'"><span >{{dashcount.invoice_cnt}}</span>
</h4>
<h4 class="text-left" *ngIf="dashcount.invoice_cnt == '0.00'"><span >0</span>
</h4>
</div>
</div>
</div>
</div> 


<!-- Call Balance -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12"   *ngIf="serverService.callbalance == 1">
<div class="card clr-card dark-green-bg-clr" routerLink="/call-history">
<div class="card-statistic">
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Call Balance</h6>
<div *ngIf="(callbalances | json) != '{}'">
<span class="fl red-clr dashboard-highlights text-left"><b><span class="vio-clr">{{callbalances.server_name1}} :</span> {{callbalances.main_balance_pbx1}} / {{callbalances.last_credit1}}</b></span>
<span class="fl red-clr dashboard-highlights text-left"><b><span class="vio-clr">{{callbalances.server_name2}} :</span> {{callbalances.main_balance_pbx2}}  / {{callbalances.last_credit2}}</b></span>
</div>
</div>
</div>
</div>
</div>


<!-- Licence -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf="serverService.license == 1 && dashcount.license_detail_cnt != '0'">
<div class="card clr-card vio-clr-bg"  routerLink="/license">
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/license.svg" alt="Licence">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Licence</h6>
<h4 class="text-left"><span class="liclr">{{dashcount.license_detail_cnt}}</span>
</h4>
</div>
</div>
</div>
</div>

<!-- Partner Id -->
 <div class="col-xl-2 col-lg-4 col-sm-6 col-12"  *ngIf="serverService.cxbuy == 1 && dashcount.customer_reseller_id != ''">
<div class="card clr-card red-bg-clr" routerLink="/buy3cx">
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/partner-id.svg" alt="Partner Id">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">3CX Partner Id</h6>
<h4 class="text-left"><span class="idclr">{{dashcount.customer_reseller_id}}</span>
</h4>
</div>
</div>   
</div>
</div> 

<!-- 3cx Discount -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf="serverService.cxbuy == 1 && dashcount.discount_percentage != 0">
<div class="card clr-card light-green-bg-clr"  routerLink="/buy3cx">
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/3cx-discount.svg" alt="3cx Discount">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">3CX Discount</h6>
<h4 class="text-left"><span class="cxclr">{{dashcount.discount_percentage}}%</span>
</h4>
</div>
</div>
</div>
</div>


<!-- Terms -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf="serverService.cxbuy == 1">
<div class="card clr-card blue-bg-clr" routerLink="/buy3cx">
<div class="card-statistic">
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Terms</h6>
<span class="fl red-clr dashboard-highlights text-left"><b><span class="vio-clr">Allocated : </span>{{dashcount.customer_terms_days}} / {{dashcount.customer_credit_amt}}</b></span><br />
<span class="fl red-clr dashboard-highlights text-left"><b><span class="vio-clr">Balance : </span>{{dashcount.customer_available_terms}} Days / {{dashcount.customer_available_credit}} </b></span>
</div>
</div>
</div>
</div>

<!-- Support Ticket -->
<div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf='counts.open != "0"'>
<div class="card clr-card liblue" routerLink="/support-ticket-dashboard">
<div class="card-statistic">
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Support Ticket</h6>
<h4 class="text-left"><span class="tktclr">{{counts.open}}</span>
</h4>

</div>
</div>
</div>
</div>



</div>




<!-- Projects -->
<!-- <div class="col-xl-2 col-lg-4 col-sm-6 col-12" *ngIf="serverService.projects == 1">
<div class="card clr-card darkblu-bg-clr">
<div class="card-statistic">
<div class="banner-img">
<img src="assets/img/custom-images/dashboard/projects.svg" alt="Licence">
</div>
<div class="card-header-details fl">
<h6 class="m-b-5 text-left">Projects</h6>
<h4 class="text-left"><span>{{dashcount.license_detail_cnt}}</span>
</h4>
</div>
</div>
</div>
</div> -->




<!-- Referral -->
<div class="row">







<div class="col-md-12 col-lg-12 col-xl-12" *ngIf='reseller_state == "1"'>
<div class="card card-success">
<div class="card-header">
<h4>Referral Amount Details in ({{commision.def_currency_name}})</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#referralAmount-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="referralAmount-collapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >
<table class="table table-hover grad-table green-grad">
<thead>
<tr class="tablebkgr">
<th *ngFor="let day of commissiondata;let i =index;" style="border-right: 1px solid #f5f5f5">{{day.date_year}} <br />{{day.date_month_name}}</th>
</tr>
</thead>
<tbody>
<tr >
<td *ngFor="let value of commissiondata;let c =index;"  style="border-right: 1px solid #f5f5f5"><b>{{value.referral_amt | number : '1.2-2'}}</b></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="col-md-12 col-lg-12 col-xl-12" *ngIf='reseller_state == "1"'>
<div class="card card-purple">
<div class="card-header">
<h4>Payout Details in ({{commision.def_currency_name}}) - Unpaid Amount-{{commision.unpaid_cus_amt}}</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-danger" href="#payoutDetails-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="payoutDetails-collapse">
<div class="card-body">
<div class="tableBody">
<div class="table-responsive scrollhide">
<table class="table table-hover grad-table red-grad">
<thead>
<tr class="tablebkred">
<th *ngFor="let day of commissiondata;let i =index;" style="border-right: 1px solid #f5f5f5">{{day.date_year}} <br />{{day.date_month_name}}</th>
</tr>
</thead>
<tbody>
<tr>
<td *ngFor="let value of commissiondata;let c =index;"  style="border-right: 1px solid #f5f5f5"><b>{{value.payout_amt | number : '1.2-2'}}</b></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>


<div class="col-md-6 col-lg-6 col-xl-6" *ngIf='reseller_state == "1" && commission_total_details.length != "0" '>
<div class="card card-success">
<div class="card-header">
<h4>Reseller Dashboard</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#def_Currcollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="def_Currcollapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >
<table class="table table-hover grad-table ">
  <!-- -->
<thead>
<tr class="tablebkgr" >
<th style="border: 1px solid #fff;background: #76d777">Currency</th>
<th style="border: 1px solid #fff;background: #76d777">Paid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Unpaid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Def_Curr({{commision.def_currency_name}})</th>

</tr>
</thead>
<tbody>
<tr *ngFor="let value of commission_total_details;let c =index;">
<td   style="border: 1px solid #fff" [style.background]="value.background" >{{value.currency_name}}</td>
<td   style="border: 1px solid #fff;background:#8bdb9f6b;">{{value.paid_amt}}</td>
<td   style="border: 1px solid #fff;background:#ff97917d;">{{value.unpaid_amt}}</td>
<td   style="border: 1px solid #fff;background:#ff97917d;">{{value.total_val}}</td>

</tr>
</tbody>
</table>






</div>
</div>
</div>
</div>
</div>
</div>




<div class="col-md-6 col-lg-6 col-xl-6" *ngIf='reseller_state == "1" && year_amount.length != "0" '>
<div class="card card-success">
<div class="card-header">
<h4>Year</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-success" href="#yearcollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="yearcollapse">
<div class="card-body">
<div class="tableBody" id="client-details">
<div class="table-responsive scrollhide" >



		<table class="table table-hover grad-table ">

<thead>
<tr class="tablebkgr" >
<th style="border: 1px solid #fff;background: #76d777">Year</th>
<th style="border: 1px solid #fff;background: #76d777">Paid</th>
<th style="border: 1px solid #fff;background: #ff7b73">Unpaid</th>


</tr>
</thead>
<tbody>
<tr *ngFor="let value of year_amount;let c =index;">
<td   style="border: 1px solid #fff;" [style.background]="value.background" >{{value.year}}</td>
<td   style="border: 1px solid #fff;background:#8bdb9f6b;">{{value.paid_amt}}</td>
<td   style="border: 1px solid #fff;background:#ff97917d;">{{value.unpaid_amt}}</td>

</tr>
</tbody>
</table>




</div>
</div>
</div>
</div>
</div>
</div>













<div class="col-12 col-sm-12 col-lg-12" *ngIf='reseller_state == "1"'>
<div class="card card-primary">
<div class="card-header">
<h4>Bank Details</h4>
<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#bankDetails-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="bankDetails-collapse">
<div class="card-body">
<div class="page-content custom-tab note-has-grid">
<ul class="nav nav-pills p-3 mb-3 rounded-pill align-items-center">
<li class="nav-item">
<a   [ngClass]=" {'activetab':selectbank == 'loc'}" (click)="selctedbnk('loc')"
class="tabStartClick nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-local-bank"> <i class="fas fa-piggy-bank"></i><span class="d-md-block">Local Bank</span></a>
</li>
<li class="nav-item" >
<a  [ngClass]=" {'activetab':selectbank == 'inter'}" (click)="selctedbnk('inter')"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-international-bank"> <i class="fas fa-dollar-sign"></i><span class="d-md-block">International</span></a>
</li>
<li class="nav-item" >
<a  [ngClass]=" {'activetab':selectbank == 'paypal'}" (click)="selctedbnk('paypal')"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 pointer"
id="note-paypal"> <i class="fab fa-paypal"></i><span class="d-md-block">Paypal</span></a>
</li>
<!-- <li class="nav-item ml-auto">
<a href="javascript:;" class="btn btn-success btn-icon icon-left rounded-pill" id="add-notes"><i
class="fas fa-plus"></i> Add Bank Details</a>
</li> -->
</ul>
<div class="tab-content bg-transparent">
<div id="note-full-container" class="note-has-grid row">

<!---------------------
Bank Detail Item
---------------------->
<div class="col-md-12 single-note-item note-local-bank" *ngIf="selectbank == 'loc'">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Local Bank</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Bank Name</th>
<th>Bank Code</th>
<th>Branch Code</th>
<th>Account Number</th>
<th>Name</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>
<tr *ngFor="let l of locals;let i = index;">
<td>{{i+1}}</td>
<td>{{l.loc_bank_name}}</td>
<td>{{l.loc_bank_code}}</td>
<td>{{l.loc_bank_branch_code}}</td>
<td>{{l.loc_acc_number}}</td>
<td>{{l.loc_payee_name}}</td>
<td class="action-btn-group">
<!-- <a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a> -->
<!-- <a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a> -->
</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Bank Detail Item End
---------------------->
<!---------------------
Bank Detail Item
---------------------->
<div class="col-md-12 single-note-item note-international-bank" *ngIf="selectbank == 'inter'"> 
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>International</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table primary-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Shift Code</th>
<th>Bank Name</th>
<th>Bank Address</th>
<th>Account Number</th>
<th>Name</th>
<th>Address</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>9042343</td>
<td>HDFC Bank</td>
<td>Aljunied Street</td>
<td>28520022020</td>
<td>TwoaPlus</td>
<td>Novelty Techpoint, Singapore 536212</td>
<!-- <td class="action-btn-group">
<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td> -->
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Bank Detail Item End
---------------------->
<!---------------------
Bank Detail Item
---------------------->
<div class="col-md-12 single-note-item note-paypal"  *ngIf="selectbank == 'paypal'">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Paypal</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table ping-color-table color-table table-striped table-hover">
<thead>
<tr>
<th>S.No</th>
<th>Paypal Email</th>
<!-- <th>Action</th> -->
</tr>
</thead>
<tbody>
<tr *ngFor="let l of paypal;let i = index;">
<td>{{i+1}}</td>
<td>{{l.paypal_email}}</td>
<!-- <td class="action-btn-group"> -->
<!-- <a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a> -->
<!-- <a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a> -->
<!-- </td> -->
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Bank Detail Item End
---------------------->

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="row" >
<div class="col-12 col-sm-12 col-lg-12">
<div class="card card-warning">
<div class="card-header">
<h4>News / Events</h4>
<div class="card-header-action">
 <a data-toggle="collapse"  class="btn btn-icon btn-info" href="#newsEvents-collapse"><i class="fas fa-minus"></i></a>

<!-- <a for="collapseOne" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne"><i class="fas fa-minus"></i></a> -->

</div>
</div>



    <!-- <div class="collapse in show" id="collapseOne" > -->


<div class="collapse show" id="newsEvents-collapse">
<div class="card-body">

<div class="row" >

<div class="col-12 col-sm-6 col-xl-3" *ngFor="let l of newsevents;let i =index;">
<article class="article">
<div class="article-header">
<div class="article-image"  [style.background]="'url(' + l.service_image_name_str + ')'" [ngStyle]="{'background-repeat' : 'no-repeat', 'background-size' :'contain', 'width' :'100%','height' :'100%'}" >
</div>   
<div class="article-title">
<h2><a href="#">{{l.service_name}}</a></h2>
</div>
</div>
<div class="article-details">
<div class="article-user mb-2">
<img alt="image" src="{{l.profile_image}}" class="newimage">
<div class="article-user-details">
<div class="user-detail-name">
<b class="blue-clr">{{l.firstName}}</b>
</div>
<!-- <div class="text-job">UI/UX Developer</div> -->
</div>
</div>
<div class="blog-short-description mb-2 descrip">
<p>{{l.service_description}}</p>
</div>
<div class="article-cta mb-2">
<a class="btn btn-primary" (click)="readmore_blogs(l.mrvoip_other_service_id)">Read More</a>
</div>
</div>
</article>
</div>
</div>



<!-- <div class="row">
<div class="col-12 col-sm-12 col-md-12">
<div class="text-center">
<ul class="pagination  mr-auto ml-auto text-center">
<li class="page-item"><a class="page-link" href="javascript:;">Previous</a></li>
<li class="page-item active"><a class="page-link" href="javascript:;">1</a></li>
<li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
<li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
<li class="page-item"><a class="page-link" href="javascript:;">Next</a></li>
</ul>
</div>
</div> 
</div> -->

  <div class="card-footer text-right" *ngIf="recordNotFound == false">
              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData.info == 'show'">
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? newseventslist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="newseventslist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>
                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? newseventslist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="newseventslist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>
                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? newseventslist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>



<div class="full-width-modal" id="full-width-modal">

<div class="modal fade in"  role="dialog" id="eventmodal">
    <div class="modal-dialog">
    
    
      <div class="modal-content">


        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">×</button>
          <h4 class="modal-title"><span id="modal_header_id">News/Evenets</span></h4>
        </div> 
        <div class="modal-body">
       			<span id="modal_content_id"><style>
				 .other-service-single img {
					max-width: 160px;
					overflow: hidden;
					max-height: 105px;
					width: 100%;
					height: 100%;
					transform: scaleX(1);
					transition: all 1s;
					object-fit: contain;
					float: left;
				  }
				 </style>
				</span>

				 <div class="other-service white-bg">
					<div class="">

						<div class="col-md-12 col-xs-12" *ngFor="let l of newseventsmodallist;let i = index;">
				<div class="other-service-single-item">
					<h4><a target="_blank" >{{l.service_name}}</a></h4>
					<div class="other-service-single text-left">
						<figure>
							<img src="{{l.service_image_name_str}}">
						</figure>
						<div class="other-service-description">
							<div [innerHtml]="l.service_description">
							
						</div>
						
					</div>
						<span class="read-more-btn pointer" (click)="readmores(l.mrvoip_other_service_id)">[ Read More ]</span>

					<div style="float:left"> <p><strong>Posted on :</strong>{{l.created_dt}}</p></div>
				</div>
			</div>


		</div>


	</div>
		</div>
      </div>
      
    </div>
  </div>
</div>


</div>
 








