import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReferralComponent } from './referral/referral.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { CreditNoteComponent } from './credit-note/credit-note.component';
import { PrepaidNoteComponent } from './prepaid-note/prepaid-note.component';
import { LicenseComponent } from './license/license.component';
import { LeadsComponent } from './leads/leads.component';
import { ResellerPriceListComponent } from './reseller-price-list/reseller-price-list.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { Buy3cxComponent } from './buy3cx/buy3cx.component';
import { DealRegistrationComponent } from './deal-registration/deal-registration.component';
import { SupportTicketDashboardComponent } from './support-ticket-dashboard/support-ticket-dashboard.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';
import { SupportTicketThreadComponent } from './support-ticket-thread/support-ticket-thread.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { ForgotPwdComponent } from './forgot-pwd/forgot-pwd.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { CmsUserComponent } from './cms-user/cms-user.component';
import { CmsUsernewComponent } from './cms-usernew/cms-usernew.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { NewextensionComponent } from './newextension/newextension.component';
import { CallhistorypbxComponent } from './callhistorypbx/callhistorypbx.component';
import { ReadmoreComponent } from './readmore/readmore.component';
import { ShoppingcartdetailsComponent } from './shoppingcartdetails/shoppingcartdetails.component';
import { RenewalComponent } from './renewal/renewal.component';
import { GccComponent } from './gcc/gcc.component';
import { CallhistoryvspbxComponent } from './callhistoryvspbx/callhistoryvspbx.component';
import { NotificationComponent } from './notification/notification.component';
import { CostcenterComponent } from './costcenter/costcenter.component';
import { CostcentereditComponent } from './costcenteredit/costcenteredit.component';

const routes: Routes = [
{
        path :'', component: DashboardComponent
    },
    {
        path :'dashobard', component: DashboardComponent
    },
    {
        path :'referral', component: ReferralComponent
    },
    {
        path :'invoice', component: InvoiceComponent
    },
     {
        path :'profile-details', component: ProfileDetailsComponent
    },
     {
        path :'credit-note', component: CreditNoteComponent
    },
     {
        path :'prepaid-note', component: PrepaidNoteComponent
    },
     {
        path :'license', component: LicenseComponent
    },
     {
        path :'leads', component: LeadsComponent
    },
    {
        path :'reseller-price-list', component: ResellerPriceListComponent
    },
    {
        path :'call-history', component: CallHistoryComponent
    },
    {
        path :'buy3cx', component: Buy3cxComponent
    },
     {
        path :'deal-registration', component: DealRegistrationComponent
    },
      {
        path :'support-ticket-dashboard', component: SupportTicketDashboardComponent
    },
      {
        path :'support-ticket', component: SupportTicketComponent
    },
      {
        path :'support-ticket-thread', component: SupportTicketThreadComponent
    },
    {
        path :'shopping-cart', component: ShoppingCartComponent
    },
 {
        path :'cmsuser', component: CmsUserComponent
    },
 {
        path :'cmsusernew', component: CmsUsernewComponent
    },
    {
        path :'extensions', component: ExtensionsComponent
    },
  {
        path :'newextension', component: NewextensionComponent
    },  {
        path :'callhistorypbx', component: CallhistorypbxComponent
    },
    {
        path :'login', component: LoginComponent
    },
    {
        path :'forgot-pwd', component: ForgotPwdComponent
    },{
        path :'logout', component: LogoutComponent
    },
    {
        path :'blogdetail', component: ReadmoreComponent
    },
    {
        path :'shoppingcartdetails', component: ShoppingcartdetailsComponent
    },
     {
        path :'renewal', component: RenewalComponent
    },
      {
        path :'gcc', component: GccComponent
    },  
    {
        path :'callhistoryvspbx', component: CallhistoryvspbxComponent
    },
     {
        path :'notification', component: NotificationComponent
    },
      {
        path :'costcenter', component: CostcenterComponent
    },
      {
        path :'costcenteredit', component: CostcentereditComponent
    }
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
