<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Add Cost Center</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Add Cost Center</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Add Cost Center</h4>

<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">





 <div class="min-form-width" style="max-width: 700px;"> 
<!-- <form [formGroup]="getdata"> -->

<div class="form-group">
<label>Group Name</label>
  <input type="text" class="form-control" id="partner-name" >
<!-- <div class="error-message message-small" *ngIf="!getdata.get('resellcustomer').valid && getdata.get('resellcustomer').touched">
Please Select Reseller Customer
</div> -->
</div>







<div class="form-group mb-4">
<label>Username</label>
<div style="display: flex;">
<select multiple class="form-control" style="margin-right: 50px;
    height: 300px;">


<option  *ngFor="let l of username" value="{{l.value}}">{{l.option}}</option>
</select>
<!-- <img src="assets/img/arrows/singlel.svg" alt="">
<img src="assets/img/arrows/singler.svg" alt="">
<img src="assets/img/arrows/doublel.svg" alt="">
<img src="assets/img/arrows/doubler.svg" alt=""> -->



<select multiple class="form-control" style="height: 300px;">


<option  *ngFor="let l of username" value="{{l.value}}">{{l.option}}</option>
</select>
</div>


</div>


<!-- <div class="form-group mb-4">
<label>Username</label>

<div class="error-message message-small" *ngIf="!getdata.get('resellcustomer').valid && getdata.get('resellcustomer').touched">
Please Select Reseller Customer
</div>
</div> -->














<div class="fl mb-4">


<a  (click)="finishFunction()"  class="btn btn-success" >Submit</a>
</div>
<!-- </form> -->
</div>








</div>
</div>



</div>
</div>
</div>
</div>
</section>