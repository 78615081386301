<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Reseller Product Price List</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Price in SGD</li>
</ul>
<div class="section-body">


<div class="row">

<div class="col-12 col-sm-12 col-lg-12">
<div class="card card-primary">
<div class="card-header">
<h4>Reseller Product Price</h4>
<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#bankDetails-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="bankDetails-collapse">
<div class="card-body">

<div class="page-content custom-tab note-has-grid reseller-product-list-tab">
<ul class="nav nav-pills p-3 mb-3 rounded-pill align-items-center">
<li class="nav-item">
<a href="javascript:void(0)"
class="tabStartClick nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2 active"
id="HTekPhones"><span class="d-md-block">H-Tek IP Phones</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="headsets"><span class="d-md-block">Headsets</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="3CXAppliance"><span class="d-md-block">3CX Applicance - 3CX SBC</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="beronetGateways"><span class="d-md-block">Beronet Gateways</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="intercomPA"><span class="d-md-block">Intercoms - PA system</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="faxATA"><span class="d-md-block">FAX ATA -3CX</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="conferencePhone"> 
<span class="d-md-block">CONFERENCE PHONE -3CX WEB MEETING</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="hotelPhones"> 
<span class="d-md-block">Hotel Phones</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="3cxCloudHosting"> 
<span class="d-md-block">3CX CLOUD-Sg - Hosting</span></a>
</li>
<li class="nav-item">
<a href="javascript:void(0)"
class="nav-link rounded-pill note-link d-flex align-items-center px-2 px-md-3 mr-0 mr-md-2"
id="snomIPPhones"> 
<span class="d-md-block">Snom IP Phones</span></a>
</li>

</ul>

<div class="tab-content bg-transparent">
<div id="note-full-container" class="note-has-grid row">

<!---------------------
HTek Phones
---------------------->
<div class="col-md-12 single-note-item HTekPhones">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>H-Tek IP Phones</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>> 1 Qty</th>
<th>> 10 Qty</th>
<th>> 100 Qty</th>
<th>MSRP</th>
<th>> 1 Qty</th>
<th>> 10 Qty</th>
<th>> 100 Qty</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>UC902S</td>
<td>Entry Level IP Phone 2 SIP, POE, 100BT</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/uc902s1771685576-1581390115.jpg"></td>
<td>S$ 70.00</td>
<td>S$ 60.00</td>
<td>S$ 56.00</td>
<td>S$ 46.00</td>
<td>US $ 52.99</td>
<td>US $ 45.42</td>
<td>US $ 42.39</td>
<td>US $ 34.82</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>UC803P</td>
<td>Entry Level IP Phone 2 SIP , POE, Backlit</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/uc-803p-1592380039.jpg"></td>
<td>S$ 70.00</td>
<td>S$ 60.00</td>
<td>S$ 56.00</td>
<td>S$ 46.00</td>
<td>US $ 52.99</td>
<td>US $ 45.42</td>
<td>US $ 42.39</td>
<td>US $ 34.82</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
<div class="table-notes">
<p><b>Warranty :</b> 24 months warranty against manufacturing defects, excluding AC adapters and accessories</p>
</div>
</div>
</div>
</div>
<!---------------------
HTek Phones End
---------------------->
<!---------------------
Headsets
---------------------->
<div class="col-md-12 single-note-item headsets">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Headsets</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table primary-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>MRD-509S</td>
<td>Monaural with QD Plug(Voice tube microphone)</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/509ds-1556215468.jpg"></td>
<td>S$ 75.00</td>
<td>S$ 60.00</td>
<td>US $ 56.78</td>
<td>US $ 45.42</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>MRD-609D</td>
<td>Mairdi Double ear (Binaural) durable nylon-made microphone boom.</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/609d-1556215512.jpg"></td>
<td>S$ 75.00</td>
<td>S$ 60.00</td>
<td>US $ 56.78</td>
<td>US $ 45.42</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Headsets End
---------------------->
<!---------------------
3CX Appliance
---------------------->
<div class="col-md-12 single-note-item 3CXAppliance">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>3CX Appliance - 3CX SBC</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table ping-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>Appliance X3 (VoIP)</td>
<td>P/N: BNTA-X3-VO Intel i3 CPU, 16GB RAM, 250GB SSD, 2x NIC, VOIP only 1TB SATA HDD (for call recording use) Preloaded Debian OS for Rack Mount Bracket.
1 Year Limited Warranty</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/sbc25-1555042938.jpg"></td>
<td>S$ 1100.00</td>
<td>S$ 750.00</td>
<td>US $ 832.70</td>
<td>US $ 567.75</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>SBC-25</td>
<td>Calncall SBC-25 VoIP PBX SBC-25 is a compact and full-featured IP-PBX that comes in a 19 inch 1U rack-mountable chassis.</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/sbcx3-1554994660.jpg"></td>
<td>S$ 3000.00</td>
<td>S$ 2666.00</td>
<td>US $ 2271.00</td>
<td>US $ 2018.16</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p><b>Warranty :</b> 12 months warranty against manufacturing defects, excluding AC adapters and accessories</p>
<p><b>Rebranding :</b> Applicable only to selected model please check via sales@cal4care.com.my</p>
<p><b>Delivery :</b> 4-8 weeks upon order confirmation. Free delivery applies to order of more than RM2,000 in a single invoice</p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
3CX Appliance End
---------------------->
<!---------------------
Beronet Gateways
---------------------->
<div class="col-md-12 single-note-item beronetGateways">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Beronet Gateways</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>1 Port E1/ PRI</td>
<td>BF16001E1BOX 1 PRI E1 Modular Gateway - Expandable with one additional Model</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/1-pri-voip-gateway-1555149148.jpg"></td>
<td>S$ 1980.00</td>
<td>S$ 1600.00</td>
<td>US $ 1498.86</td>
<td>	US $ 1211.20</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>2 Port E1/ PRI</td>
<td>BF16002E1BOX 2 PRI E1 Modular Gateway - Expandable with one additional Model</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/2-pri-voip-gateway-1555149893.jpg"></td>
<td>S$ 2600.00</td>
<td>S$ 2133.00</td>
<td>US $ 1968.20</td>
<td>US $ 1614.68</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p><b>Warranty :</b> months warranty against manufacturing defects, excluding AC adapters and accessories</p>
<p><b>Certification  :</b>  TM certified</p>
<p><b>Delivery :</b> 2 -3  weeks upon order confirmation. Free delivery applies to order of more than RM2,000 in a single invoice, subject to</p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Beronet Gateways End
---------------------->
<!---------------------
Intercom PA System
---------------------->
<div class="col-md-12 single-note-item intercomPA">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Intercoms - PA system</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table primary-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>Cyberdata -Paging Server</td>
<td>011146 SIP Paging Server with Bell Scheduler</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/377ee466bc1af001ed55bc9afa7db8c8_1d1e5786-4b68-44a9-bfbd-4f87d991f8a5_300x-1555367579.jpg"></td>
</tr>
<tr>
<td class="text-center">2</td>
<td>Cyberdata - 011393/011394 SIP Speaker</td>
<td>011393/011394 SIP Speaker</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/c4d052f084967610e67c6bb4b6be71e2_e9100eec-8f8f-4425-b672-e515cd79eae3_300x-1555367853.jpg"></td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p><b>* PRICE QUOTE IN USD</b></p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Intercom PA System End
---------------------->
<!---------------------
Fax ATA 3CX
---------------------->
<div class="col-md-12 single-note-item faxATA">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Intercoms - PA system</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table ping-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>Handytone HT802 (NEW)</td>
<td>Handytone HT802 (NEW)</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/ht802_right_website-1556198662.jpg"></td>
<td>S$ 110.00</td>
<td>S$ 90.00</td>
<td>US $ 83.27</td>
<td>US $ 68.13</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>handytone HT812</td>
<td>2 FXS, 2GigE, NAT Router</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/ht812_top_website-1556214150.jpg"></td>
<td>S$ 120.00</td>
<td>S$ 110.00</td>
<td>US $ 90.84</td>
<td>US $ 83.27</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p><b>3CX Supported FAX ATA</b></p>
<p>* FAX are agressively tested with above ATA and certified by cal4care Labs</p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Fax ATA 3CX End
---------------------->
<!---------------------
Confierence Phone
---------------------->
<div class="col-md-12 single-note-item conferencePhone">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Conference Phone - 3CX Web Meeting</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>GAC2500</td>
<td>Android Enterprise Conference Phone</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/grandstream-1556210727.jpeg"></td>
<td>S$ 744.00</td>
<td>S$ 675.00</td>
<td>US $ 563.21</td>
<td>US $ 510.98</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>GVC3210</td>
<td>	
The GVC3210 is an innovative video conferencing endpoint ideal for small to medium businesses who seek an easy-to-use yet potent video conferencing solution. By supporting top-tier video resolutions up to 4K Ultra HD, the GVC3210 turns any video conference into a powerful, clear, enhancing experience.
"GVC3210 completely tested for 3cx web meeting and certified by cal4care Labs "</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/gvc3210_front_mount_web-1556210838.jpg"></td>
<td>S$ 744.00</td>
<td>S$ 675.00</td>
<td>US $ 563.21</td>
<td>US $ 510.98</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p>* CONFERENCE PHONE ARE TESTED WITH HOTDESKING </p>
<p>* ANDROID CONFERENCE WEBMEETING SUPPORTED TESTED AGRESSIVELY IN CAL4CARE LABS </p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Confierence Phone End
---------------------->
<!---------------------
Confierence Phone
---------------------->
<div class="col-md-12 single-note-item hotelPhones">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>hotel Phones</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table primary-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>Reseller Price</th>
<th>MSRP</th>
<th>Reseller Price</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>H2S</td>
<td>Fanvil Entry-level Hotel SIP phone. Wall-mountable, no display. POE</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/h2s-1556608867.jpg"></td>
<td>S$ 120.00</td>
<td>S$ 100.00</td>
<td>US $ 90.84</td>
<td>US $ 75.70</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>H3</td>
<td>Fanvil H3 Hotel SIP Phone, 1 SIP account, 6 Soft keys programmable</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/h3-1556608874.jpg"></td>
<td>S$ 140.00</td>
<td>S$ 120.00</td>
<td>US $ 105.98</td>
<td>US $ 90.84</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p>* CONFERENCE PHONE ARE TESTED WITH HOTDESKING </p>
<p>* ANDROID CONFERENCE WEBMEETING SUPPORTED TESTED AGRESSIVELY IN CAL4CARE LABS </p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
hotel Phones End
---------------------->
<!---------------------
Snom Phone
---------------------->
<div class="col-md-12 single-note-item snomIPPhones">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>SNOM IP Phones</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th class="text-center">S.No</th>
<th>Product Name</th>
<th>Description</th>
<th>Image</th>
<th>MSRP</th>
<th>> 1 Qty</th>
<th>> 10 Qty</th>
<th>> 100 Qty</th>
<th>MSRP</th>
<th>> 1 Qty</th>
<th>> 10 Qty</th>
<th>> 100 Qty</th>
</tr>
</thead>
<tbody>
<tr>
<td class="text-center">1</td>
<td>SNM_M325-EU</td>
<td>3955 - Snom M325 SingleCell IP DECT Bundle (DE) MSRP Price quoted for minimum 5 units</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/snom_m325-1557743971.jpg"></td>
<td>S$ 260.00</td>
<td>S$ 0.00</td>
<td>S$ 0.00</td>
<td>S$ 0.00</td>
<td>US $ 196.82</td>
<td>US $ 0.00</td>
<td>US $ 0.00</td>
<td>US $ 0.00</td>
</tr>
<tr>
<td class="text-center">2</td>
<td>SNM_M85</td>
<td>4189 - Snom M85 Industrial Handset EU/US MSRP Price quoted for minimum 5 units</td>
<td class="product-img"><img src="https://erp.cal4care.com/cms/images/product_img/snom_m65-1557744163.jpg"></td>
<td>S$ 260.00</td>
<td>S$ 0.00</td>
<td>S$ 0.00</td>
<td>S$ 0.00</td>
<td>US $ 196.82</td>
<td>US $ 0.00</td>
<td>US $ 0.00</td>
<td>US $ 0.00</td>
</tr>
</tbody>
</table>
</div>
</div>
<div class="table-notes">
<p>* CONFERENCE PHONE ARE TESTED WITH HOTDESKING </p>
<p>* ANDROID CONFERENCE WEBMEETING SUPPORTED TESTED AGRESSIVELY IN CAL4CARE LABS </p>
</div>
</div>
</div>
</div>
</div>
<!---------------------
Snom Phones End
---------------------->
<!---------------------
3CX Cloud Hosting
---------------------->
<div class="col-md-12 single-note-item 3cxCloudHosting">
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Standard Edition</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table success-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th>Product Edition</th>
<th>Sim Calls</th>
<th>Max Extension Allowed</th>
<th>Minimum Contract Period</th>
<th>Session Border Device provided on site @ FOC</th>
<th>Setup Charge(SGD)</th>
<th>Monthly Subscription(SGD)</th>
<th></th>
</tr>
</thead>
<tbody>
<tr>
<td>3CX Phone System 16 SC Fully Managed service</td>
<td>16 inclusive of (10 Channel Siptrunk)</td>
<td>40 Extension & 25 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$300</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
<tr>
<td>3CX Phone System 24 SC Fully Managed service</td>
<td>24 inclusive of (15 Channel Siptrunk)</td>
<td>60 Extension & 25 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$510</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
<tr>
<td>3CX Phone System 32 SC Fully Managed service</td>
<td>32 inclusive of (20 Channel Siptrunk)</td>
<td>80 Extension & 25 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$640</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
<div class="note-card">
<div class="note-content">
<div class="card">
<div class="card-header">
<h4>Pro Edition</h4>
</div>
<div class="card-body">
<div class="table-responsive">
<table class="table ping-color-table product-list-table color-table table-striped table-hover">
<thead>
<tr>
<th>Product Edition</th>
<th>Sim Calls</th>
<th>Max Extension Allowed</th>
<th>Minimum Contract Period</th>
<th>Session Border Device provided on site @ FOC</th>
<th>Setup Charge(SGD)</th>
<th>Monthly Subscription(SGD)</th>
<th></th>
</tr>
</thead>
<tbody>
<tr>
<td>3CX Phone System 4 SC+Fully Managed service</td>
<td>4 inclusive of (2 Channel Siptrunk)</td>
<td>10 Extension & 100 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$160</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
<tr>
<td>3CX Phone System 24 SC Fully Managed service</td>
<td>24 inclusive of (15 Channel Siptrunk)</td>
<td>60 Extension & 25 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$510</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
<tr>
<td>3CX Phone System 8 SC+Fully Managed service</td>
<td>8 inclusive of (4 Channel Siptrunk)</td>
<td>20 Extension & 100 Web Conferencing Participants Included</td>
<td><span class="strike">2 year</span> <br> 1 year</td>
<td></td>
<td>0</td>
<td>$260</td>
<td><a href="https://www.calncall.com/feature-comparison/" class="btn btn-success" target="_blank">Compare</a></td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>
</div>
</div>
<!---------------------
3CX Cloud Hosting End
---------------------->
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</section>