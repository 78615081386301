<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Online Ordering</h4>
</li>
<!-- <li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">
Select to order
</li> -->
</ul>
<div class="section-body">
<div class="row">
<div class="col-md-12 col-lg-12 col-xl-12">
<div class="card card-success">
<div class="card-header">
<h4>Select to order</h4>
<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-success" href="#referralAmount-collapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="referralAmount-collapse">
<div class="card-body">


<form [formGroup]="getdata">



<div class="row">
<div class="col-md-4 text-right">
<h6 class="pt-2">I would like to</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<select id="product_sale_type" name="product_sale_type" class="optional form-control" formControlName="category">
<option value="" selected="selected">Please choose category</option>
<option value="new">Purchase a new license</option>
<option value="upgrade">Upgrade an existing license</option>
<option value="maintenance">Maintenance(Annual/Perpetual)</option>
</select>
<div class="error-message message-small" *ngIf="!getdata.get('category').valid && getdata.get('category').touched">
Please choose category
</div>
</div>
</div>
</div>








<div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
<div class="col-md-4 text-right">
<h6 class="pt-2">Edition</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<select id="edition_key" name="edition_key" class="optional form-control"  formControlName="editionkey"  (change)="editionchange()">
<option value="" selected="selected" > Choose edition</option>
<option *ngFor="let l of edition" value="{{l.value}}">{{l.option_value}}</option>
</select>
<div class="error-message message-small" *ngIf="!getdata.get('editionkey').valid && getdata.get('editionkey').touched">
 Please choose edition
</div>
</div>
</div>
</div>


<div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
<div class="col-md-4 text-right">
<h6 class="pt-2">Concurrent License</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<select id="edition_key" name="edition_key" class="optional form-control" formControlName="conc_license" (change)="concurrentlicense_change()">
<option selected="selected" value =""> Choose Concurrent License</option>
<option *ngFor="let l of concur_license" value="{{l.prices}},{{l.discount}},{{l.value}}" >{{l.option_value}}</option>
</select>
<div class="error-message message-small" *ngIf="!getdata.get('conc_license').valid && getdata.get('conc_license').touched">
 Please choose concurrent license
</div>
</div>
</div>
</div>











<div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
<div class="col-md-4 text-right">
<h6 class="pt-2">Quantity</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<input type="text" class="form-control" id="quantity" name="quantity" value="1" formControlName="qty">
<div class="error-message message-small" *ngIf="!getdata.get('qty').valid && getdata.get('qty').touched">
  Please enter quantity
</div>
</div>
</div>
</div>


<div class="row" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">
<div class="col-md-4 text-right">
<h6 class="pt-2">Add Maintenance</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<input type="text" class="form-control" id="maintenance" name="maintenance" value="0" formControlName="maintenance">
<div class="error-message message-small" *ngIf="message != ''">
 {{message}} 
</div>
<div class="error-message message-small" *ngIf="!getdata.get('maintenance').valid && getdata.get('maintenance').touched">
 Please enter maintenance
</div>
</div>
</div>
</div>


<div class="submit-btn-group text-center mb-2" *ngIf="getdata.value.category == '' || getdata.value.category == 'new'">

<a  (click)="cart('view')"  class="btn btn-warning">View Cart</a>

<a  (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

</div>





</form>



<form [formGroup]="getdataupgrademaintan" *ngIf="getdata.value.category == 'upgrade'">


<div class="row" >
<div class="col-md-4 text-right">
<h6 class="pt-2" >License Key</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<input type="text" class="form-control" id="quantity" name="quantity" value="1" formControlName="li_key"  >
<div class="error-message message-small" *ngIf="!getdataupgrademaintan.get('li_key').valid && getdataupgrademaintan.get('li_key').touched">
Please enter license key
</div>
</div>
</div>

<div class="col-md-4 pt-1">
<button class="btn btn-info" (click)="priceinformation_upgrade()">Verify</button>
</div>

</div>





<!-- upgrade edition dropdown -->
<div class="row" *ngIf="upgradeshow">
<div class="col-md-4 text-right">
<h6 class="pt-2">Edition</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<select id="edition_key" name="edition_key" class="optional form-control"  formControlName="editionkeyupgrade" >
<!-- <option value="" selected="selected" > Choose edition</option> -->
<option *ngFor="let l of upgradeedition" value="{{l.value}}">{{l.edition}}</option>
</select>
 <div class="error-message message-small" *ngIf="!getdataupgrademaintan.get('editionkeyupgrade').valid && getdataupgrademaintan.get('editionkeyupgrade').touched">
 Please choose edition
</div> 
</div>
</div>
</div>


<!-- upgrade Concurrent License -->
<div class="row" *ngIf="upgradeshow">
<div class="col-md-4 text-right">
<h6 class="pt-2">Concurrent License</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<select id="edition_key" name="edition_key" class="optional form-control" formControlName="conc_license_upgrade" >
<option selected="selected" value =""> Choose Concurrent License</option>
<option *ngFor="let l of upgrade_call" value="{{l.price_value}},{{l.discount}},{{l.value}},{{l.prices_value}}" >{{l.option_value}}</option>
</select>
 <div class="error-message message-small" *ngIf="!getdataupgrademaintan.get('conc_license_upgrade').valid && getdataupgrademaintan.get('conc_license_upgrade').touched">
 Please choose concurrent license
</div> 
</div>
</div>
</div>




<div class="submit-btn-group text-center mb-2" *ngIf="upgradeshow">

<a  (click)="cart('view')"  class="btn btn-warning">View Cart</a>

<a  (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

</div>



</form>



<form [formGroup]="getmaintan" *ngIf="getdata.value.category == 'maintenance'">


<div class="row" >
<div class="col-md-4 text-right">
<h6 class="pt-2" >License Key</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<input type="text" class="form-control" id="quantity" name="quantity" value="1" formControlName="li_key"  >
<div class="error-message message-small" *ngIf="!getmaintan.get('li_key').valid && getmaintan.get('li_key').touched">
Please enter license key
</div>
</div>
</div>

<div class="col-md-4 pt-1">
<button class="btn btn-info" (click)="priceinformation_upgrade()">Verify</button>
</div>

</div>




<div class="row" *ngIf="maintananceshow">
<div class="col-md-4 text-right">
<h6 class="pt-2">Price</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
{{maintenance_data.price_data}}

  
</div>
</div>

</div>

<div class="row" *ngIf="maintananceshow">
<div class="col-md-4 text-right">
<h6 class="pt-2">Year</h6>
</div>	
<div class="col-md-4">
<div class="form-group">
<input type="text" class="form-control" id="year" name="year" value="1" formControlName="year"  >
<div class="error-message message-small" *ngIf="!getmaintan.get('year').valid && getmaintan.get('year').touched">
Please enter year
</div>
</div>
</div>

</div>


<div class="submit-btn-group text-center mb-2" *ngIf="maintananceshow">

<a  (click)="cart('view')"  class="btn btn-warning">View Cart</a>

<a  (click)="cart('insert')" class="btn btn-success">Add to Cart</a>

</div>



</form>






</div>
</div>
</div>
</div>
</div>
</div>
</section>