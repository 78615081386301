import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
declare var iziToast:any;
import Swal from 'sweetalert2'
import * as Feather from 'feather-icons';
@Component({
  selector: 'app-buy3cx',
  templateUrl: './buy3cx.component.html',
  styleUrls: ['./buy3cx.component.css']
})
export class Buy3cxComponent implements OnInit {
edition:any=[];
 getdata: FormGroup;
 getdataupgrademaintan:FormGroup;
 getmaintan:FormGroup;
 c3x_data:any={};
 cartdata:any={};
 customer_data:any={};
 concur_license:any=[];
 message = '';
 upgradeshow = false;
 upgradeedition:any=[];
 upgrade_call:any=[];
system_discount_3cx;
reseller_dis_per;
maintenance_data;
maintananceshow = false;
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {

if(this.serverService.cxbuy == 0){
  
 this.router.navigate(['/dashobard']);
}


  	this.onlineorder({})
  this.customers_data()
  	  this.getdata = new FormGroup({
      'category' : new FormControl(null,Validators.required),
      'editionkey' : new FormControl(null,Validators.required),
      'conc_license' : new FormControl(null,Validators.required),
        'qty':new FormControl(null,Validators.required),
        'maintenance':new FormControl(null,Validators.required)

        //  'li_key' : new FormControl(null),
        // 'conc_license_upgrade':new FormControl(null),
        // 'editionkeyupgrade':new FormControl(null)

           
     });
  this.getdataupgrademaintan = new FormGroup({
   

         'li_key' : new FormControl(null,Validators.required),
        'conc_license_upgrade':new FormControl(null,Validators.required),
        'editionkeyupgrade':new FormControl(null,Validators.required),
        'year':new FormControl(null,Validators.required)

           
     });

  this.getmaintan = new FormGroup({
   

         'li_key' : new FormControl(null,Validators.required),
        'year':new FormControl(null,Validators.required)

           
     });




this.getdata.patchValue({
	category:'',
	editionkey:'',
	conc_license:'',
     // conc_license_upgrade:'',
    qty:1,
    maintenance: 0,

});
this.getdataupgrademaintan.patchValue({
  li_key:'',
  conc_license_upgrade:'',
  editionkeyupgrade:'',
  year:'1'
 
});

this.getmaintan.patchValue({
  li_key:'',
  year:'1'
 
});


  }


ngAfterViewInit() {
    Feather.replace();
  }

cart(val){
  console.log(this.getdata.value)
  console.log(this.getdataupgrademaintan.value)

	if(val == "insert"){

if(this.getdata.value.category == "" || this.getdata.value.category == "new"){
  if(this.getdata.valid){
this.getcart()
}else{
   this.validateAllFields(this.getdata); 
}

}
else if(this.getdata.value.category == "upgrade"){
    if(this.getdataupgrademaintan.valid){
this.getcart()
}else{
   this.validateAllFields(this.getdataupgrademaintan); 
}
}
else if(this.getdata.value.category == "maintenance"){
    if(this.getmaintan.valid){
this.getcart()
}else{
   this.validateAllFields(this.getmaintan); 
}
}



	}else{

    this.getcarts()
   // this.router.navigate(['/shopping-cart']);

	}
}

validateAllFields(formGroup: FormGroup) {         
        Object.keys(formGroup.controls).forEach(field => {  
            const control = formGroup.get(field);            
            if (control instanceof FormControl) {             
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        
                this.validateAllFields(control);  
            }
        });
    }
       

onlineorder(data){
  let datas:any =new Object();
  let datasend:any = new Object();
  let access_token: any=localStorage.getItem('at');
  let customer_id: any=localStorage.getItem('en');
   
   
datas.action="online_order";
datas.customer_id = customer_id;
datas.product_sale_type = "new";

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
	console.log(response)
        if(response.status=="true"){
    this.edition = response.Edition;
    this.c3x_data = response.Customer_3cx_data;
    this.customer_data = response.Customer_data;
   
          }
        else{
 this.edition = [];
 this.c3x_data={};
        }


      
      }, 
      (error)=>{
          console.log(error);
      });

}




editionchange(){
  Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="edition_change";
datas.customer_id = customer_id;
datas.edition_key = this.getdata.value.editionkey;
datas.api_key = this.c3x_data.Apikey;
datas.reseller_id = this.c3x_data.Resellerid;
datas.customer_currency = this.c3x_data.Customer_currency;
datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
datas.currency_value = this.c3x_data.Currency_value;
datas.system_discount_3cx  = this.system_discount_3cx;
datas.reseller_dis_per  = this.reseller_dis_per;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
Swal.close();
        if(response.status=="true"){
     this.concur_license = response.Options
   
          }
        else{
           Swal.close();
  this.concur_license =[];
        }

      
      }, 
      (error)=>{
          console.log(error);
      });

}


concurrentlicense_change(){

  Swal.fire('Please wait')
Swal.showLoading()

  
	this.message = '';

    var array = this.getdata.value.conc_license.split(','); 


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="concurrent_license_change";
datas.customer_id = customer_id;
datas.edition_key = this.getdata.value.editionkey;
datas.api_key =this.c3x_data.Apikey;
datas.reseller_id =this.c3x_data.Resellerid;
datas.customer_currency = this.c3x_data.Customer_currency;
datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
datas.currency_value = this.c3x_data.Currency_value;
datas.simultaneous_call = array[0];

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response){
          this.cartdata = response;
     this.message = response.message;
     
   Swal.close();
          }
        else{
// this.prepaidnotedata =[];
 
        }  
      }, 
      (error)=>{
          console.log(error);
      });

}



insertcart(){


// console.log(this.getdata.value)

console.log(this.getdataupgrademaintan.value)


var array = this.getdata.value.conc_license.split(','); 
console.log(this.getdata.value.conc_license)

var arrayupgrade = this.getdataupgrademaintan.value.conc_license_upgrade.split(','); 
console.log(arrayupgrade )


  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   if(this.getdata.value.category == 'upgrade'){
datas.action="insert_cart";
datas.customer_id = customer_id;
datas.product_sale_type = "upgrade";
datas.quantity = "1";
datas.maintenance = "0";
datas.simultaneous_call = arrayupgrade[2];
datas.license_key = this.getdataupgrademaintan.value.li_key;
datas.edition_key = this.getdataupgrademaintan.value.editionkeyupgrade;
datas.dicountPercentage = arrayupgrade[1];
datas.temp_price = arrayupgrade[0] ;
datas.actual_price = arrayupgrade[0];
datas.perpetual = "1";
datas.edition = this.cartdata.edition;
datas.temp_maintenace_price ="0";
   } 
   else if(this.getdata.value.category == 'maintenance'){
datas.action="insert_cart";
datas.customer_id = customer_id;
datas.product_sale_type = "maintenance";
datas.quantity = this.getmaintan.value.year;
datas.maintenance = this.getmaintan.value.year;
datas.simultaneous_call = this.maintenance_data.simultaneousCalls;
datas.license_key = this.getmaintan.value.li_key;
datas.edition_key = this.maintenance_data.Edition;
datas.dicountPercentage = this.maintenance_data.discount;
datas.temp_price = this.maintenance_data.temp_price;
datas.actual_price = this.maintenance_data.actual_price;
datas.perpetual = this.maintenance_data.Perpetual;
datas.edition = this.maintenance_data.edition;
datas.temp_maintenace_price = this.maintenance_data.temp_maintenace_price;
   }
   else{
datas.action="insert_cart";
datas.customer_id = customer_id;
datas.product_sale_type = "new";
datas.quantity = this.getdata.value.qty;
datas.maintenance = this.getdata.value.maintenance;
datas.simultaneous_call = array[2];
datas.license_key = "";
datas.edition_key = this.getdata.value.editionkey;
datas.dicountPercentage = array[1];
datas.temp_price = this.cartdata.temp_price;
datas.actual_price = this.cartdata.actual_price;
datas.perpetual = this.cartdata.perpetual;
datas.edition = this.cartdata.edition;
datas.temp_maintenace_price = this.cartdata.temp_maintenace_price;
   }
 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){

         if(this.getdata.value.category == 'new'){
    iziToast.success({
          message: "Product Successfully added to your cart",
          position: 'topRight'

      });
   this.router.navigate(['/shopping-cart']);

         }else if(this.getdata.value.category == 'upgrade'){
    iziToast.success({
          message: "Product Upgradation added to your cart",
          position: 'topRight'
      });
   this.router.navigate(['/shopping-cart']);

         }else{
           iziToast.success({
          message: "Product maintenance added to your cart",
          position: 'topRight'
      });
   this.router.navigate(['/shopping-cart']);

         } 
    
          }else{
                iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
          }
    
      }, 
      (error)=>{
          console.log(error);
      });

}


priceinformation_upgrade(){


if(this.getdata.value.category == "upgrade" ){
if(this.getdataupgrademaintan.value.li_key == "" || this.getdataupgrademaintan.value.li_key == undefined){
   iziToast.error({
          message: "Please Enter License Key",
          position: 'topRight'

      });
    return false
}
  console.log(this.getdataupgrademaintan.value.li_key)
    Swal.fire('Please wait')
Swal.showLoading()

   let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="price_information_upgrade";
datas.customer_id = customer_id;
datas.product_sale_type = "upgrade";
datas.license_key =this.getdataupgrademaintan.value.li_key;
datas.customer_currency =this.customer_data.currency_name;
datas.customer_currency_symbol = this.customer_data.symbol;
datas.currency_value =this.customer_data.currency_value;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
           Swal.close();
          this.upgradeshow = true;
          this.maintananceshow = false;
     this.upgradeedition = response.Edition;
     this.upgrade_call = response.Simultaneouscall;
     this.getdataupgrademaintan.patchValue({
  editionkeyupgrade:this.upgradeedition[0].value,
  
});
   
          }
        else{
           Swal.close();
this.upgradeedition =[];
this.upgrade_call =[];
 
        }  
      }, 
      (error)=>{
          console.log(error);
      });

}else if(this.getdata.value.category == "maintenance"){

if(this.getmaintan.value.li_key == "" || this.getmaintan.value.li_key == undefined){
   iziToast.error({
          message: "Please Enter License Key",
          position: 'topRight'

      });
    return false
}
    Swal.fire('Please wait')
Swal.showLoading()
  console.log(this.getmaintan.value.li_key)
   // iziToast.error({
   //        message: "Please Enter License Key",
   //        position: 'topRight'

   //    });
 let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="anual_renew_maintenance";
datas.customer_id = customer_id;
datas.product_sale_type = "maintenance";
datas.license_key =this.getmaintan.value.li_key;
datas.customer_currency =this.customer_data.currency_name;
datas.customer_currency_symbol = this.customer_data.symbol;
datas.currency_value =this.customer_data.currency_value;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
           Swal.close();
          this.maintenance_data = response
          this.maintananceshow = true;
             this.upgradeshow = false;
//      this.upgradeedition = response.Edition;
//      this.upgrade_call = response.Simultaneouscall;
//      this.getdataupgrademaintan.patchValue({
//   editionkeyupgrade:this.upgradeedition[0].value,
  
// });
   
          }
        else{
           Swal.close();
// this.upgradeedition =[];
// this.upgrade_call =[];
 
        }  
      }, 
      (error)=>{
          console.log(error);
      });
}

 

}

simultaneouscall_change(){
  this
	    var arrays = this.getdata.value.conc_license_upgrade.split(','); 

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="simultaneous_call_change";
datas.customer_id = customer_id;
datas.product_sale_type = "upgrade";
datas.license_key =this.c3x_data.Apikey;
datas.edition =this.c3x_data.Resellerid;
datas.simultaneous_call = this.c3x_data.Customer_currency;
datas.actual_price = arrays[0];
datas.temp_price =arrays[0] ;
datas.perpetual = "1";
datas.maintenance = "0";
datas.quantity = "1";
datas.temp_maintenace_price = "0";
datas.customer_currency = this.c3x_data.Customer_currency;
datas.customer_currency_symbol = this.c3x_data.Currency_symbol;
datas.currency_value = this.c3x_data.Currency_value;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        // if(response.status=="true"){
     this.message = response.message
     this.cartdata = response
   
          // }
//         else{
// this.prepaidnotedata =[];
 
//         }  
      }, 
      (error)=>{
          console.log(error);
      });

}



customers_data(){

  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="contact_flag";
data.customer_id=customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 this.system_discount_3cx = response.customer_details.system_discount_3cx;
 this.reseller_dis_per = response.customer_details.reseller_dis_per;

          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}

getcart(){
  // Swal.fire('Please wait')
// Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="getcartlist";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){

     // this.cartdata = response.options
     if(response.options.length <= 5){
     this.insertcart()

   }else{
             iziToast.error({
          message: "Cart limit exceeded",
          position: 'topRight'
      });
   }

          }
        else{
this.insertcart()

        }

      }, 
      (error)=>{
          console.log(error);
      });

}


getcarts(){
  Swal.fire('Please wait')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="getcartlist";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
     // this.cartdata = response.options
     if(response.options.length != 0){
   this.router.navigate(['/shopping-cart']);

     }
   //   if(response.options.length <= 5){
   //   this.insertcart()

   // }
   else{

             iziToast.error({
          message: "Please Add any Product in your cart",
          position: 'topRight'
      });
   }

          }
        else{
          Swal.close()
          
          iziToast.error({
          message: "Please Add any Product in your cart",
          position: 'topRight'
      });

        }

      }, 
      (error)=>{
          console.log(error);
      });

}



}
