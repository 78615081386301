<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Prepaid Note</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Prepaid Note Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Prepaid Note Details</h4>

<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped"  >
  <!-- -->
  <!-- id="dataTable" -->
<thead>
<tr>
<th class="text-center pt-3">
<div class="custom-checkbox custom-checkbox-table custom-control">
<input type="checkbox" data-checkboxes="mygroup"  data-checkbox-role="dad"
class="custom-control-input" id="checkbox-all">
<label for="checkbox-all" class="custom-control-label">&nbsp;</label>
</div>
</th>
<th>S.no</th>
<th>Doc No</th>
<th>Date</th>
<th>Description</th>
<th>Biller</th>
<th>Status</th>
<th>Total Amt</th>
<th>Generated By</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr *ngFor ="let l of prepaidnotedata;let i = index;">
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>{{offset_count+i+1}}</td>
<td>{{l.Docnumber}}</td>
<td>{{l.Date}}</td>
<td>{{l.Description}}</td>
<td>
<div class="badge badge-success badge-shadow">&nbsp;{{l.Biller}}&nbsp;</div>
</td>
<td *ngIf='l.used_status == "1"'>
<div class="badge badge-success badge-shadow" data-toggle="tooltip" title="{{l.customer_inv_no}}">&nbsp;Used&nbsp;</div>
</td>
<td *ngIf='l.used_status != "1"'>
<div class="badge badge-warning badge-shadow" data-toggle="tooltip" title="{{l.customer_inv_no}}">&nbsp;Unused&nbsp;</div>
</td>
<td>{{l.Totalamt}}</td>
<td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;{{l.Generatedby}}&nbsp;</div></td>
<td class="action-btn-group">
<a href="{{l.Attachment}}" target="_blank" class="btn btn-primary"><i class="far fa-file-pdf"></i></a>
<!-- <a href=""  target="_blank" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a> -->
</td>
</tr>





<!-- <tr>
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>2</td>
<td>INC0998-980899</td>
<td>29-08-2020</td>
<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</td>
<td>
<div class="badge badge-info badge-shadow">&nbsp;Cal4care JB&nbsp;</div>
</td>
<td>5000</td>
<td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;Narissa&nbsp;</div></td>
<td class="action-btn-group">
<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> -->


<!-- <tr>
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>3</td>
<td>INC9298-9882059</td>
<td>29-08-2020</td>
<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</td>
<td>
<div class="badge badge-warning badge-shadow">&nbsp;Cal4care MY&nbsp;</div>
</td>
<td>5000</td>
<td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;Adam&nbsp;</div></td>
<td class="action-btn-group">
<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr>
 -->
<!-- <tr>
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>4</td>
<td>INC9298-9882059</td>
<td>29-08-2020</td>
<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi.</td>
<td>
<div class="badge badge-danger badge-shadow">&nbsp;Cal4care TH&nbsp;</div>
</td>
<td>5000</td>
<td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;Adam&nbsp;</div></td>
<td class="action-btn-group">
<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> -->

<!-- <tr>
<td class="text-center pt-2">
<div class="custom-checkbox custom-control">
<input type="checkbox" data-checkboxes="mygroup" class="custom-control-input"
id="checkbox-1">
<label for="checkbox-1" class="custom-control-label">&nbsp;</label>
</div>
</td>
<td>5</td>
<td>INC0598-890058</td>
<td>29-08-2020</td>
<td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</td>
<td>
<div class="badge badge-success badge-shadow">&nbsp;Cal4care SG&nbsp;</div>
</td>
<td>5000</td>
<td><div class="badge ping-bg-clr badge-shadow white-clr">&nbsp;CM&nbsp;</div></td>
<td class="action-btn-group">
<a href="javascript:;" class="btn btn-primary"><i class="fas fa-edit"></i></a>
<a href="javascript:;" class="btn btn-danger"><i class="fas fa-trash-alt"></i></a>
</td>
</tr> -->



</tbody>

 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>



</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">
  <!--  -->
              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">
<!--  -->
                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? prepaidnote({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="prepaidnote({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? prepaidnote({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="prepaidnote({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? prepaidnote({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>




</div>
</div>
</div>
</div>
</div>
</div>
</section>