<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Support Ticket Dashboard</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<!-- data-toggle="modal"  data-target=".createticketModal" -->
<li class="breadcrumb-item active"> <button type="submit"  class="btn btn-success" (click)="createticket()"><i class="fas fa-plus-circle"></i> Create Tickets</button></li>
</ul>
<div class="section-body">
<div class="row no-gutters header-card mb-4">
<div class="col-xl col-md-4 col-sm-12">
<div class="card clr-card red-bg-clr">
	<a routerLink="/support-ticket">
		<div class="card-statistic">
			<div class="banner-img">
				<img src="assets/img/custom-images/dashboard/support-ticket.svg" alt="Support Ticket">
			</div>
			<div class="card-header-details fl">
				<h6 class="m-b-5 text-left">Open</h6>
				<h4 class="text-left"><span>{{counts.open}}</span>
				</h4>
			</div>
		</div>
	</a>
</div>
</div>
<div class="col-xl col-md-4 col-sm-12">
<div class="card clr-card ping-bg-clr">
	<a routerLink="/support-ticket">
		<div class="card-statistic">
			<div class="banner-img">
				<img src="assets/img/custom-images/dashboard/unread-icon.svg" alt="Unread Tickets">
			</div>
			<div class="card-header-details fl">
				<h6 class="m-b-5 text-left">Unread</h6>
				<h4 class="text-left"><span>{{counts.unread}}</span>
				</h4>
			</div>
		</div>
	</a>
</div>
</div> 
<div class="col-xl col-md-4 col-sm-12">
<div class="card clr-card green-bg-clr">
	<a routerLink="/support-ticket">
		<div class="card-statistic">
			<div class="banner-img">
				<img src="assets/img/custom-images/dashboard/closed-icon.svg" alt="Closed Tickets">
			</div>
			<div class="card-header-details fl">
				<h6 class="m-b-5 text-left">Closed</h6>
				<h4 class="text-left"><span>{{counts.closed}}</span>
				</h4>
			</div>
		</div>
	</a>
</div>
</div>
<div class="col-xl col-md-4 col-sm-12">
<div class="card clr-card yellow-bg-clr">
	<a routerLink="/support-ticket">
		<div class="card-statistic">
			<div class="banner-img">
				<img src="assets/img/custom-images/dashboard/this-month.svg" alt="Current month ticket">
			</div>
			<div class="card-header-details fl">
				<h6 class="m-b-5 text-left">This Month</h6>
				<h5 class="text-left whitecolr"><span>{{counts.current_month_closed}} out of {{counts.current_month_total}}</span>

											  <div class="progress progress-thin" >
										<div class="progress-bar progress-bar-green"  [ngStyle]="{'width': counts.current_month_percent + '%'}"></div>
									 </div>



				</h5>
			</div>
		</div>
	</a>
</div>
</div>
<div class="col-xl col-md-4 col-sm-12">
<div class="card clr-card  light-green-bg-clr">
	<a routerLink="/support-ticket">
		<div class="card-statistic">
			<div class="banner-img">
				<img src="assets/img/custom-images/dashboard/last-month.svg" alt="Previous Month">
			</div>
			<div class="card-header-details fl">
				<h6 class="m-b-5 text-left">Last Month</h6>
				<!-- <h4 class="text-left"><span>{{counts.previous_month_closed}}</span>
				</h4> -->
							<h5 class="text-left whitecolr"><span>{{counts.previous_month_closed}} out of {{counts.previous_month_total}}</span>
											  <div class="progress progress-thin" >
										<div class="progress-bar progress-bar-green"  [ngStyle]="{'width': counts.previous_month_percent + '%'}"></div>
									 </div>
									</h5>



			</div>
		</div>
	</a>
</div>
</div>
</div>
<!-- <div class="row">
<div class="col-md-12 col-lg-12 col-xl-12">
<div class="card card-warning">
	<div class="card-header">
		<h4>Ticket Report</h4>
		<div class="card-header-action">
			<a data-collapse="#chartCollapse" class="btn btn-icon btn-warning" href="#">
				<i class="fas fa-minus"></i></a>
			</div>
		</div>
		<div class="collapse show" id="chartCollapse">
			<div class="card-body">
				<div class="recent-report__chart">
					<div id="chart6"></div>
				</div>
			</div>
		</div>
	</div>
</div>
</div> -->
</div>
</section>



<!-- Create Ticket Modal -->
<div class="modal fade createticketModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
aria-hidden="true"  id="addNewPopup">
<div class="modal-dialog modal-lg">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title" id="myLargeModalLabel">Create Tickets</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

		

			<form class="deal-register-form" [formGroup]="createinformation">

				<div class="form-group">
					<label for="partner-name">From</label>
						<input type="text" class="form-control" id="partner-name" formControlName="fromticket" readonly>
						  <div class="error-message message-small" *ngIf="!createinformation.get('fromticket').valid && createinformation.get('fromticket').touched">
                     Please enter Name
					</div>
			</div>

	<div class="form-group">
					<label for="partner-name">Department</label>
						<input type="text" class="form-control" id="partner-name" formControlName="department" readonly>
						  <div class="error-message message-small" *ngIf="!createinformation.get('department').valid && createinformation.get('department').touched">
                     Please enter Department
					</div>
			</div>

<div class="form-group">
					<label for="partner-name">Subject</label>
						<input type="text" class="form-control" id="partner-name" formControlName="subject">
						  <div class="error-message message-small" *ngIf="!createinformation.get('subject').valid && createinformation.get('subject').touched">
                     Please enter Subject
					</div>
			</div>


	<div class="form-group">
					<label >Message</label>
						<!-- <input type="text" class="form-control" id="partner-name" formControlName="department" readonly> -->
                    <kendo-editor [value]="value" formControlName="messages" style="height: 250px;"></kendo-editor> 

						 <!--  <div class="error-message message-small" *ngIf="!createinformation.get('department').valid && createinformation.get('department').touched">
                     Please enter Department
					</div> -->
			</div>



			
				 
				
				 <div class="form-group">
				 <label >File Upload </label>	
	<div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFiless($event)">
  <input hidden type="file" #fileInput (change)="uploadFile($event)">
</div>
<div  class="files-list" *ngFor="let file of files;let i= index">
    <p> {{ file }} </p>
    <button class="delete-file" (click)="deleteAttachment(i)">
      <a href="javascript:;" data-toggle="tooltip" title="View" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>
    </button>
 </div>
</div>




				
				<div class="btn-groups aligncenter">
					<a  class="btn btn-success mr-3" (click)="finishFunction()">Submit</a>
					<a  class="btn btn-info" data-dismiss="modal">Cancel</a>
				</div>
			</form>
		</div>
	</div>
</div>
</div>
<!-- Create Ticket Modal End -->



