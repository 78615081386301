import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ServerService } from '../services/server.service';
import * as Feather from 'feather-icons';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-readmore',
  templateUrl: './readmore.component.html',
  styleUrls: ['./readmore.component.css']
})
export class ReadmoreComponent implements OnInit {
newsid;
detail:any={};
  constructor(private route:ActivatedRoute,private router:Router,private serverService: ServerService) { 

 this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {

     this.newsid=this.router.getCurrentNavigation().extras.state.blogid;





      }
    });
this.readmore_blogs(this.newsid)
  }

  ngOnInit(): void {
  }
ngAfterViewInit() {
    Feather.replace();
  }
readmore_blogs(id){

 Swal.fire('loading')
Swal.showLoading()
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    
      let blog_id: any=localStorage.getItem('blk');
   
datas.action="news_events_data";
datas.mrvoip_other_service_id = blog_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
this.detail = response.news_details;
     Swal.close()
          }else{
            this.detail ={};
            Swal.close()
          }
      
      }, 
      (error)=>{
          console.log(error);
      });



}





}
