import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
declare var iziToast:any;
declare var $:any;
import Swal from 'sweetalert2'
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
dealregistration:any=[];
recordNotFound = false;
 pageLimit = 4;
 paginationData:any ={"info":"hide"};
 offset_count = 0;
commissiondata:any=[];
callbalances:any={};
newsevents:any=[];
dashcount:any=[];
userperms:any=[];
pn:any=[];

 locals:any =[];
 international:any =[];
 paypal:any =[];
 selectbank= "loc";
 counts:any={};

commission_total_details:any=[];
year_amount:any=[];
commision;
reseller_state;


newseventsmodallist:any=[];
blgshow ='1';
  constructor(private router:Router,public serverService: ServerService) {
  this.selctedbnk('loc')
   }




ngAfterViewInit() {
  // this.selectbank= "loc";
  
    Feather.replace();

  }
  ngOnInit(): void {
 if(localStorage.getItem('enable') == '1'){
     localStorage.setItem('enable','0'); 
      location.reload()
    }else{

     // this.selectbank= "loc";
     this.selctedbnk('loc')

    this.permissions()
    this.callbalance()
    this.ticket_count()
    this.dashboard_Count()
    this.newseventslist({})
    this.commissiondetailslist()
    this.commissiondetails_def()
 // if(localStorage.getItem('show') == '1'){
           // localStorage.setItem('show','0'); 
            this.newseventsmodal()
        // }
    }

    

    
   
  }

// changeColor(i){
//     this.selectedIndex = i;
//   }



selctedbnk(val){
 this.selectbank= val;
}
check(){
  iziToast.success({
           progressBar: true,
    progressBarColor: '',
    progressBarEasing: 'linear',

      });
 
}

readmores(id){
  $('#eventmodal').modal('hide');
  localStorage.setItem('blk',id); 
this.router.navigate(['blogdetail'], { state: {blogid:id} });


}


newseventsmodal(){
this.blgshow =localStorage.getItem('blg');

 if(this.blgshow == '1'){
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="news_events";
datas.customer_id = customer_id;
datas.limit = 3;
datas.offset = 0;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)

localStorage.setItem('blg','0'); 
        if(response.status=="true"){
     this.newseventsmodallist = response.List
   $('#eventmodal').modal('show');
          }
        else{
this.newseventsmodallist =[];
 
        }
   

      
      }, 
      (error)=>{
          console.log(error);
      });
}
}
ticket_count(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="ticket_count";
datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.counts = response.counts
   
          }
        else{
this.counts={}
 
        }

      }, 
      (error)=>{
          console.log(error);
      });

}


readmore_blogs(id){
  localStorage.setItem('blk',id); 
this.router.navigate(['blogdetail'], { state: {blogid:id} });

}






callbalance(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="call_balance";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.callbalances = response.call_balance_list
   
          }
          else{
            this.callbalances ={}
          }
      console.log(this.callbalances)
      }, 
      (error)=>{
          console.log(error);
      });
}


dashboard_Count(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="dashboardCount";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.dashcount = response.List
   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



newseventslist(data){
   Swal.fire('Loading Dashboard')
Swal.showLoading()
    var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="news_events";
datas.customer_id = customer_id;
datas.limit = list_data.limit;
datas.offset = list_data.offset;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
          Swal.close()
     this.newsevents = response.List
   
          }
        else{
          Swal.close()
this.newsevents =[];
 
        }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.newsevents.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.newseventslist({'search_text':searchValue})
}

checkEven(val){
     return (val%2 == 0);
}


commissiondetailslist(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="commission_details";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.commissiondata = response.List;
     this.locals = response.bank_detail1;
     this.international = response.bank_detail2;
     this.paypal = response.bank_detail3;
     if(response.commission_total_details != ""){
     this.commission_total_details = response.commission_total_details;

   }else{
     this.commission_total_details = [];

   }

   this.year_amount = response.year_amount;

   for (var i = 0; i < this.commission_total_details.length; i++) {
      if(i%2 == 0){
console.log("even")
this.commission_total_details[i].background ="#87ceeba1";
      }else{
this.commission_total_details[i].background ="#f5deb3a8";
      }
   }


   for (var i = 0; i < this.year_amount.length; i++) {
      if(i%2 == 0){
console.log("even")
this.year_amount[i].background ="#87ceeba1";
      }else{
this.year_amount[i].background ="#f5deb3a8";
      }
   }



   console.log(this.year_amount)
     this.commision = response;


   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}



permissions(){
this.pn =[];
this.userperms =[];
// this.serverService.invoice=0;
// this.serverService.prepaidnote=0;
// this.serverService.creditnote = 0;
// this.serverService.license = 0;
// this.serverService.projects = 0;
// this.serverService.phones =0;
// this.serverService.leads =0;
// this.serverService.reseller =0;
// this.serverService.callhistory =0;
// this.serverService.cxbuy =0;
// this.serverService.iddpricelist =0;
// this.serverService.call4tellicense =0;
// this.serverService.supportticket =0;
// this.serverService.users =0;


  let data:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
data.action="contact_flag";
data.customer_id=customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = data;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
 // this.countryflagdata = response.cms_menu_data;
    // this.userperms = localStorage.getItem('pn').split(',')
    //   for (var i = 0; i < this.userperms.length; i++) {
    //   this.pn.push(atob(this.userperms[i]))
    // }

this.serverService.callhistorypbx =  response.customer_details.cms_username_dispaly;
this.serverService.callhistoryvspbx =  response.customer_details.cms_vs_pbx_dispaly;
this.userperms = response.customer_details.cus_permission.split(',');
this.reseller_state =  response.customer_details.reseller_state;
    console.log(this.userperms)
    for (var i = 0; i < this.userperms.length; i++) {

this.pn.push(btoa(this.userperms[i]))

    }


localStorage.setItem('pn',this.pn); 

     for (var i = 0; i < this.userperms.length; i++) {
    
      if (this.userperms[i] == '101'){
        this.serverService.invoice = 1;
        this.serverService.referral = 1;
      }
      if (this.userperms[i] == '102'){
        this.serverService.prepaidnote = 1;
         this.serverService.creditnote = 1;   
      }
      if (this.userperms[i] == '103')
        this.serverService.license = 1;
      if (this.userperms[i] == '104')
        this.serverService.projects = 1;
      if (this.userperms[i] == '105')
        this.serverService.phones = 1;
      if (this.userperms[i] == '107')
        this.serverService.leads = 1;
      if (this.userperms[i] == '131')
        this.serverService.reseller = 1;
      if (this.userperms[i] == '109')
        this.serverService.callhistory = 1;    
      if (this.userperms[i] == '118')
        this.serverService.cxbuy = 1;
      if (this.userperms[i] == '134')
        this.serverService.iddpricelist = 1;
      if (this.userperms[i] == '138')
        this.serverService.call4tellicense = 1;
      if (this.userperms[i] == '157')
        this.serverService.supportticket = 1;
      if (this.userperms[i] == '110')
        this.serverService.users = 1;
      if (this.userperms[i] == '136')
        this.serverService.callbalance = 1;
      if (this.userperms[i] == '158')
        this.serverService.licencerenewal = 1;
      if (this.userperms[i] == '159')
        this.serverService.dealregistration = 1;
      if (this.userperms[i] == '160')
        this.serverService.gccfirewall = 1;
    }

    console.log(this.serverService.users)
    console.log(this.serverService.leads)
    console.log(this.serverService.license)

          }
        else{
 
        }      
      }, 
      (error)=>{
          console.log(error);
      });

}




commissiondetails_def(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="commission_details";
datas.customer_id = customer_id;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     // this.counts = response.counts
   
          }
        else{
// this.counts={}
 
        }

      }, 
      (error)=>{
          console.log(error);
      });

}











}
