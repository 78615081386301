



<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">CMS User</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">CMS User Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4 *ngIf="page =='add'">Add User</h4>
<h4 *ngIf="page =='edit'">User Edit</h4>


<div class="card-header-action">
<a  data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>
</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
  <div class="min-form-width">

<form class="deal-register-form">

				<div class="form-group">
					<label for="partner-name">Username*</label>
					<!-- <div class="input-group"> -->
						<div *ngIf="page =='add'">
						<input type="text" class="form-control" id="partner-name" [(ngModel)]="userdata.username" name="username">
						</div>

						<div *ngIf="page =='edit'">
						<input type="text" class="form-control" id="partner-name" [(ngModel)]="userdata.username" name="username" readonly> 
						</div>

						<div class="success-message message-small" *ngIf="page =='add'">
							{{domain}}
							</div>
					<!-- </div> -->
				</div>

				
				<div class="form-group">
					<label for="phone-number">Phone Number</label>
					<!-- <div class="input-group"> -->
						<input type="number"  class="form-control" id="phone-number" [(ngModel)]="userdata.phone_no" name="phone_no">
				<div class="success-message message-small" *ngIf="page =='edit'">
							Phone number should be country code + phone number (eg : 6598765432)
							</div>
						
					<!-- </div> -->
				</div>
				<div class="form-group">
					<label for="Product">Permission</label>
					<div class="input-group">
						<div class="pretty p-default mr-4" *ngFor="let l of menu_list; let i = index">

							<div class="state p-primary" >
		<input  [disabled]="l.readonly" type="checkbox"    [(ngModel)]="l.add"  [ngModelOptions]="{standalone: true}" name="add" (click)="multislt(l)" readonly>
								<label for="cloud" class="pl-1">{{l.name}}</label>
							</div>

							
						</div>
			
<!-- <div *ngIf="typeData.types == 'months'">
<select class="form-control" [(ngModel)]="typeData.start_month"  name="start_month" >

      <option value="" selected> Select Month</option>
  >
<option  *ngFor="let l of montharray" value="{{l.month_val}},{{l.month_year}}">{{l.month_name}} {{l.month_year}}</option>
</select>

</div> -->








			

					</div>
				</div>
				<div class="btn-groups">
					<a  class="btn btn-info mr-3" (click)="back()">Back</a>
					<a  class="btn btn-success mr-3" (click)="submits()">Generate</a>
					
				</div>
				<!-- <div class="btn-groups">
					<a  class="btn btn-info mr-3">Back</a>

					<a  *ngIf="page =='edit'" class="btn btn-warning " (click)="updatess()">Save</a>
						<a  (click)="submits()" class="btn btn-success" >Save</a>
				</div -->
			</form>
		</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>















