<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Call History</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Search call history</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Search call history</h4>

<div class="card-header-action">
<a data-toggle="collapse" class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">





 <div class="min-form-width" *ngIf="resellershow"> 
<form [formGroup]="getdata">

<div class="form-group">
<label>Reseller Customer</label>
<select class="form-control" formControlName="resellcustomer">

<option value="">All</option>

<option  *ngFor="let l of reseller_customer" value="{{l.customer_bill_code_id}},{{l.bill_code_name}}">{{l.customer_bill_code_id}}</option>
</select>
<div class="error-message message-small" *ngIf="!getdata.get('resellcustomer').valid && getdata.get('resellcustomer').touched">
Please Select Reseller Customer
</div>
</div>







<div class="fl mb-4">


<a  (click)="finishFunction()"  class="btn btn-success" >Submit</a>
</div>
</form>
</div>























<form *ngIf="!resellershow">
<!-- <div class="form-group">
<label>Select Reseller Customer</label>
<select class="form-control">
<option>All</option>
<option value="640">640</option>
<option value="1254">1254</option>
</select>
</div> -->
<h6 *ngIf="selectedresellername.length != 0" class="ind-red-clr">Search call history - {{selectedresellername[1]}}</h6>
 <div class="form-group row mb-4">
                
                  <div class="col-sm-12 col-md-7">
                  <button routerLink="/costcenter" type="submit"  style="margin-right: 10px !important" class="btn btn-primary">Cost Center</button>
                  </div>
               </div>
  <div class="form-group row mb-4">
                
                  <div class="col-sm-4 col-md-4" style="display: flex">   


   <input type="radio" id="Number" [(ngModel)]="typeData.types"  class="mrg-right margin-t10" name="types"   value="dates">
  <label for="phone number"  class="mrg-right margin-t5">Filter by Date Range</label>
  

                  </div>


                  <div class="col-sm-4 col-md-4" style="display: flex">   
    <input type="radio" id="Number" [(ngModel)]="typeData.types" class="mrg-right margin-t10" name="types" value="months">
  <label for="phone number"  class="mrg-right margin-t5">Filter By Last Three Month</label>

                  </div>

<!--   <div class="col-sm-4 col-md-4" style="display: flex">   
    <input type="radio" id="Number" [(ngModel)]="typeData.types" class="mrg-right margin-t10" name="types" value="months">
  <label for="phone number"  class="mrg-right margin-t5">Filter By Last Three Month</label>

                  </div> -->



               </div>


 <div class="form-group row mb-4" >

             

                  <div class="col-sm-2 col-md-2">

              <div *ngIf="typeData.types == 'dates'">
                     <input placeholder="Select From Date" type="text" onfocus="(this.type='date')"  [(ngModel)]="typeData.start_time" name="start_time" class="form-control datetimepicker" >
                     </div>

            <div *ngIf="typeData.types != 'dates'">
            <input placeholder="Select From Date" type="text" [(ngModel)]="typeData.start_time" name="start_time" class="form-control datetimepicker" readonly>
            </div>


                            </div>

                   <div class="col-sm-2 col-md-2">

                      <div *ngIf="typeData.types == 'dates'">
                     <input placeholder="Select To Date" type="text" onfocus="(this.type='date')" [(ngModel)]="typeData.to_time" name="to_time" class="form-control datetimepicker"  >


                   </div>

 <div *ngIf="typeData.types != 'dates'">
                     <input placeholder="Select To Date" type="text"  [(ngModel)]="typeData.to_time" name="to_time" class="form-control datetimepicker"  readonly>
                   </div>

                  </div>

          
                   <div class="col-sm-2 col-md-2">
                  
 <div *ngIf="typeData.types == 'months'">
<select class="form-control" [(ngModel)]="typeData.start_month"  name="start_month" >

      <option value="" selected> Select Month</option>
  >
<option  *ngFor="let l of montharray" value="{{l.month_val}},{{l.month_year}}">{{l.month_name}} {{l.month_year}}</option>
</select>

</div>

 <div *ngIf="typeData.types != 'months'">
<select class="form-control" [(ngModel)]="typeData.start_month"  name="start_month" readonly>

  <option value="" selected>Select Month</option> 

</select>

</div>




</div>



            <div class="col-sm-2 col-md-2"  *ngIf="datalist.host_server != null && datalist.host_server.length >= 2">
                  
 <div>
<select class="form-control" [(ngModel)]="typeData.host"  name="host" >

      <!-- <option value="" selected> Select host</option> -->
  >
<option  *ngFor="let l of datalist.host_server" value="{{l.name}}">{{l.name}}</option>
</select>

</div>





</div>










               </div>








<div class="fl mb-4">
<a (click)="callhistorylist({})" class="btn btn-success">Submit</a>
<!-- <a (click)="filterbyclentid('419')" class="btn btn-success">filter</a> -->
<!-- <a (click)="filterbyclentid('')" class="btn btn-success">empty</a> -->

</div>
</form>


  <div *ngIf="calldatashow">
 
 <div class=" form-group row mb-12" style="clear: both;">
   <div class="col-sm-4 col-md-4 " style="display: flex;">
<strong>
<span class="blue-clr">Customer Id:
</span>

{{datalist.customer_details.customerId}}
</strong>
   </div>

   <div class="col-sm-4 col-md-4 " style="display: flex;">
    <strong>
    <span class="blue-clr">
Total Call Duration :</span> {{totaldatas.call_duration}}
</strong>
   </div>

   <div class="col-sm-4 col-md-4 " style="display: flex;">
    <strong>
    <span class="blue-clr">Total Call Cost :</span> {{totaldatas.total_cost}}
</strong>
   </div>

</div> 


              <div class="row" style="padding-bottom: 10px;clear: both;"> 

                <div class="col-sm-4 col-md-4 ">
                  <!-- <div class="col-sm-4 col-md-4"> -->
                  <button type="submit" style="margin-right: 10px !important" class="btn btn-warning" (click)="callhistoryexport('pdf')">PDF</button>
                   <button type="submit" style="margin-right: 10px !important" class="btn btn-primary" (click)="callhistoryexport('excel')">Excel</button>
                  </div>

<div class="col-sm-4 col-md-4 ">
              <input type="radio" id="Number" [(ngModel)]="typeData.filtertype" class="mrg-right margin-t10" name="filtertype" value="clientid">
  <label for="phone number"  class="mrg-right margin-t5">Filter by Client ID</label> 

<select class="form-control" [(ngModel)]="typeData.filterid"  name="filterid" (click)="filterbyclentid(l)">

      <option value="" selected>All</option>
  >
<option  *ngFor="let l of client_id_array" value="l"></option>
</select>



                  </div>


<div class="col-sm-4 col-md-4 " *ngIf='username_display == "1" && display_names.length != 0'>
       <input type="radio" id="Number" [(ngModel)]="typeData.filtertype" class="mrg-right margin-t10" name="filtertype" value="Username">
  <label for="phone number"  class="mrg-right margin-t5">Group By Username</label>  

<div class="containers" >
  <ul class="checkbox-itemss">
        <li *ngFor="let item of display_names">
  <input type="checkbox" style="margin-right: 10px;" [(ngModel)]="item.add"  name="add" (click)="multislt(item)">{{item.from_display_name}}
        </li>
      </ul>
  
</div>


</div>



               </div>

 <!-- <div class="form-group row mb-4" > -->



<!-- </div> -->





<div class="table-responsive" >
<table class="table table-striped" >
  <!-- id="tableExport" -->
<thead>

     <!--  <tr>
                <td colspan="20" class="search-text-tbl">
                            <div class="input-group"><input type="text" class="form-control" placeholder="Search Call" (input)="getsearch($event.target.value)"  (keyup.enter)="get_student_list({'search_text':agent_search.value})" #agent_search>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" (click)="callhistorylist({'search_text':agent_search.value})"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </td>
                            </tr> -->




<!-- <div > -->
<tr *ngIf='customers_id == "2238"'>
<th>No</th>
<th>Caller Id</th>
<th>Called Number</th>
<th>Call Start</th>
<th>Call End</th>
<th>Call Duration(Sec)</th>
<th>Call Cost</th>
<th>PIN Code</th>
<th>Vessel Name</th>
<th>Caller Name</th>
<th>Country Code</th>
<th>Destination Country</th>

</tr>
<!-- </div> -->
<!-- <div> -->
<tr  *ngIf='customers_id != "2238"'>
<th>No</th>
<th>Caller Id</th>
<th>Called Number</th>
<th>Call Start</th>
<th>Call End</th>
<th>Call Duration(Sec)</th>
<th>Call Cost</th>
<th>Client ID</th>
<th>Destination Country</th>

</tr>
<!-- </div> -->
</thead>
<tbody *ngIf='customers_id == "2238"'>

<tr *ngFor="let l of calllist;let i = index;">
<td>{{offset_count+i+1}}</td>
<td>{{l.caller_id}}</td>
<td>{{l.called_number}}</td>
<td>{{l.call_start_show}}</td>
<td>{{l.call_end_show}}</td>
<td>{{l.duration}}</td>

<td>{{l.cost}}</td>
<td>{{l.pin_code}}</td>
<td>{{l.vessel_name}}</td>
<td>{{l.caller_name}}</td>

<td>{{l.country_code}}</td>
<td>{{l.destination_country}}</td>



</tr> 
 <tr>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td>{{totaldatas.call_duration}}</td>
  <td>{{totaldatas.total_cost}} ( {{totaldatas.total_cost}} Total)</td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
 </tr>

</tbody>
<tbody *ngIf='customers_id != "2238"'>

<tr *ngFor="let l of calllist;let i = index;">
<td>{{offset_count+i+1}}</td>
<td>{{l.caller_id}}</td>
<td>{{l.called_number}}</td>
<td>{{l.call_start_show}}</td>
<td>{{l.call_end_show}}</td>
<td>{{l.call_duration}}</td>

<td>{{l.call_cost}}</td>
<!-- <td>{{l.pin_code}}</td> -->
<!-- <td>{{l.vessel_name}}</td> -->
<!-- <td>{{l.from_display_name}}</td> -->

<td>{{l.client_id}}</td>
<td>{{l.destination_country}}</td>



</tr> 
  <tr>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td>{{totaldatas.call_duration}}</td>
  <td>{{totaldatas.total_cost}} ( {{totaldatas.total_cost}} Total)</td>
  <td></td>
  <td></td>
  
 </tr>

</tbody>
<tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>


</table>
</div>



<div class="card-footer text-right" *ngIf="recordNotFound == false">

              <nav class="d-inline-block">
                <ul class="pagination mb-0"  *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? callhistorylist({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="callhistorylist({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? callhistorylist({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="callhistorylist({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? callhistorylist({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>









</div>














</div>
</div>



</div>
</div>
</div>
</div>
</section>