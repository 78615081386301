import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css']
})
export class RenewalComponent implements OnInit {
recordNotFound = false;
 pageLimit = 10;
 paginationData:any ={"info":"hide"};
 offset_count = 0;

 renewallist:any=[];
 
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
           if(this.serverService.licencerenewal == 0){
  
 this.router.navigate(['/dashobard']);
}
  	this.renewals({})
  }
ngAfterViewInit() {
    Feather.replace();
  }

renewals(data){
    var list_data= this.listDataInfo(data);

// console.log(this.serverService.ci)
  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="list_renewal_reminder";
datas.customer_id = customer_id;
datas.limit = list_data.limit;
datas.offset = list_data.offset;
datas.search_text = list_data.search_text;


 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.renewallist = response.options
   
          }
        else{
this.renewallist =[];
 
        }
   this.offset_count = list_data.offset;
        this.paginationData = this.serverService.pagination({'offset':response.Offset, 'total':response.Total, 'page_limit' :this.pageLimit });
         console.log(this.paginationData);
                this.recordNotFound = this.renewallist.length == 0 ? true : false;


      
      }, 
      (error)=>{
          console.log(error);
      });

}


listDataInfo(list_data){
console.log(list_data)
  list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
  // list_data.order_by_name = list_data.order_by_name == undefined ? "user.user_id" : list_data.order_by_name;
  // list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
  list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
  list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
  return list_data;
} 

getsearch(searchValue: string): void {  
  console.log(searchValue);
  this.renewals({'search_text':searchValue})
}





}
