import { Component, OnInit } from '@angular/core';
import { ServerService } from '../services/server.service';
import { Router } from '@angular/router';
import * as Feather from 'feather-icons';
declare var iziToast:any;

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.css']
})
export class ExtensionsComponent implements OnInit {
allextensions:any=[];
  constructor(private router:Router,private serverService: ServerService) { }

  ngOnInit(): void {
  	this.viewall_extension()
  }
newextension(){
this.router.navigate(['newextension'], { state: {page:"add"} });

}

ngAfterViewInit() {
    Feather.replace();
  }

viewall_extension(){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="viewall_extension";
datas.customer_id = customer_id;

 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
     this.allextensions = response.CMS_extension
   
          }
      
      }, 
      (error)=>{
          console.log(error);
      });
}


editextension(exten_id){
this.router.navigate(['newextension'], { state: { exten_id:exten_id,page:"edit"} });


}


deleteaction(val){
var s = this;
  iziToast.question({
    timeout: 20000,
    close: false,
    overlay: true,
    displayMode: 'once',
    id: 'question',
    zindex: 999,
    title: 'Confirm',
    message: 'Are you sure you want to delete?',
    position: 'center',
    buttons: [
        ['<button><b>YES</b></button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
 console.log('yes')

   s.deleteextension(val.extn_name_id)
 

        }, true],
        ['<button>NO</button>', function (instance, toast) {
 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
  console.log('no')

        }],
    ]

});

}



deleteextension(id){

  let datas:any =new Object();
 let datasend:any = new Object();
    let access_token: any=localStorage.getItem('at');
    let customer_id: any=localStorage.getItem('en');
   
   
datas.action="delete_extension_info";
datas.customer_id = customer_id;
datas.extn_name_id = id;



 datasend.access_token=access_token;
  datasend.operation="curlDatas";
  datasend.moduleType="login";
  datasend.api_type="web";
  datasend.element_data = datas;
      this.serverService.sendServer(datasend).subscribe((response: any) => {
console.log(response)
        if(response.status=="true"){
         iziToast.success({
          message: "Deleted Successfully",
          position: 'topRight'
      });
this.viewall_extension()

          }
        else{
    iziToast.error({
          message: "Please try again later",
          position: 'topRight'
      });
 
        }
 


      
      }, 
      (error)=>{
          console.log(error);
      });

}







}
