<section class="section">
<ul class="breadcrumb breadcrumb-style ">
<li class="breadcrumb-item">
<h4 class="page-title m-b-0">Cost Center Details</h4>
</li>
<li class="breadcrumb-item">
<a routerLink="/DashboardComponent">
<i data-feather="home"></i></a>
</li>
<li class="breadcrumb-item active">Cost Center Details</li>
</ul>
<div class="section-body">
<div class="row">
<div class="col-12">
<div class="card card-primary">
<div class="card-header">
<h4>Cost Center Details</h4>

<div class="card-header-action">
<a  data-toggle="collapse"  class="btn btn-icon btn-info" href="#tableCollapse">
<i class="fas fa-minus"></i></a>
</div>

</div>
<div class="collapse show" id="tableCollapse">
<div class="card-body">
<div class="table-responsive">
<table class="table table-striped"  >
  <!-- -->
  <!-- id="dataTable" -->







<thead>
<tr>

<th>Group Name</th>
<th>Username Details</th>
<th>Action</th>
</tr>
</thead>
<tbody>
<tr *ngFor ="let l of costdata;let i = index;">



<td>{{l.group_name}}</td>
<td>{{l.user_name}}</td>
<!-- <td>{{l.license}}</td> -->
<td class="action-btn-group">

<a  (click)="goedit()" data-toggle="tooltip" title="Edit" class="btn btn-primary">
<i class="far fa-edit"></i>
</a>

<a  (click)="godelete()" data-toggle="tooltip" title="Delete" class="btn btn-danger">
<i class="far fa-trash-alt"></i>
</a>


</tr>






</tbody>

 <tr *ngIf="recordNotFound == true">
                 <td colspan="12">No Record Found</td>
               </tr>



</table>
</div>

<div class="card-footer text-right" *ngIf="recordNotFound == false">

              <nav class="d-inline-block">
                <ul class="pagination mb-0" *ngIf="paginationData.info == 'show'">

                  <li class="page-item">
                    <a [class]="paginationData.backtab=='show' ? 'page-link' :'page-link disabled'" href="javascript:void(0)" (click)="paginationData.backtab=='show' ? viewall({'search_text':'','offset':paginationData.start}) : false"><i class="fas fa-angle-left"></i></a>
                  </li>
                  <li class="page-item" *ngIf="paginationData.backtab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="viewall({'search_text':'','offset':paginationData.back})"><i class="fas fa-angle-double-left"></i></a>
                  </li>


                  <li [class]="pagination.load ==false ? 'page-item active' :'page-item'" *ngFor="let pagination of paginationData.data;let i=index">
                    <a class="page-link" href="javascript:void(0)" (click)=" pagination.load ==true ? viewall({'search_text':'','offset':pagination.offset_count}) : false">{{pagination.btn_length}} <span class="sr-only" *ngIf="pagination.load ==false">(current)</span></a>
                  </li>

                  <li class="page-item" *ngIf="paginationData.nexttab =='show'">
                    <a class="page-link" href="javascript:void(0)" (click)="viewall({'search_text':'','offset':paginationData.next})"><i class="fas fa-angle-double-right"></i></a>
                  </li>

                  <li class="page-item">
                    <a [class]="paginationData.nexttab =='show' ? 'page-link' : 'page-link disabled'" href="javascript:void(0)" (click)="paginationData.nexttab =='show' ? viewall({'search_text':'','offset':paginationData.end}) : false"><i class="fas fa-angle-right"></i></a>
                  </li>
                </ul>
              </nav>
            </div>




</div>
</div>
</div>
</div>
</div>
</div>
</section>